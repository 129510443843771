import * as React from "react"
import Svg, { SvgProps, G, Path, Circle } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: title */

const SvgComponent = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    height={120}
    width={256}
    data-name="Layer 1"
    {...props}
  >
    <G transform="matrix(.22515 0 0 .22515 0 0)">
      <Path
        d="m125.898 528.556-1.951-.44c21.756-96.75 76.43-184.609 153.951-247.391a444.114 444.114 0 0 1 712.155 246.21l-1.95.444C942.05 325.17 764.83 183.945 557.135 183.945c-204.974 0-386.334 144.93-431.236 344.61z"
        fill="#3f3d56"
      />
      <Path
        fill="#a0616a"
        d="m1051.5 482.5 3 22-21 2 3-27zM952.5 180.5s2 27 2 28-14 70-14 70-20 38 0 37 14-36 14-36l21-58-9-41zM985.5 482.5l-3 22 21 2-3-27z"
      />
      <Path
        d="m962.5 276.5-5 7s-3 30 0 40 9 56 9 59 1 5 0 6-4 1-2 3 2 8 2 8v5l13 74s-8 9-2 9 28 0 27-10l-2-1s-3-66-2-69 0-9 0-9-2-3 0-4 2-9 2-9l9-61s8 41 13 45 4 14 4 14l4 88s-4 10 0 12 21 9 22-1 6-97 6-101-1-6 0-9-1-3 0-3-2-3-2-5v-49s1-10 2-12-1-4 0-7-2-13-2-13l-45-12zM997.5 500.5s5-5-2-5a32.55 32.55 0 0 0-13 3l-5 18S957 533 980 531s19.5-.5 19.5-.5 7-2 8-4-2-31-2-31-3 8-8 5zM1040.5 500.5s-5-5 2-5a32.55 32.55 0 0 1 13 3l5 18S1081 533 1058 531s-22.5-.5-22.5-.5-7-2-8-4 5-31 5-31 3 8 8 5z"
        fill="#2f2e41"
      />
      <Path fill="#3f3d56" d="M286.141 177.432h8.903v351.653h-8.903z" />
      <Path
        d="M377.205 222.246c.566 122.964-85.946 223.048-85.946 223.048s-87.43-99.282-87.997-222.246C202.695 100.083 289.207 0 289.207 0s87.431 99.282 87.998 222.246z"
        fill="#2ecc71"
      />
      <Path fill="#f2f2f2" d="M225.5 383.5h689v146h-689z" />
      <Path fill="#3f3d56" d="m203.5 399.5 75-157h599l56 157z" />
      <Path fill="#f2f2f2" d="M390.5 252.5h278v92h-278z" />
      <Path fill="#3f3d56" d="m408.5 177.5-44 92h330l-26-92z" />
      <Path
        fill="#ccc"
        d="M415 412.5h219.5v117H415zM252.5 416.5h117v113h-117z"
      />
      <Path fill="#3f3d56" d="M672.5 423.5H733v106h-60.5z" />
      <Path fill="#3f3d56" d="M0 529h1137v2H0z" />
      <Path fill="#3f3d56" d="M179.905 297.059h5.875v232.054h-5.875z" />
      <Path
        d="M239.997 326.631c.374 81.144-56.714 147.188-56.714 147.188s-57.695-65.515-58.07-146.659c-.373-81.143 56.715-147.187 56.715-147.187s57.695 65.515 58.07 146.658z"
        fill="#2ecc71"
      />
      <Path
        d="M239.997 326.631c.374 81.144-56.714 147.188-56.714 147.188s-57.695-65.515-58.07-146.659c-.373-81.143 56.715-147.187 56.715-147.187s57.695 65.515 58.07 146.658z"
        opacity={0.1}
      />
      <Path fill="#3f3d56" d="M103.533 297.059h5.875v232.054h-5.875z" />
      <Path
        d="M163.625 326.631C164 407.775 106.91 473.82 106.91 473.82S49.215 408.304 48.842 327.16c-.374-81.143 56.714-147.187 56.714-147.187s57.695 65.515 58.07 146.658z"
        fill="#2ecc71"
      />
      <Path
        fill="#3f3d56"
        d="M798.5 429.5H828V457h-29.5zM838 429.5h29.5V457H838zM798.5 467H828v28.5h-29.5zM838 467h29.5v28.5H838zM416 277.674h23.087v21.522H416zM446.913 277.674H470v21.522h-23.087zM416 307.022h23.087v22.304H416zM446.913 307.022H470v22.304h-23.087zM589 277.674h23.087v21.522H589zM619.913 277.674H643v21.522h-23.087zM589 307.022h23.087v22.304H589zM619.913 307.022H643v22.304h-23.087zM503 277.674h23.087v21.522H503zM533.913 277.674H557v21.522h-23.087zM503 307.022h23.087v22.304H503zM533.913 307.022H557v22.304h-23.087z"
      />
      <Path
        fill="#f2f2f2"
        d="M686 434.239h14.536v13.551H686zM705.464 434.239H720v13.551h-14.536zM686 452.717h14.536v14.043H686zM705.464 452.717H720v14.043h-14.536z"
      />
      <Circle cx={725} cy={484} r={4} fill="#f2f2f2" />
      <Path
        fill="#f2f2f2"
        d="M415 425h220v2H415zM415 434h220v2H415zM415 443h220v2H415zM415 452h220v2H415zM415 461h220v2H415zM415 470h220v2H415zM415 479h220v2H415zM415 488h220v2H415zM415 497h220v2H415zM415 506h220v2H415zM415 515h220v2H415zM252.5 425h117v2h-117zM252.5 434h117v2h-117zM252.5 443h117v2h-117zM252.5 452h117v2h-117zM252.5 461h117v2h-117zM252.5 470h117v2h-117zM252.5 479h117v2h-117zM252.5 488h117v2h-117zM252.5 497h117v2h-117zM252.5 506h117v2h-117zM252.5 515h117v2h-117z"
      />
      <Circle cx={877} cy={242} r={81} fill="#2ecc71" />
      <Path fill="#fff" d="M869 234h-43v16h43v43h16v-43h43v-16h-43v-43h-16z" />
      <Circle cx={975.5} cy={75.5} r={26} fill="#a0616a" />
      <Path
        d="M966.5 94.5s4 20 4 23 17 12 17 12l19-24s-12-17-12-21z"
        fill="#a0616a"
      />
      <Path
        d="M981.5 120.5s-8.716-2.32-11.358-6.16c0 0-27.642 27.16-27.642 32.16l22 39s0 14 2 15 0 0 0 4-6 30-4 34 4 1 2 7-7 38-7 38 18-6 44 4 57-4 57-4-8-18-6-24-5-17-5-17l-5-92s7-41 2-43l-10-4-32.024-3.936S994.5 119.5 981.5 120.5z"
        fill="#d0cde1"
      />
      <Path d="m952.5 139.5-10 7s-5 15 0 22 7 17 7 17l20-2z" fill="#d0cde1" />
      <Path
        d="M1026.5 148.5s-5.79 14.89-4.581 29.624a15.614 15.614 0 0 0 2.581 7.376l-70.944 33.681S921.5 222.5 929.5 236.5c8 14 37-7 37-7s76-11 82-22 10-57 10-57z"
        fill="#a0616a"
      />
      <Path
        d="m955.65 48.022-4.51-1.805s9.43-10.382 22.55-9.48L970 32.676s9.02-3.61 17.22 5.868c4.31 4.983 9.297 10.84 12.406 17.438h4.83l-2.016 4.44 7.056 4.438-7.242-.798a24.561 24.561 0 0 1-.685 11.496l.194 3.508s-8.394-12.987-8.394-14.793v4.514s-4.51-4.062-4.51-6.77l-2.46 3.16-1.23-4.966L970 65.175l2.46-4.062-9.43 1.354 3.69-4.965s-10.66 5.868-11.07 10.833c-.41 4.965-5.74 11.285-5.74 11.285l-2.46-4.514s-3.69-20.313 8.2-27.084z"
        fill="#2f2e41"
      />
      <Path
        d="M1029.5 107.5h15s11 2 14 22 6 25 3 25-39-1-39-2 7-45 7-45z"
        fill="#d0cde1"
      />
    </G>
  </Svg>
)

export default SvgComponent

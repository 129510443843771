import {
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Platform,
} from "react-native";

import { Text, View } from "../components/Themed";

import { RootTabScreenProps, OrderData } from "../types";
import React, { useContext, useEffect, useState } from "react";

import { OrdersContext } from "../context/OrdersContext";
import useColorScheme from "../hooks/useColorScheme";
import Colors from "../constants/Colors";
import { Picker } from "@react-native-picker/picker";
import { AuthContext } from "../context/AuthContext";
import OrderInfo from "../components/OrderInfo";
import OrderButtons from "../components/OrderButtons";

export default function AcceptedOrdersScreen({
  navigation,
}: RootTabScreenProps<"AcceptedOrders">) {
  const colorScheme = useColorScheme();
  const allOrders = useContext(OrdersContext).orders;
  const [acceptedOrders, setAcceptedOrders] = useState<OrderData[]>([]);
  const { userData } = useContext(AuthContext);
  const [timestamp, setTimestamp] = useState<number>(0);

  const [selectedOrderFilter, setSelectedOrderFilter] = useState("mine");

  useEffect(() => {
    if (allOrders != undefined) {
      setAcceptedOrders(allOrders.filter((o) => o.data.status === "accepted"));
    }
  }, [allOrders]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimestamp(Date.now() / 1000);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const orderDetails = (orderId: string, username: string) => {
    navigation.navigate("OrderDetails", {
      id: orderId,
      username: username,
    });
  };

  return (
    <View style={styles.container}>
      <ScrollView style={styles.scrollView}>
        <View style={{ padding: 20 }}>
          <Picker
            style={[
              styles.headquarterPicker,
              {
                color: Colors[colorScheme].text,
                backgroundColor: Colors[colorScheme].card,
              },
            ]}
            selectedValue={selectedOrderFilter}
            onValueChange={(itemValue: string, itemIndex: number) =>
              setSelectedOrderFilter(itemValue)
            }
            mode="dropdown"
            dropdownIconColor={Colors[colorScheme].text}
            itemStyle={{
              color: "gray",
              fontSize: 16,
            }}
          >
            <Picker.Item label="Mes commandes assignées" value="mine" />
            <Picker.Item label="Toutes les commandes" value="all" />
          </Picker>
          {acceptedOrders
            .filter(
              (o) =>
                selectedOrderFilter === "all" ||
                (userData &&
                  (o.data.deliveryBy || []).includes(userData.username))
            )
            .sort((a, b) => (a.data.datetime > b.data.datetime ? 1 : -1))
            .map((order) => (
              <View key={order.orderId} style={styles.card}>
                <TouchableOpacity
                  onPress={() => {
                    orderDetails(order.orderId, order.user.username);
                  }}
                >
                  <OrderInfo
                    order={order}
                    elapsedTime={
                      timestamp - new Date(order.data.datetime).getTime() / 1000
                    }
                  />
                </TouchableOpacity>
                <OrderButtons
                  order={order}
                  navigation={navigation}
                  showCheckListButton={true}
                />
              </View>
            ))}
          {acceptedOrders.filter(
            (o) =>
              selectedOrderFilter === "all" ||
              (userData &&
                (o.data.deliveryBy || []).includes(userData.username))
          ).length === 0 && (
            <View style={styles.noOrders}>
              <Text style={styles.noOrdersText}>
                Aucune commande à préparer
              </Text>
            </View>
          )}
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    flex: 1,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 16,
    textAlign: "center",
  },

  headquarterPicker: {
    marginTop: -16,
    marginBottom: 10,
    height: 60,
    top: Platform.OS === "ios" ? -65 : 0,
  },
  noOrders: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  noOrdersText: {
    fontSize: 20,
    fontWeight: "bold",
  },

  card: {
    borderColor: "grey",
    borderWidth: 3,
    borderRadius: 10,
    padding: 10,
    marginBottom: 20,
  },
});

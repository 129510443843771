import * as React from "react"
import Svg, { SvgProps, G, Path, Circle } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: title */

const SvgComponent = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    height={214}
    width={256}
    data-name="Layer 1"
    {...props}
  >
    <G transform="scale(.28571)">
      <Path
        d="M41.634 712.738c12.428 23.049 38.806 32.943 38.806 32.943s6.227-27.475-6.201-50.524c-12.429-23.05-38.806-32.944-38.806-32.944s-6.227 27.476 6.201 50.525z"
        fill="#2f2e41"
      />
      <Path
        d="M50.177 705.155c22.438 13.5 31.08 40.314 31.08 40.314s-27.738 4.927-50.177-8.573C8.642 723.396 0 696.582 0 696.582s27.738-4.927 50.177 8.573z"
        fill="#2ecc71"
      />
      <Path fill="#f2f2f2" d="M413.248 35.908h140v2h-140z" />
      <Path
        fill="#f2f2f2"
        d="M513.248 37.408h2v18.5h-2zM452.248 37.408h2v18.5h-2zM484.248 131.908h140v2h-140z"
      />
      <Path
        fill="#f2f2f2"
        d="M522.248 113.908h2v18.5h-2zM583.248 113.908h2v18.5h-2zM670.248 176.908h140v2h-140z"
      />
      <Path
        fill="#f2f2f2"
        d="M708.248 158.908h2v18.5h-2zM769.248 158.908h2v18.5h-2zM656.248 640.908h140v2h-140z"
      />
      <Path
        fill="#f2f2f2"
        d="M694.248 622.908h2v18.5h-2zM755.248 622.908h2v18.5h-2zM417.248 319.908h140v2h-140z"
      />
      <Path
        fill="#f2f2f2"
        d="M455.248 301.908h2v18.5h-2zM516.248 301.908h2v18.5h-2zM461.248 560.908h140v2h-140z"
      />
      <Path
        fill="#f2f2f2"
        d="M499.248 542.908h2v18.5h-2zM560.248 542.908h2v18.5h-2zM685.248 487.908h140v2h-140z"
      />
      <Path
        fill="#f2f2f2"
        d="M723.248 469.908h2v18.5h-2zM784.248 469.908h2v18.5h-2z"
      />
      <Path
        fill="#2f2e41"
        d="M360.356 700.481v-82.62H145.18l-10.453-21.777 1.536-.737 9.989 20.81H362.06v86.027H125.274v-1.703z"
      />
      <Circle cx={156.789} cy={726.033} r={17.887} fill="#3f3d56" />
      <Circle cx={333.101} cy={726.033} r={17.887} fill="#3f3d56" />
      <Circle cx={540.927} cy={346.153} r={11.073} fill="#3f3d56" />
      <Path
        d="M387.385 589.753H121.237L63.648 401.517h383.045l-.349 1.107zm-264.888-1.703h263.639l58.234-184.83H65.95Z"
        fill="#2f2e41"
      />
      <Path
        fill="#f2f2f2"
        d="m418.701 413.015-.306.983-51.785 165.96H132.842L82.26 413.015z"
      />
      <Path
        fill="#2f2e41"
        d="M526.448 341.894v1.704H462.37L451.465 384.7l-1.647-.437 11.241-42.369zM82.258 458.584h345.293v1.704H82.258zM101.459 521.344h306.319v1.704H101.459z"
      />
      <Path
        fill="#2f2e41"
        d="M254.314 402.368h1.704v186.533h-1.704zM320.057 588.844l12.229-186.529 1.7.112-12.228 186.528zM176.38 402.424l1.7-.11 12.158 186.532-1.7.111zM0 745h896v2H0z"
      />
      <Path
        d="M595.41 61.876s14.62 41.606 5.623 48.007c-8.996 6.401 30.361 58.676 30.361 58.676l47.23-12.802-25.864-43.74s-3.374-43.74-3.374-50.141c0-6.401-53.975 0-53.975 0z"
        fill="#a0616a"
      />
      <Path
        d="M595.41 61.876s14.62 41.606 5.623 48.007c-8.996 6.401 30.361 58.676 30.361 58.676l47.23-12.802-25.864-43.74s-3.374-43.74-3.374-50.141c0-6.401-53.975 0-53.975 0z"
        opacity={0.1}
      />
      <Path
        d="M570.874 358.454s-4.268 53.341 0 81.079c4.267 27.737 10.668 104.549 10.668 104.549s0 145.089 23.47 147.222c23.47 2.134 40.54 4.268 42.673-4.267 2.134-8.535-10.668-12.802-4.267-17.07 6.4-4.267 8.535-19.202 0-36.271-8.535-17.07 0-189.896 0-189.896l40.54 108.817s4.267 89.613 8.534 102.415c4.267 12.802-4.267 36.272 10.668 38.406 14.936 2.134 32.005-10.668 40.54-14.936 8.534-4.267-12.802-4.267-8.535-6.4 4.267-2.134 17.07-8.535 12.802-10.669-4.267-2.134-8.535-104.55-8.535-104.55s-11.735-218.699-26.67-227.233c-14.936-8.535-24.537 6.165-24.537 6.165z"
        fill="#2f2e41"
      />
      <Path
        d="M609.28 682.77v17.069s-19.203 46.4 0 46.4c19.202 0 34.138 4.808 34.138-1.593v-57.61zM735.165 682.74v17.069s19.203 46.399 0 46.399-34.138 4.808-34.138-1.593v-57.608z"
        fill="#2f2e41"
      />
      <Circle cx={625.282} cy={54.408} r={38.406} fill="#a0616a" />
      <Path
        d="M613.547 125.886s10.668 32.004 27.737 25.604l17.07-6.401 29.87 204.83s-23.47 34.139-57.608 12.802c-34.138-21.336-17.07-236.835-17.07-236.835z"
        fill="#2ecc71"
      />
      <Path
        d="m643.418 119.485 9.601-20.27s56.542 26.67 65.077 35.205c8.534 8.535 8.534 21.337 8.534 21.337l-14.935 53.341s4.267 117.351 4.267 121.619c0 4.267 14.936 27.737 4.267 19.202-10.668-8.534-12.801-17.069-21.336-4.267-8.535 12.802-27.738 27.738-27.738 27.738z"
        fill="#3f3d56"
      />
      <Path
        d="m718.096 273.108-6.401 59.742s-38.406 34.139-29.871 36.272c8.534 2.134 12.802-6.4 12.802-6.4s14.935 14.935 23.47 6.4c8.534-8.534 29.871-89.613 29.871-89.613z"
        fill="#a0616a"
      />
      <Path
        d="M626.1.13c-8.514-.305-17.625-.455-24.804 4.133a36.313 36.313 0 0 0-8.572 8.392c-6.992 8.838-13.033 19.959-10.436 30.925l3.016-1.176a19.75 19.75 0 0 1-1.905 8.462c.425-1.235 1.848.762 1.467 2.011l-3.323 10.9c5.462-2.002 12.257 2.053 13.088 7.81.38-12.66 1.693-27.18 11.964-34.593 5.18-3.739 11.735-4.88 18.042-5.893 5.818-.935 11.918-1.827 17.49.088 5.574 1.916 10.32 7.615 9.056 13.371 2.57-.885 5.443.906 6.713 3.309 1.27 2.403 1.337 5.237 1.375 7.955 2.74 1.936 5.857-1.908 6.973-5.071 2.62-7.424 4.95-15.327 3.538-23.073-1.412-7.746-7.724-15.148-15.597-15.174a5.467 5.467 0 0 0 1.422-3.849l-6.49-.548a7.172 7.172 0 0 0 4.287-2.26C650.798 8.716 630.313.28 626.1.13Z"
        fill="#2f2e41"
      />
      <Path
        d="M624.215 113.084s-17.37-17.021-23.62-15.979c-6.251 1.043-14.786 15.979-14.786 15.979s-51.207 17.069-49.074 34.138c2.134 17.07 25.604 100.282 25.604 100.282s19.203 100.282 2.134 110.95c-17.07 10.668 81.079 38.406 83.212 25.604 2.134-12.802 6.401-140.821 0-160.024-6.4-19.203-23.47-110.95-23.47-110.95ZM698.893 147.222h26.383s18.424 81.08 20.557 89.614c2.134 8.534 6.401 49.074 4.268 49.074-2.134 0-44.807-8.535-44.807-2.134z"
        fill="#3f3d56"
      />
      <Path
        d="M698 348H597c-9.856-45.34-10.68-89.146 0-131h101c-16.3 41.1-17.318 84.607 0 131z"
        fill="#f2f2f2"
      />
      <Path
        d="m555.938 292.31 29.871 12.803s57.609 8.534 57.609-14.936-57.609-10.668-57.609-10.668l-19.204-6.14Z"
        fill="#a0616a"
      />
      <Path
        d="m562.339 134.42-25.604 6.401-19.203 113.084s-6.4 29.871 4.268 32.005c10.668 2.133 40.539 19.203 40.539 19.203s4.267-32.005 12.802-32.005l-21.337-17.07 12.802-74.677Z"
        fill="#3f3d56"
      />
      <Path fill="#f2f2f2" d="M60.249 352.908h140v2h-140z" />
      <Path
        fill="#f2f2f2"
        d="M98.248 334.908h2v18.5h-2zM159.249 334.908h2v18.5h-2zM109.249 56.908h140v2h-140z"
      />
      <Path
        fill="#f2f2f2"
        d="M209.249 58.408h2v18.5h-2zM148.249 58.408h2v18.5h-2zM250.249 253.908h140v2h-140z"
      />
      <Path
        fill="#f2f2f2"
        d="M350.248 255.408h2v18.5h-2zM289.248 255.408h2v18.5h-2zM12.248 252.908h140v2h-140z"
      />
      <Path
        fill="#f2f2f2"
        d="M112.249 254.408h2v18.5h-2zM51.249 254.408h2v18.5h-2zM180.249 152.908h140v2h-140z"
      />
      <Path
        fill="#f2f2f2"
        d="M218.249 134.908h2v18.5h-2zM279.248 134.908h2v18.5h-2z"
      />
    </G>
  </Svg>
)

export default SvgComponent

import { StyleSheet, SafeAreaView, Pressable, TextInput } from "react-native";

import { MaterialIcons } from "@expo/vector-icons";

import { Text, View } from "../components/Themed";

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "../types";

import React, { useContext, useEffect, useState } from "react";
import useColorScheme from "../hooks/useColorScheme";
import Colors from "../constants/Colors";
import { useToast } from "react-native-toast-notifications";
import { HeadquartersContext } from "../context/HeadquartersContext";
import { nativeConfirm } from "../utils";

export default function SpecialPriceChoiceScreen({
  navigation,
  route,
}: NativeStackScreenProps<RootStackParamList, "ListeuxChoice">) {
  const colorScheme = useColorScheme();
  const toast = useToast();
  const { setSpecialRequestPrice } = useContext(HeadquartersContext);

  const orderId = route.params.id;
  const username = route.params.username;

  const [specialPrice, setSpecialPrice] = useState(0);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Pressable
          onPress={() => {
            nativeConfirm(
              "Valider le prix",
              `Êtes-vous sûr(e) de vouloir valider la demande spéciale pour un prix de ${specialPrice.toFixed(
                2
              )}€ ?`,
              () => {
                setSpecialRequestPrice(username, orderId, specialPrice)
                  .then((message) => {
                    toast.show(message, {
                      type: "success",
                    });
                    navigation.goBack();
                  })
                  .catch((message) => {
                    toast.show(message, {
                      type: "danger",
                    });
                  });
              }
            );
          }}
          style={({ pressed }) => ({
            opacity: pressed ? 0.5 : 1,
            width: 48,
            height: 48,
            alignItems: "center",
            justifyContent: "center",
            marginRight: 4,
          })}
        >
          <MaterialIcons
            name="check"
            size={25}
            color={Colors[colorScheme].text}
          />
        </Pressable>
      ),
    });
  }, [specialPrice]);

  return (
    <SafeAreaView style={styles.safeAreaView}>
      <View style={styles.container}>
        {/* Float number input to set custom price */}
        <View style={styles.horizontalContainer}>
          <TextInput
            style={[styles.input, { color: Colors[colorScheme].text }]}
            placeholder="Coût"
            keyboardType="numeric"
            onChangeText={(text) => {
              setSpecialPrice(Number(text.replace(",", ".")));
            }}
            autoFocus={true}
          />
          <Text style={styles.inputUnity}>€</Text>
        </View>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    padding: 16,
  },

  horizontalContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },

  input: {
    flex: 1,
    borderColor: "gray",
    borderWidth: 1,
    borderRadius: 4,
    padding: 8,
    margin: 8,
    fontSize: 36,
  },

  inputUnity: {
    fontSize: 36,
    marginLeft: 8,
  },
});

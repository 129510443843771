import {
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Platform,
  Pressable,
  Switch,
} from "react-native";

import { MaterialIcons } from "@expo/vector-icons";

import { Text, View } from "../components/Themed";

import { RootTabScreenProps, OrderData } from "../types";
import React, { useContext, useEffect, useState } from "react";

import useColorScheme from "../hooks/useColorScheme";
import Colors from "../constants/Colors";
import { Picker } from "@react-native-picker/picker";
import { HeadquartersContext } from "../context/HeadquartersContext";
import { SettingsContext } from "../context/SettingsContext";
import { useToast } from "react-native-toast-notifications";

export default function HeadquarterManagementScreen({
  navigation,
}: RootTabScreenProps<"HeadquarterManagement">) {
  const colorScheme = useColorScheme();
  const toast = useToast();
  const {
    headquarters,
    items,
    setHeadquarterOpeningStatus,
    setItemQuantity,
    setItemAvailability,
  } = useContext(HeadquartersContext);
  const { selectedHeadquarter, setSelectedHeadquarter } =
    useContext(SettingsContext);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Pressable
          onPress={() => {
            navigation.navigate("NewOrder");
          }}
          style={({ pressed }) => ({
            opacity: pressed ? 0.5 : 1,
            width: 48,
            height: 48,
            alignItems: "center",
            justifyContent: "center",
            marginRight: 4,
          })}
        >
          <MaterialIcons
            name="add"
            size={25}
            color={Colors[colorScheme].text}
          />
        </Pressable>
      ),
    });
  }, []);

  const addQuantity = (itemId: string, quantity: number) => {
    const newQuantity = items[itemId].quantity[selectedHeadquarter] + quantity;
    if (newQuantity > 0) {
      setItemQuantity(selectedHeadquarter.toString(), itemId, newQuantity);
    } else {
      setItemQuantity(selectedHeadquarter.toString(), itemId, 0);
    }
  };

  return (
    <View style={styles.container}>
      <ScrollView style={styles.scrollView}>
        <View style={{ padding: 20 }}>
          <Picker
            style={[
              styles.headquarterPicker,
              {
                color: Colors[colorScheme].text,
                backgroundColor: Colors[colorScheme].card,
              },
            ]}
            selectedValue={selectedHeadquarter.toString()}
            onValueChange={(itemValue: string, itemIndex: number) =>
              setSelectedHeadquarter(parseInt(itemValue))
            }
            mode="dropdown"
            dropdownIconColor={Colors[colorScheme].text}
            itemStyle={{
              color: "gray",
              fontSize: 16,
            }}
          >
            <Picker.Item label="Tout les QG" value="-1" />
            {Object.entries(headquarters).map(([id, hq]) => (
              <Picker.Item
                label={`${hq.name} (${hq.position})`}
                value={id}
                key={id}
              />
            ))}
          </Picker>
          {selectedHeadquarter == -1 && (
            <Text style={styles.title}>Veuillez sélectionner un QG</Text>
          )}
          {selectedHeadquarter != -1 && (
            <View>
              <Text style={styles.title}>
                Gestion du {headquarters[selectedHeadquarter].name}
              </Text>
              {/* Switch to toggle the open/closed status of the HQ */}
              <View style={styles.switchContainer}>
                <Text style={styles.switchLabel}>
                  {headquarters[selectedHeadquarter].name} est{" "}
                  {headquarters[selectedHeadquarter].opened
                    ? "ouvert"
                    : "fermé"}
                </Text>
                <Switch
                  value={headquarters[selectedHeadquarter].opened}
                  onValueChange={(value) => {
                    setHeadquarterOpeningStatus(
                      selectedHeadquarter.toString(),
                      value
                    )
                      .then((message) => {
                        toast.show(message, {
                          type: "success",
                        });
                      })
                      .catch((message) => {
                        toast.show(message, {
                          type: "danger",
                        });
                      });
                  }}
                />
              </View>
              <Text style={styles.checklistTitle}>Gestion des stocks :</Text>
              {Object.entries(items).map(([itemId, count]) => (
                <View key={itemId} style={styles.checklistItem}>
                  <View style={styles.horizontalContainer}>
                    <Text style={styles.checklistItemText}>
                      {items[itemId].quantity[selectedHeadquarter]} x{" "}
                      {items[itemId].shortname
                        ? items[itemId].shortname
                        : items[itemId].name}
                    </Text>
                  </View>
                  <View style={styles.horizontalContainer}>
                    <Switch
                      value={items[itemId].availability[selectedHeadquarter]}
                      onValueChange={(value) => {
                        setItemAvailability(
                          selectedHeadquarter.toString(),
                          itemId,
                          value
                        )
                          .then((message) => {
                            toast.show(message, {
                              type: "success",
                            });
                          })
                          .catch((message) => {
                            toast.show(message, {
                              type: "danger",
                            });
                          });
                      }}
                    />
                    <TouchableOpacity
                      style={[
                        styles.checklistItemButton,
                        {
                          backgroundColor:
                            items[itemId].quantity[selectedHeadquarter] > 0
                              ? "#2ecc71"
                              : "grey",
                        },
                      ]}
                      onPress={() => addQuantity(itemId, -10)}
                    >
                      <Text>-10</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[
                        styles.checklistItemButton,
                        {
                          backgroundColor:
                            items[itemId].quantity[selectedHeadquarter] > 0
                              ? "#e74c3c"
                              : "grey",
                        },
                      ]}
                      onPress={() => addQuantity(itemId, -1)}
                    >
                      <MaterialIcons name="remove" size={24} color="white" />
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[
                        styles.checklistItemButton,
                        {
                          backgroundColor: "#3498db",
                        },
                      ]}
                      onPress={() => addQuantity(itemId, 1)}
                    >
                      <MaterialIcons name="add" size={24} color="white" />
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[
                        styles.checklistItemButton,
                        {
                          backgroundColor: "#2ecc71",
                        },
                      ]}
                      onPress={() => addQuantity(itemId, 10)}
                    >
                      <Text>+10</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              ))}
            </View>
          )}
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    flex: 1,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 16,
    textAlign: "center",
  },

  headquarterPicker: {
    marginTop: -16,
    marginBottom: 10,
    height: 60,
    top: Platform.OS === "ios" ? -65 : 0,
  },

  switchContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },

  switchLabel: {
    fontSize: 16,
    fontWeight: "bold",
  },

  horizontalContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },

  checklistTitle: {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 10,
  },
  checklistItem: {
    flexDirection: "column",
    borderColor: "grey",
    borderWidth: 3,
    borderRadius: 10,
    marginVertical: 6,
  },
  checklistItemText: {
    fontSize: 16,
    marginLeft: 10,
    textAlign: "left",
  },
  checklistItemButton: {
    backgroundColor: "#9b59b6",
    padding: 3,
    borderRadius: 8,
    margin: 5,
  },
});

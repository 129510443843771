import {
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Platform,
  Pressable,
  Switch,
} from "react-native";

import { MaterialIcons } from "@expo/vector-icons";

import { Text, View } from "../components/Themed";

import { RootTabScreenProps, OrderData } from "../types";
import React, { useContext, useEffect, useState } from "react";

import { OrdersContext } from "../context/OrdersContext";
import useColorScheme from "../hooks/useColorScheme";
import Colors from "../constants/Colors";
import { Picker } from "@react-native-picker/picker";
import { HeadquartersContext } from "../context/HeadquartersContext";
import { SettingsContext } from "../context/SettingsContext";
import { clock, nativeConfirm } from "../utils";
import { getDistanceFromLatLonInKm } from "../locationUtils";
import { AuthContext } from "../context/AuthContext";
import { useToast } from "react-native-toast-notifications";
import OrderInfo from "../components/OrderInfo";
import OrderButtons from "../components/OrderButtons";

export default function PendingOrdersScreen({
  navigation,
}: RootTabScreenProps<"PendingOrders">) {
  const colorScheme = useColorScheme();
  const toast = useToast();
  const allOrders = useContext(OrdersContext).orders;
  const { userData, setUserGPSLocation } = useContext(AuthContext);
  const { headquarters, adminConfig } = useContext(HeadquartersContext);
  const [pendingOrders, setPendingOrders] = useState<OrderData[]>([]);
  const [timestamp, setTimestamp] = useState<number>(0);
  const [sortByDistance, setSortByDistance] = useState<boolean>(false);

  const { selectedHeadquarter, setSelectedHeadquarter } =
    useContext(SettingsContext);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimestamp(Date.now() / 1000);
    }, 1000);

    navigation.setOptions({
      headerRight: () => (
        <Pressable
          onPress={() => {
            nativeConfirm(
              "Mise à jour de la localisation",
              "Souhaitez vous mettre à jour votre localisation afin d'afficher la distance estimée du lieux de livraison des commandes ?",
              () => {
                setUserGPSLocation()
                  .then((message) => {
                    toast.show(message, {
                      type: "success",
                    });
                  })
                  .catch((message) => {
                    toast.show(message, {
                      type: "danger",
                    });
                  });
              }
            );
          }}
          style={({ pressed }) => ({
            opacity: pressed ? 0.5 : 1,
            width: 48,
            height: 48,
            alignItems: "center",
            justifyContent: "center",
            marginRight: 4,
          })}
        >
          <MaterialIcons
            name="my-location"
            size={25}
            color={Colors[colorScheme].text}
          />
        </Pressable>
      ),
    });

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (allOrders != undefined) {
      setPendingOrders(allOrders.filter((o) => o.data.status === "pending"));
    }
  }, [allOrders]);

  const orderDetails = (orderId: string, username: string) => {
    navigation.navigate("OrderDetails", {
      id: orderId,
      username: username,
    });
  };

  const [userLocation, setUserLocation] = useState<null | {
    latitude: number;
    longitude: number;
    lastUpdate?: string;
  }>(null);

  useEffect(() => {
    if (userData && adminConfig.listeux && adminConfig.listeux[userData.username] != undefined) {
      setUserLocation(adminConfig.listeux[userData.username].location);
    }
  }, [userData, adminConfig.listeux]);

  return (
    <View style={styles.container}>
      <ScrollView style={styles.scrollView}>
        <View style={{ padding: 20 }}>
          <Picker
            style={[
              styles.headquarterPicker,
              {
                color: Colors[colorScheme].text,
                backgroundColor: Colors[colorScheme].card,
              },
            ]}
            selectedValue={selectedHeadquarter.toString()}
            onValueChange={(itemValue: string, itemIndex: number) =>
              setSelectedHeadquarter(parseInt(itemValue))
            }
            mode="dropdown"
            dropdownIconColor={Colors[colorScheme].text}
            itemStyle={{
              color: "gray",
              fontSize: 16,
            }}
          >
            <Picker.Item label="Tout les QG" value="-1" />
            {Object.entries(headquarters).map(([id, hq]) => (
              <Picker.Item
                label={`${hq.name} (${hq.position})`}
                value={id}
                key={id}
              />
            ))}
          </Picker>
          {userLocation && (
            <Text style={styles.locationLastUpdate}>
              Localisation mise à jour il y a{" "}
              {userLocation.lastUpdate
                ? clock(
                    timestamp -
                      new Date(userLocation.lastUpdate).getTime() / 1000 +
                      1,
                    2 * 60
                  )
                : ": jamais"}
            </Text>
          )}
          <View style={styles.closestOrders}>
            <Text style={styles.closestOrdersText}>Trier par temps</Text>
            <Switch
              style={styles.closestOrdersSwitch}
              value={sortByDistance}
              onValueChange={(value) => {
                setSortByDistance(value);
              }}
            />
            <Text style={styles.closestOrdersText}>Trier par distance</Text>
          </View>

          {pendingOrders
            .filter(
              (o) =>
                selectedHeadquarter === -1 ||
                o.data.headquarterId === selectedHeadquarter
            )
            .map((o) => {
              return {
                order: o,
                elapsedTime:
                  timestamp - new Date(o.data.datetime).getTime() / 1000,
                distance:
                  userLocation && o.data.address
                    ? getDistanceFromLatLonInKm(
                        userLocation.latitude,
                        userLocation.longitude,
                        o.data.address.location.lat,
                        o.data.address.location.lng
                      )
                    : undefined,
              };
            })
            .sort((a, b) =>
              sortByDistance && a.distance && b.distance
                ? a.distance - b.distance
                : a.order.data.datetime > b.order.data.datetime
                ? 1
                : -1
            )
            .map(({ order, distance, elapsedTime }) => (
              <View style={styles.card} key={order.orderId}>
                <TouchableOpacity
                  onPress={() => {
                    orderDetails(order.orderId, order.user.username);
                  }}
                >
                  <OrderInfo
                    distance={distance}
                    order={order}
                    elapsedTime={elapsedTime}
                  />
                </TouchableOpacity>
                {elapsedTime < 2 * 60 && (
                  <Text style={{marginTop: 8}}>
                    Sauf annulation de la part du client, vous pourrez valider
                    la commande dans {(120 - elapsedTime).toFixed(0)}s
                  </Text>
                )}
                <OrderButtons
                  order={order}
                  navigation={navigation}
                  showValidateAndCancelButtons={true}
                  disableValidateAndCancelButtons={elapsedTime < 2 * 60}
                  showSpecialRequestSetPriceButton={Object.keys(order.data.items).includes("zspecial")}
                />
              </View>
            ))}
          {pendingOrders.filter(
            (o) =>
              selectedHeadquarter === -1 ||
              o.data.headquarterId === selectedHeadquarter
          ).length === 0 && (
            <View style={styles.noOrders}>
              <Text style={styles.noOrdersText}>
                Aucune commande pour ce QG
              </Text>
            </View>
          )}
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    flex: 1,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 16,
    textAlign: "center",
  },

  headquarterPicker: {
    marginTop: -16,
    marginBottom: 10,
    height: 60,
    top: Platform.OS === "ios" ? -65 : 0,
  },
  noOrders: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  noOrdersText: {
    fontSize: 20,
    fontWeight: "bold",
  },
  locationLastUpdate: {
    marginTop: 10,
    fontSize: 16,
    textAlign: "center",
  },
  closestOrders: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  closestOrdersText: {
    fontSize: 16,
  },
  closestOrdersSwitch: {
    marginHorizontal: 10,
  },

  card: {
    borderColor: "grey",
    borderWidth: 3,
    borderRadius: 10,
    padding: 10,
    marginBottom: 20,
  },
});

import { Alert, ImageSourcePropType, Platform } from "react-native";
import { Text } from "./components/Themed";

/* Function to display a native alert if on Android or iOS or a dialog if on web */
export function nativeDialog(
  title: string,
  message?: string,
  confirmCallback?: () => void,
  label: string = "OK"
) {
  if (Platform.OS === "web") {
    window.alert(message ? `${title.toUpperCase()}\n\n${message}` : title);
    if (confirmCallback) {
      confirmCallback();
    }
  } else {
    Alert.alert(
      title,
      message,
      [
        {
          text: label,
          onPress: confirmCallback,
        },
      ],
      { cancelable: false }
    );
  }
}

/* Function to display a native confirm alert if on Android or iOS or a confirm dialog if on web */
export function nativeConfirm(
  title: string,
  message?: string,
  confirmCallback?: () => void,
  label: string = "Valider",
  cancelCallback?: () => void,
  cancelLabel: string = "Annuler"
) {
  if (Platform.OS === "web") {
    if (
      window.confirm(message ? `${title.toUpperCase()}\n\n${message}` : title)
    ) {
      if (confirmCallback) {
        confirmCallback();
      }
    } else if (cancelCallback) {
      cancelCallback();
    }
  } else {
    Alert.alert(
      title,
      message,
      [
        {
          text: cancelLabel,
          onPress: cancelCallback,
        },
        {
          text: label,
          onPress: confirmCallback,
        },
      ],
      { cancelable: false }
    );
  }
}

/* Function to convert a datetime ISO String to a human readable string */
export function formatDate(date: string | Date) {
  const d = new Date(date);
  return `${d.getDate()}/${("0" + (d.getMonth() + 1)).slice(
    -2
  )}/${d.getFullYear()} ${("0" + d.getHours()).slice(-2)}:${(
    "0" + d.getMinutes()
  ).slice(-2)}`;
}

/* Function to convert a datetime ISO String to a human readable string (english format) */
export function formatDateEnglish(date: string | Date) {
  const d = new Date(date);
  return `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(
    -2
  )}-${d.getDate()}`;
}

/* Function to convert a time in seconds to a human readable string */
export function formatTime(time: number, noUnit: boolean = false) {
  if (time < 0) time = 0;
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time - hours * 3600) / 60);
  if (hours > 0) {
    return `${hours}h${("0" + minutes).slice(-2)}`;
  } else if (noUnit) {
    return `${minutes}`;
  } else {
    return `${minutes} min`;
  }
}

/* Function to convert a time in seconds to a human readable string with seconds */
export function formatSeconds(time: number) {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time - hours * 3600) / 60);
  const seconds = Math.floor(time - hours * 3600 - minutes * 60);
  if (hours > 0) {
    return `${hours}h${("0" + minutes).slice(-2)}`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} et ${(
      "0" + seconds
    ).slice(-2)} seconde${seconds > 1 ? "s" : ""}`;
  } else {
    return `${seconds} seconde${seconds > 1 ? "s" : ""}`;
  }
}

/* Function to convert a time in seconds to a human readable string with seconds like clock */
export function clock(time: number, limit: number = 10 * 60) {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time - hours * 3600) / 60);
  const seconds = Math.floor(time - hours * 3600 - minutes * 60);
  if (hours > 0) {
    return (
      <Text style={{ color: time >= limit ? "#e74c3c" : "#2ecc71" }}>
        {hours}:{("0" + minutes).slice(-2)}:{("0" + seconds).slice(-2)}
      </Text>
    );
  } else {
    return (
      <Text style={{ color: time >= limit ? "#e74c3c" : "#2ecc71" }}>
        {minutes}:{("0" + seconds).slice(-2)}
      </Text>
    );
  }
}

export const LISTEUX: { [key: string]: string } = {
  lmartin054: "Louis",
  eghanem001: "Elena",
  mhochard001: "Mathieu",
  thohbauer: "Thomas",
  jroullet: "Jules R.",
  jmessner001: "Julie",
  aperre: "Arthur",
  acannet: "Alban",
  aboin: "Alexandre",
  aghammaz: "Anas",
  jbraun001: "Jules B.",
  bhubert002: "Baptiste",
  zzahi: "Ziad",
  mdesaintexu: "Malo",
  jmadec: "Jolann",
  wtoulerfoka: "Camille",
  tcauquil: "Tanguy",
  mcroguennec: "Mathéo",
  clfremy: "Clément F.",
  cguillaud: "Cassandra",
  asolignac: "Amandine",
  nvervoort: "Nicolas",
  lhemmerlin: "Lou",
  fbour: "Florian",
  ahaton: "Antoine",
  misambert: "Mathilde",
  melhaouati: "Simo",
  caumonier: "Clément A.",
  lupereira: "Luca",
  adebouchage: "Arnaud",
  vpenot001: "Victorine",
  vvaujany: "Vincent",
};

export const LISTEUX_STATES: {
  [key: string]: {
    name: string;
    color: string;
    icon: string;
  };
} = {
  responsable: {
    name: "Respo QG",
    color: "#FFD700",
    icon: "home",
  },
  available: {
    name: "Disponible",
    color: "#2ecc71",
    icon: "check-circle",
  },
  "on-delivery": {
    name: "En livraison",
    color: "#e67e22",
    icon: "directions-car",
  },
  "on-phone": {
    name: "Au standard",
    color: "#3498db",
    icon: "support-agent",
  },
  trap: {
    name: "Traquenard",
    color: "#e74c3c",
    icon: "lock",
  },
  sleeping: {
    name: "Dodo",
    color: "#ffffff",
    icon: "bedtime",
  },
};

export const ORDER_STATES: {
  [key: string]: {
    text: string;
    timeText: string;
    description: string;
    icon: string;
    color: string;
    emoji: string;
    order: Number;
    image: ImageSourcePropType;
    imageColor: string;
    progress: number;
  };
} = {
  pending: {
    text: "En attente de validation...",
    timeText: "Temps avant validation",
    description: "Votre commande est en attente de validation.",
    icon: "hourglass-empty",
    color: "#ffc107",
    emoji: "⌛",
    order: 0,
    image: require("./assets/images/states/pending.gif"),
    imageColor: "#F7B16A",
    progress: 25,
  },
  accepted: {
    text: "Préparation en cours...",
    timeText: "Temps de préparation",
    description: "Votre commande est en cours de préparation.",
    icon: "outdoor-grill",
    color: "#2c3e50",
    emoji: "👩‍🍳",
    order: 1,
    image: require("./assets/images/states/accepted.gif"),
    imageColor: "#F34751",
    progress: 50,
  },
  ongoing: {
    text: "En cours de livraison...",
    timeText: "Arrivée estimée",
    description: "Votre commande est en cours de livraison.",
    icon: "delivery-dining",
    color: "#3498db",
    emoji: "🛵",
    order: 2,
    image: require("./assets/images/states/ongoing.gif"),
    imageColor: "#4E85AB",
    progress: 75,
  },
  delivered: {
    text: "Livrée",
    timeText: "Temps de livraison",
    description: "Votre commande a bien été livrée.",
    icon: "check-circle",
    color: "#28a745",
    emoji: "✅",
    order: 3,
    image: require("./assets/images/states/delivered.gif"),
    imageColor: "#849B87",
    progress: 100,
  },
  cancelled: {
    text: "Annulée",
    timeText: "Temps avant annulation",
    description: "Votre commande a été annulée.",
    icon: "cancel",
    color: "#dc3545",
    emoji: "❌",
    order: -1,
    image: require("./assets/images/states/cancelled.gif"),
    imageColor: "#E15B64",
    progress: 100,
  },
  unknown: {
    text: "Inconnu",
    timeText: "...",
    description: "Statut de la commande inconnu.",
    icon: "help",
    color: "#ffc107",
    emoji: "❓",
    order: -2,
    image: require("./assets/images/states/unknown.gif"),
    imageColor: "#333333",
    progress: 50,
  },
};

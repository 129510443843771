import {
  StyleSheet,
  ScrollView,
  TextInput,
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform,
  Pressable,
} from "react-native";

import HomePicture from "../assets/images/home";

import { Text, View } from "../components/Themed";

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList, Address } from "../types";

import React, { useState, useRef, useContext, useEffect } from "react";

import { AuthContext } from "../context/AuthContext";
import { useToast } from "react-native-toast-notifications";
import useColorScheme from "../hooks/useColorScheme";
import Colors from "../constants/Colors";
import { nativeConfirm } from "../utils";
import { MaterialIcons } from "@expo/vector-icons";

export default function AddressChoiceScreen({
  navigation,
  route,
}: NativeStackScreenProps<RootStackParamList, "AddressChoice">) {
  const colorScheme = useColorScheme();
  const toast = useToast();
  // State to hold the address
  const [address, setAddress] = useState<Address>(
    route.params.address ||
      ({
        number: "",
        street: "",
        complement: "",
        postal_code: "",
        city: "",
        details: "",
      } as Address)
  );

  const [loading, setLoading] = useState<boolean>(false);

  // State to hold current authenticated user
  const {
    user,
    getAddressesSuggestions,
    setUserAddress,
    constructVerifiedAddress,
    setUserTravelDetails,
  } = useContext(AuthContext);

  // State to hold form validity
  const [formValid, setFormValid] = useState(true);

  // Function to save the address and verify input before saving
  const saveAddress = () => {
    if (
      //address.city.length > 0 &&
      //address.postal_code.length > 0 &&
      address.number.length > 0 &&
      address.street.length > 0
    ) {
      // Search for the address with the API
      setLoading(true);
      getAddressesSuggestions(
        `${address.number} ${address.street} ${address.city}`
      )
        .then((results) => {
          setLoading(false);
          if (results.length > 0) {
            {
              nativeConfirm(
                "Vérfication",
                `L'adresse suivante est-elle correcte ?\n\n${results[0].formatted_address}`,
                () => {
                  // Construct the verified address object
                  const verifiedAddress = constructVerifiedAddress(
                    address,
                    results[0]
                  );

                  // Save the address
                  setUserAddress(verifiedAddress)
                    .then(() => {
                      toast.show("Adresse enregistrée avec succès !", {
                        type: "success",
                      });
                      navigation.goBack();
                      if (route.params.address != null) {
                        navigation.goBack();
                      }
                      setUserTravelDetails(
                        verifiedAddress.formattedAddress,
                        verifiedAddress.location.lat,
                        verifiedAddress.location.lng
                      );
                    })
                    .catch((error) => {
                      toast.show(error, {
                        type: "danger",
                      });
                    });
                },
                "Oui",
                () => {
                  if (results.length > 1) {
                    // If there are more results, we display them in a list
                    navigation.navigate("AddressSearch", {
                      address,
                      results,
                      comingFromLocationSelector: route.params.address != null,
                    });
                  } else {
                    // Ask the user to re-enter the address correctly
                    toast.show(
                      "Vérifiez à nouveau les informations saisies pour aider la recherche !",
                      {
                        type: "warning",
                        duration: 4000,
                      }
                    );
                  }
                },
                results.length > 1 ? "Autres résultats" : "Non"
              );
            }
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
          toast.show("Erreur lors de la recherche de l'adresse", {
            type: "danger",
          });
        });
    } else {
      setFormValid(false);
      toast.show("Adresse incomplète !", {
        type: "danger",
      });
    }
  };

  // Refs for the inputs
  const numberInputRef = useRef<TextInput>(null);
  const streetInputRef = useRef<TextInput>(null);
  const complementInputRef = useRef<TextInput>(null);
  const postalCodeInputRef = useRef<TextInput>(null);
  const cityInputRef = useRef<TextInput>(null);
  const detailsInputRef = useRef<TextInput>(null);

  // On load
  useEffect(() => {
    navigation.setOptions({
      headerLeft: () =>
        Platform.OS === "web" ? (
          <Pressable
            onPress={() => {
              navigation.goBack();
            }}
            style={({ pressed }) => ({
              opacity: pressed ? 0.5 : 1,
              width: 48,
              height: 48,
              alignItems: "center",
              justifyContent: "center",
              marginRight: 4,
            })}
          >
            <MaterialIcons
              name="arrow-back"
              size={25}
              color={Colors[colorScheme].text}
              style={{ marginLeft: 15 }}
            />
          </Pressable>
        ) : undefined,
    });
  }, []);

  return (
    <View style={{ flex: 1 }}>
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS === "ios" ? "padding" : undefined}
      >
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: "center",
            paddingBottom: 60,
          }}
          keyboardShouldPersistTaps="handled"
        >
          <View style={styles.container}>
            <HomePicture style={{ marginVertical: 16 }} />
            <View style={styles.inputContainer}>
              <View style={[styles.field, { flex: 0.4 }]}>
                <Text style={styles.label}>Numéro</Text>
                <TextInput
                  ref={numberInputRef}
                  style={[
                    styles.input,
                    {
                      borderColor:
                        !formValid && address.number.length <= 0
                          ? "#e74c3c"
                          : "#aaa",
                      color: Colors[colorScheme].text,
                    },
                  ]}
                  value={address.number}
                  onChangeText={(text) => {
                    setFormValid(true);
                    setAddress({
                      ...address,
                      number: text,
                    });
                  }}
                  autoFocus={true}
                  onSubmitEditing={() => {
                    streetInputRef.current?.focus();
                  }}
                  blurOnSubmit={false}
                />
              </View>
              <View style={styles.field}>
                <Text style={styles.label}>Rue</Text>
                <TextInput
                  ref={streetInputRef}
                  style={[
                    styles.input,
                    {
                      borderColor:
                        !formValid && address.street.length <= 0
                          ? "#e74c3c"
                          : "#aaa",
                      color: Colors[colorScheme].text,
                    },
                  ]}
                  value={address.street}
                  autoCapitalize="words"
                  onChangeText={(text) => {
                    setFormValid(true);
                    setAddress({
                      ...address,
                      street: text,
                    });
                  }}
                  onSubmitEditing={() => {
                    complementInputRef.current?.focus();
                  }}
                  blurOnSubmit={false}
                  textContentType="streetAddressLine1"
                />
              </View>
            </View>
            <View style={styles.inputContainer}>
              <View style={styles.field}>
                <Text style={styles.label}>
                  Complément (bâtiment, étage, appartement...)
                </Text>
                <TextInput
                  ref={complementInputRef}
                  style={[styles.input, { color: Colors[colorScheme].text }]}
                  value={address.complement || ""}
                  autoCapitalize="words"
                  onChangeText={(text) => {
                    setFormValid(true);
                    setAddress({
                      ...address,
                      complement: text,
                    });
                  }}
                  onSubmitEditing={() => {
                    postalCodeInputRef.current?.focus();
                  }}
                  blurOnSubmit={false}
                  textContentType="streetAddressLine2"
                />
              </View>
            </View>
            <View style={styles.inputContainer}>
              <View style={[styles.field, { flex: 0.4 }]}>
                <Text style={styles.label}>Code postal</Text>
                <TextInput
                  ref={postalCodeInputRef}
                  style={[
                    styles.input,
                    {
                      borderColor:
                        !formValid && address.postal_code.length <= 0
                          ? "#e74c3c"
                          : "#aaa",
                      color: Colors[colorScheme].text,
                    },
                  ]}
                  keyboardType="numeric"
                  value={address.postal_code}
                  onChangeText={(text) => {
                    setFormValid(true);
                    setAddress({
                      ...address,
                      postal_code: text,
                    });
                  }}
                  onSubmitEditing={() => {
                    cityInputRef.current?.focus();
                  }}
                  blurOnSubmit={false}
                  textContentType="postalCode"
                />
              </View>
              <View style={styles.field}>
                <Text style={styles.label}>Ville</Text>
                <TextInput
                  ref={cityInputRef}
                  style={[
                    styles.input,
                    {
                      borderColor:
                        !formValid && address.city.length <= 0
                          ? "#e74c3c"
                          : "#aaa",
                      color: Colors[colorScheme].text,
                    },
                  ]}
                  value={address.city}
                  autoCapitalize="words"
                  onChangeText={(text) => {
                    setFormValid(true);
                    setAddress({
                      ...address,
                      city: text,
                    });
                  }}
                  onSubmitEditing={() => {
                    detailsInputRef.current?.focus();
                  }}
                  blurOnSubmit={false}
                  textContentType="addressCity"
                />
              </View>
            </View>
            <View style={styles.inputContainer}>
              <View style={styles.field}>
                <Text style={styles.label}>
                  Détails pour le livreur (optionnel) : lieu dit, numéro/nom du bâtiment, etc.
                </Text>
                <TextInput
                  ref={detailsInputRef}
                  style={[styles.input, { color: Colors[colorScheme].text }]}
                  value={address.details || ""}
                  onChangeText={(text) => {
                    setFormValid(true);
                    setAddress({
                      ...address,
                      details: text,
                    });
                  }}
                  onSubmitEditing={saveAddress}
                />
              </View>
            </View>

            <TouchableOpacity
              style={styles.saveButton}
              onPress={() => {
                if (!loading) {
                  saveAddress();
                }
              }}
            >
              <Text style={styles.saveButtonText}>Enregistrer</Text>
            </TouchableOpacity>
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
  },
  inputContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
    width: "100%",
  },
  field: {
    flex: 1,
    marginRight: 10,
  },
  label: {
    fontSize: 14,
    marginLeft: 2,
    marginBottom: 2,
    color: "#aaa",
  },
  input: {
    fontSize: 16,
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: "#aaa",
    borderRadius: 5,
  },
  saveButton: {
    backgroundColor: "#2ecc71",
    padding: 16,
    borderRadius: 5,
    marginTop: 10,
    width: "100%",
  },
  saveButtonText: {
    color: "#fff",
    fontSize: 16,
    textAlign: "center",
  },
});

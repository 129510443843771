import { StyleSheet, TouchableOpacity, Image } from "react-native";

import { Text, View } from "./Themed";

import { Headquarter, Item } from "../types";
import Colors from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme";
import React, { useContext } from "react";
import { MaterialIcons } from "@expo/vector-icons";
import { HeadquartersContext } from "../context/HeadquartersContext";
import { nativeConfirm } from "../utils";
import { useToast } from "react-native-toast-notifications";

export default function ItemCard({
  itemId,
  item,
  headquarter,
  headquarterId,
  currentQuantity,
  showAddButton = false,
  showRemoveButton = false,
  deleteButton = false,
  hideMaxQuantity = false,
  hideAvailableQuantity = false,
  smallVersion = false,
}: {
  itemId: string;
  item: Item;
  headquarterId: number;
  headquarter: Headquarter;
  currentQuantity: number;
  showAddButton?: boolean;
  showRemoveButton?: boolean;
  deleteButton?: boolean;
  hideMaxQuantity?: boolean;
  hideAvailableQuantity?: boolean;
  smallVersion?: boolean;
}) {
  const colorScheme = useColorScheme();
  const toast = useToast();
  const {
    items,
    shoppingCart,
    itemCount,
    addItemToShoppingCart,
    removeItemFromShoppingCart,
    deleteShoppingCart,
  } = useContext(HeadquartersContext);

  const removeItemFromCart = (itemId: string) => {
    if (
      (shoppingCart?.items[itemId] || 0) > 0 &&
      items[itemId].maxQuantity !== 1
    ) {
      if (itemCount == 1) deleteShoppingCart();
      else removeItemFromShoppingCart(itemId);
    } else {
      nativeConfirm(
        "Supprimer l'article du panier ?",
        `Voulez-vous vraiment supprimer l'article "${items[itemId].name}" du panier ?`,
        () => {
          if (itemCount == 1) deleteShoppingCart();
          else removeItemFromShoppingCart(itemId);
        }
      );
    }
  };

  const addItemToCart = (itemId: string) => {
    if (currentQuantity == items[itemId].quantity[headquarterId]) {
      toast.show(
        "Cet article est n'est pas disponible en quantité suffisante dans ce QG",
        {
          type: "warning",
        }
      );
    } else if (shoppingCart && shoppingCart.headquarterId !== headquarterId) {
      nativeConfirm(
        "Panier en cours",
        "Vous avez déjà un panier en cours dans un autre restaurant. Voulez-vous le remplacer ?",
        async () => {
          addItemToCartConfirm(itemId);
        },
        "Remplacer"
      );
    } else {
      if (shoppingCart?.items[itemId] || items[itemId].maxQuantity !== 1) {
        addItemToShoppingCart(headquarterId, itemId, 1).then((message) => {
          toast.show(message, {
            type: "success",
          });
        });
      } else {
        addItemToCartConfirm(itemId);
      }
    }
  };

  const addItemToCartConfirm = (itemId: string) => {
    nativeConfirm(
      "Confirmation d'ajout au panier",
      `Voulez-vous ajouter l'article "${items[itemId].name}" à votre panier ?`,
      () => {
        addItemToShoppingCart(headquarterId, itemId)
          .then((message) => {
            toast.show(message, {
              type: "success",
            });
          })
          .catch((message) => {
            toast.show(message, {
              type: "danger",
            });
          });
      },
      "Ajouter au panier"
    );
  };

  return (
    <View
      key={itemId}
      style={[
        styles.foodItem,
        {
          backgroundColor: Colors[colorScheme].card,
          minHeight: smallVersion ? 50 : 80,
        },
      ]}
    >
      <View style={styles.details}>
        <View style={styles.detailsHeader}>
          {currentQuantity > 0 && (
            <Text style={[styles.quantity, { color: headquarter.color }]}>
              {currentQuantity}×
            </Text>
          )}
          <Text style={styles.name}>{item.name}</Text>
        </View>
        {!smallVersion && (
          <Text style={styles.description}>{item.description}</Text>
        )}
        <Text style={styles.detail}>
          {item.price > 0 ? item.price.toFixed(2) + "€" : "Gratuit"}
          {!hideMaxQuantity ? " / " + item.maxQuantity + " max" : ""}
          {!hideAvailableQuantity
            ? " / Qté disponible : " + item.quantity[headquarterId]
            : ""}
        </Text>
      </View>
      {!smallVersion && (
        <Image style={styles.image} source={{ uri: item.image }} />
      )}
      {(showAddButton || showRemoveButton) && (
        <View style={styles.buttonContainer}>
          {showAddButton && (
            <TouchableOpacity
              style={[
                styles.button,
                {
                  backgroundColor:
                    currentQuantity >= item.maxQuantity
                      ? Colors[colorScheme].card
                      : headquarter.color + "55",
                },
              ]}
              onPress={() => {
                addItemToCart(itemId);
              }}
              disabled={currentQuantity >= item.maxQuantity}
            >
              <MaterialIcons
                size={24}
                name="add"
                color={Colors[colorScheme].text}
              />
            </TouchableOpacity>
          )}
          {showRemoveButton && (
            <TouchableOpacity
              style={[
                styles.button,
                {
                  backgroundColor:
                    currentQuantity <= 0
                      ? Colors[colorScheme].card
                      : headquarter.color + "55",
                },
              ]}
              onPress={() => {
                removeItemFromCart(itemId);
              }}
              disabled={currentQuantity <= 0}
            >
              <MaterialIcons
                size={24}
                name={
                  currentQuantity == 1 && deleteButton ? "delete" : "remove"
                }
                color={Colors[colorScheme].text}
              />
            </TouchableOpacity>
          )}
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  foodItem: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
    borderRadius: 12,
    paddingVertical: 3,
    paddingLeft: 16,
    minHeight: 80,
  },
  details: {
    flex: 1,
    backgroundColor: "transparent",
  },
  detailsHeader: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "transparent",
    flex: 1,
  },
  quantity: {
    fontSize: 18,
    fontWeight: "bold",
    marginRight: 4,
    color: "#5AC2FF",
  },
  name: {
    fontSize: 18,
    fontWeight: "bold",
    marginRight: 10,
  },
  description: {
    fontSize: 14,
    marginVertical: 2,
  },
  detail: {
    fontSize: 12,
    color: "grey",
  },
  image: {
    width: 50,
    height: 50,
    marginHorizontal: 6,
  },

  buttonContainer: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent",
    width: 40,
  },

  button: {
    alignItems: "center",
    justifyContent: "center",
    width: 32,
    minHeight: 32,
    borderRadius: 5,
    marginVertical: 2,
    flex: 1,
  },

  buttonText: {
    fontSize: 24,
    color: "grey",
  },
});

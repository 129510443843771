import { StyleSheet } from "react-native";

import { Text, View } from "./Themed";

import { OrderData } from "../types";
import React, { useContext } from "react";
import { clock, formatDate, formatDateEnglish, LISTEUX } from "../utils";
import { MaterialIcons } from "@expo/vector-icons";
import useColorScheme from "../hooks/useColorScheme";
import Colors from "../constants/Colors";
import { HeadquartersContext } from "../context/HeadquartersContext";

export default function OrderInfo({
  order,
  elapsedTime,
  distance,
}: {
  order: OrderData;
  elapsedTime: number;
  distance?: number;
}) {
  const colorScheme = useColorScheme();
  const { headquarters, items, adminConfig } = useContext(HeadquartersContext);

  const itemsCount = (itemList: { [key: string]: number }) => {
    let count = 0;
    for (const key in itemList) {
      if (itemList.hasOwnProperty(key)) {
        count += itemList[key];
      }
    }
    return count;
  };

  const itemsCost = (itemList: { [key: string]: number }) => {
    let cost = 0;
    for (const key in itemList) {
      if (itemList.hasOwnProperty(key)) {
        const item = items[key];
        if (item != undefined) {
          cost += item.price * itemList[key];
        }
      }
    }
    return cost.toFixed(2);
  };

  return (
    <View>
      <View style={styles.userInfo}>
        <MaterialIcons
          name="apartment"
          size={24}
          color={Colors[colorScheme].text}
          style={{ marginRight: 4 }}
        />
        <Text style={styles.userInfoText}>
          {headquarters[order.data.headquarterId].name} (
          {headquarters[order.data.headquarterId].position})
        </Text>
      </View>
      <View style={styles.userInfo}>
        <MaterialIcons
          name="timer"
          size={24}
          color={Colors[colorScheme].text}
          style={{ marginRight: 4 }}
        />
        <Text style={styles.userInfoText}>
          {formatDate(order.data.datetime)} (il y a {clock(elapsedTime, 3 * 60)}
          )
        </Text>
      </View>
      <View style={styles.userInfo}>
        <MaterialIcons
          name="person"
          size={24}
          color={Colors[colorScheme].text}
          style={{ marginRight: 4 }}
        />
        <Text style={styles.userInfoText}>
          {order.user.displayName} ({order.user.username})
        </Text>
      </View>
      <View style={styles.userInfo}>
        <MaterialIcons
          name="phone"
          size={24}
          color={Colors[colorScheme].text}
          style={{ marginRight: 4 }}
        />
        <Text style={styles.userInfoText}>{order.data.phoneNumber}</Text>
      </View>
      {distance && (
        <View style={styles.userInfo}>
          <MaterialIcons
            name="my-location"
            size={24}
            color={Colors[colorScheme].text}
            style={{ marginRight: 4 }}
          />
          <Text style={styles.userInfoText}>{distance.toFixed(2)} km</Text>
        </View>
      )}
      {order.data.deliveryBy && (
        <View style={styles.userInfo}>
          <MaterialIcons
            name="group"
            size={24}
            color={Colors[colorScheme].text}
            style={{ marginRight: 4 }}
          />
          <Text style={styles.userInfoText}>
            Gérée par{" "}
            {order.data.deliveryBy.map((u) => LISTEUX[u]).join(" et ")}
          </Text>
        </View>
      )}
      <View style={styles.userInfo}>
        <MaterialIcons
          name="location-on"
          size={24}
          color={Colors[colorScheme].text}
          style={{ marginRight: 4 }}
        />
        <Text style={styles.userInfoText}>
          {order.data.address?.formattedAddress}
          {order.data.address?.userInput.complement
            ? ` (${order.data.address.userInput.complement})`
            : ""}
        </Text>
      </View>
      {order.data.address &&
        order.data.address.userInput &&
        order.data.address.userInput.details != "" && (
          <View style={styles.userInfo}>
            <MaterialIcons
              name="live-help"
              size={24}
              color={Colors[colorScheme].text}
              style={{ marginRight: 4 }}
            />
            <Text style={styles.userInfoText}>
              {order.data.address.userInput.details}
            </Text>
          </View>
        )}
      {Object.keys(adminConfig.birthdays).includes(order.user.username) && adminConfig.birthdays[order.user.username] == formatDateEnglish(order.data.datetime) && (
        <View style={styles.userInfo}>
          <MaterialIcons
            name="cake"
            size={24}
            color="#9b59b6"
            style={{ marginRight: 4 }}
          />
          <Text style={[styles.userInfoText, { color: "#9b59b6" }]}>
            Anniversaire du client
          </Text>
        </View>
      )}
      {Object.keys(order.data.items).includes("zspecial") && (
        <View style={styles.userInfo}>
          <MaterialIcons
            name="warning"
            size={24}
            color="#e74c3c"
            style={{ marginRight: 4 }}
          />
          <Text style={[styles.userInfoText, { color: "#e74c3c" }]}>
            Demande spéciale (voir commentaire)
          </Text>
        </View>
      )}
      {order.data.comment && (
        <View style={styles.userInfo}>
          <MaterialIcons
            name="comment"
            size={24}
            color="#e74c3c"
            style={{ marginRight: 4 }}
          />
          <Text style={[styles.userInfoText, { color: "#e74c3c" }]}>
            {order.data.comment}
          </Text>
        </View>
      )}
      <View style={styles.userInfo}>
        <MaterialIcons
          name="shopping-cart"
          size={24}
          color={Colors[colorScheme].text}
          style={{ marginRight: 4 }}
        />
        <Text style={styles.userInfoText}>
          {itemsCount(order.data.items).toFixed(2)} articles -{" "}
          {itemsCost(order.data.items)}€
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  userInfo: {
    marginBottom: 2,
    flexDirection: "row",
  },
  userInfoText: {
    fontSize: 16,
  },
});

import { StyleSheet } from "react-native";

import { Text, View } from "./Themed";

import { Headquarter, Item, ItemList } from "../types";
import ItemCard from "../components/ItemCard";
import { useContext } from "react";
import { HeadquartersContext } from "../context/HeadquartersContext";

export default function ItemCardList({
  items,
  headquarterId,
  headquarter,
  itemList,
  showAddButton = false,
  showRemoveButton = false,
  hideMaxQuantity = false,
  hideAvailableQuantity = false,
  deleteButton = false,
  title = null,
  smallVersion = false,
}: {
  items: ItemList;
  headquarterId: number;
  headquarter: Headquarter;
  itemList: {[key: string]: number};
  showAddButton?: boolean;
  showRemoveButton?: boolean;
  hideMaxQuantity?: boolean;
  hideAvailableQuantity?: boolean;
  deleteButton?: boolean;
  title: string | null;
  smallVersion?: boolean;
}) {
  const { shoppingCart } = useContext(HeadquartersContext);

  const menuItemCards = Object.entries(items).map(([itemId, item] : [string, Item]) => (
    <ItemCard
      key={itemId}
      itemId={itemId}
      item={item}
      headquarterId={headquarterId}
      headquarter={headquarter}
      showAddButton={showAddButton}
      showRemoveButton={showRemoveButton}
      hideMaxQuantity={hideMaxQuantity}
      hideAvailableQuantity={hideAvailableQuantity}
      deleteButton={deleteButton}
      currentQuantity={itemList[itemId] || 0}
      smallVersion={smallVersion}
    />
  ));

  return (
    <View style={styles.itemList}>
      {title && <Text style={styles.title}>{title}</Text>}
      <View style={{ backgroundColor: "transparent" }}>{menuItemCards}</View>
    </View>
  );
}

const styles = StyleSheet.create({
  itemList: {
    marginTop: 20,
    backgroundColor: "transparent",
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 10,
  },
});

import { Linking, Platform, StyleSheet, TouchableOpacity } from "react-native";
import * as location from "expo-location";

import { Text, View } from "./Themed";

import React, { useContext, useState } from "react";
import { HeadquartersContext } from "../context/HeadquartersContext";
import { MaterialIcons } from "@expo/vector-icons";
import {
  formatDate,
  formatDateEnglish,
  nativeConfirm,
  nativeDialog,
} from "../utils";
import { OrderData } from "../types";
import { useToast } from "react-native-toast-notifications";
import { AuthContext } from "../context/AuthContext";

export default function OrderButtons({
  order,
  navigation,
  showValidateAndCancelButtons = false,
  disableValidateAndCancelButtons = false,
  showItineraryButton = false,
  showCheckListButton = false,
  lastPositionUpdate = null,
  showSpecialRequestSetPriceButton = false,
}: {
  order: OrderData;
  navigation: any;
  showValidateAndCancelButtons?: boolean;
  disableValidateAndCancelButtons?: boolean;
  showItineraryButton?: boolean;
  showCheckListButton?: boolean;
  lastPositionUpdate?: number | null;
  showSpecialRequestSetPriceButton?: boolean;
}) {
  const {
    cancelPendingOrder,
    items,
    updateDeliveryStatus,
    markOrderAsDelivered,
  } = useContext(HeadquartersContext);
  const { userData } = useContext(AuthContext);
  const { adminConfig } = useContext(HeadquartersContext);
  const toast = useToast();

  const [checkedItemsCount, setCheckedItemsCount] = useState<{
    [key: string]: number;
  }>(
    Object.fromEntries(
      Object.entries({...order.data.items, birthday: 0}).map(([itemId, count]) => [itemId, 0])
    )
  );

  const addCheckedItem = (itemId: string, all = false) => {
    if (checkedItemsCount[itemId] < order.data.items[itemId] || (itemId == "birthday" && checkedItemsCount["birthday"] < 1)) {
      setCheckedItemsCount({
        ...checkedItemsCount,
        [itemId]: all
          ? order.data.items[itemId]
          : checkedItemsCount[itemId] + 1,
      });
    }
  };

  const removeCheckedItem = (itemId: string) => {
    if (checkedItemsCount[itemId] > 0) {
      setCheckedItemsCount({
        ...checkedItemsCount,
        [itemId]: checkedItemsCount[itemId] - 1,
      });
    }
  };

  const itemsCost = (itemList: { [key: string]: number }) => {
    let cost = 0;
    for (const key in itemList) {
      if (itemList.hasOwnProperty(key)) {
        const item = items[key];
        if (item != undefined) {
          cost += item.price * itemList[key];
        }
      }
    }
    return cost.toFixed(2);
  };

  const cancelOrder = async () => {
    nativeConfirm(
      "Annuler la commande",
      "Souhaitez-vous vraiment annuler la commande du client ?\n\nAVERTISSEMENT :\nCette action annulera la commande du client sans plus de détails, veillez donc à ce qu'il y ait une bonne raison derrière ! Contactez celui-ci via la messagerie intégrée si nécessaire.",
      () => {
        cancelPendingOrder(order.user.username, order.orderId)
          .then((message) => {
            toast.show(message, {
              type: "success",
            });
          })
          .catch((error) => {
            toast.show(error, {
              type: "danger",
            });
          });
      }
    );
  };

  const validateOrder = async () => {
    nativeConfirm(
      "Assigner la commande",
      "Êtes-vous sûr de vouloir valider et assigner cette commande ?\n\nVérifiez que vous serez en capacité de l'honorer, c'est à dire que toutes les informations nécessaires à la livraison sont correctes. En cas de problème, contactez le client par la messagerie intégrée à l'application en cliquant sur la bulle en haut à droite.\n\nVous devrez ensuite choisir qui seront les personnes en charge de cette commande.",
      () => {
        navigation.navigate("ListeuxChoice", {
          id: order.orderId,
          username: order.user.username,
        });
      }
    );
  };

  const orderFullyPrepared = () => {
    const nonCheckedItems = Object.entries(checkedItemsCount).filter(
      ([itemId, count]) => count < order.data.items[itemId]
    );

    if (nonCheckedItems.length !== 0) {
      nativeDialog(
        "Articles manquants",
        "Tous les articles n'ont pas été comptés.\n\nVeuillez vous assurer de tous les avoir préparés. Si pour une raison ou une autre vous ne pouvez pas donner suite à une demande d'article, veuillez vous assurer de définir l'article comme indisponible dans votre QG, puis cochez toutes les cases pour pouvoir continuer."
      );
    } else {
      nativeConfirm(
        "Terminer la préparation",
        "Voulez-vous terminer la préparation de cette commande ?\n\nVeuillez vous assurer que vous avez bien tout les articles demandés en votre possession, et préparez-vous pour partir en livraison !",
        () => {
          updateListeuxLocation(() => {
            navigation.navigate("OngoingOrders");
          });
        },
        "Terminer"
      );
    }
  };

  const updateListeuxLocation = (callback: () => void) => {
    location.requestForegroundPermissionsAsync().then(({ status }) => {
      if (status !== "granted") {
        toast.show("Vous devez autoriser l'accès à votre position", {
          type: "danger",
        });
      }
      location.getCurrentPositionAsync({}).then(({ coords }) => {
        updateDeliveryStatus(order.user.username, order.orderId, {
          latitude: coords.latitude,
          longitude: coords.longitude,
        })
          .then((message) => {
            toast.show(message, {
              type: "success",
            });
            callback();
          })
          .catch((message) => {
            toast.show(message, {
              type: "danger",
            });
          });
      });
    });
  };

  const openItinerary = () => {
    if (Platform.OS === "ios") {
      Linking.openURL(
        `http://maps.apple.com/?q=${order.data.address?.formattedAddress}`
      );
    } else {
      Linking.openURL(
        `https://maps.google.com/?q=${order.data.address?.formattedAddress}`
      );
    }
  };

  const markAsDelivered = () => {
    nativeConfirm(
      "Marquer comme livré",
      "Êtes-vous sûr de vouloir marquer cette commande comme livrée ?" +
        (itemsCost(order.data.items) != "0.00"
          ? `\n\nVeuillez vous assurer de bien avoir procédé à l'encaissement de ${itemsCost(
              order.data.items
            )}€ sur Lydia Pro !`
          : ""),
      () => {
        markOrderAsDelivered(order.user.username, order.orderId)
          .then((message) => {
            toast.show(message, {
              type: "success",
            });
            navigation.navigate("PendingOrders");
          })
          .catch((message) => {
            toast.show(message, {
              type: "danger",
            });
          });
      },
      "C'est livré chef !"
    );
  };

  const payement = () => {
    nativeConfirm(
      "Paiement",
      `Voulez-vous procéder au paiement ?\n\nVous devrez demander au client de générer un QR Code de paiment de ${itemsCost(
        order.data.items
      )}€ dans son application Lydia, puis vous devrez le scanner avec Lydia Pro pour finaliser le paiement.`,
      () => {
        if (Platform.OS === "android") {
          Linking.openURL("lydiapro://");
        }
      },
      "Z'est parti !"
    );
  };

  const setSpecialRequestSetPrice = () => {
    navigation.navigate("SpecialPriceChoice", {
      id: order.orderId,
      username: order.user.username,
    });
  };

  return (
    <View>
      {showSpecialRequestSetPriceButton && (
        <TouchableOpacity
          style={[styles.button, { backgroundColor: "#f1c40f" }]}
          onPress={() => {
            setSpecialRequestSetPrice();
          }}
        >
          <MaterialIcons
            name="attach-money"
            size={24}
            color="white"
            style={styles.buttonIcon}
          />
          <Text style={styles.buttonText}>Définir prix demande spéciale</Text>
        </TouchableOpacity>
      )}
      <View style={styles.horizontalContainer}>
        <TouchableOpacity
          style={[styles.button, { flex: 1, marginRight: 8 }]}
          onPress={() => {
            navigation.navigate("OrderMessages", {
              id: order.orderId,
              username: order.user.username,
            });
          }}
        >
          <MaterialIcons
            name="message"
            size={24}
            color="white"
            style={styles.buttonIcon}
          />
          <Text style={styles.buttonText}>
            {Object.entries(order.data.messages || {}).reverse()[0]?.[1]
              .datetime >= (order.data.lastSeenAdmin || "")
              ? "1 message non lu"
              : "Messagerie"}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.button, { backgroundColor: "#2c3e50" }]}
          onPress={() => {
            nativeConfirm(
              "Appeler le client",
              "Voulez-vous appeler le client en numéro masqué ?",
              () => {
                if (Platform.OS === "web") {
                  window.open(
                    `tel:%2331%23${order.user.phoneNumber}`,
                    "_system"
                  );
                } else {
                  Linking.openURL(`tel:%2331%23${order.user.phoneNumber}`);
                }
              },
              "Oui"
            );
          }}
        >
          <MaterialIcons name="phone" size={24} color="white" />
        </TouchableOpacity>
      </View>
      {showItineraryButton && (
        <View>
          <View style={styles.horizontalContainer}>
            <TouchableOpacity
              style={[styles.button, { backgroundColor: "#9b59b6", flex: 1 }]}
              onPress={() => {
                openItinerary();
              }}
            >
              <MaterialIcons
                name="directions-car"
                size={24}
                color="white"
                style={styles.buttonIcon}
              />
              <Text style={styles.buttonText}>Itinéraire</Text>
            </TouchableOpacity>
            {itemsCost(order.data.items) != "0.00" && (
              <TouchableOpacity
                style={[
                  styles.button,
                  { backgroundColor: "#f1c40f", marginLeft: 8 },
                ]}
                onPress={() => {
                  payement();
                }}
              >
                <MaterialIcons
                  name="attach-money"
                  size={24}
                  color="white"
                  style={styles.buttonIcon}
                />
                <Text style={styles.buttonText}>
                  Encaisser ({itemsCost(order.data.items)}€)
                </Text>
              </TouchableOpacity>
            )}
          </View>
          <TouchableOpacity
            style={[styles.button, { backgroundColor: "#1abc9c" }]}
            onPress={() => {
              markAsDelivered();
            }}
          >
            <MaterialIcons
              name="local-shipping"
              size={24}
              color="white"
              style={styles.buttonIcon}
            />
            <Text style={styles.buttonText}>Livraison/prestation terminée</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              styles.button,
              {
                backgroundColor:
                  !userData ||
                  !order.data.deliveryBy?.includes(userData.username)
                    ? "gray"
                    : (!lastPositionUpdate || lastPositionUpdate) > 180
                    ? "#f39c12"
                    : "#2ecc71",
              },
            ]}
            onPress={() => {
              updateListeuxLocation(() => {});
            }}
            disabled={
              !userData || !order.data.deliveryBy?.includes(userData.username)
            }
          >
            <MaterialIcons
              name="my-location"
              size={24}
              color="white"
              style={styles.buttonIcon}
            />
            <Text style={styles.buttonText}>
              Mise à jour position
              {lastPositionUpdate
                ? ` (il y a ${lastPositionUpdate.toFixed(0)}s)`
                : ""}
            </Text>
          </TouchableOpacity>
          {order.data.nextStepExpectedTime && (
            <Text style={{ marginTop: 10, textAlign: "center" }}>
              Arrivée prévue vers{" "}
              {formatDate(order.data.nextStepExpectedTime).split(" ")[1]}
            </Text>
          )}
        </View>
      )}
      {showValidateAndCancelButtons && (
        <View style={styles.horizontalContainer}>
          <TouchableOpacity
            style={[
              styles.button,
              {
                backgroundColor: disableValidateAndCancelButtons
                  ? "grey"
                  : "#c0392b",
                marginRight: 8,
              },
            ]}
            onPress={cancelOrder}
            disabled={disableValidateAndCancelButtons}
          >
            <MaterialIcons
              name="cancel"
              size={24}
              color="white"
              style={styles.buttonIcon}
            />
            <Text style={styles.buttonText}>Annuler</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              styles.button,
              {
                backgroundColor: disableValidateAndCancelButtons
                  ? "grey"
                  : "#2ecc71",
                flex: 1,
              },
            ]}
            onPress={validateOrder}
            disabled={disableValidateAndCancelButtons}
          >
            <MaterialIcons
              name="check"
              size={24}
              color="white"
              style={styles.buttonIcon}
            />
            <Text style={styles.buttonText}>Valider et assigner</Text>
          </TouchableOpacity>
        </View>
      )}
      {showCheckListButton && (
        <View>
          <Text style={styles.checklistTitle}>Checklist :</Text>
          {Object.entries(order.data.items).map(([itemId, count]) => (
            <View key={itemId} style={styles.checklistItem}>
              <Text style={styles.checklistItemText}>
                {checkedItemsCount[itemId]}/{count}x{" "}
                {items[itemId].shortname
                  ? items[itemId].shortname
                  : items[itemId].name}
              </Text>
              <View style={styles.horizontalContainer}>
                <TouchableOpacity
                  style={[
                    styles.checklistItemButton,
                    { backgroundColor: checkedItemsCount[itemId] > 0 ? "#e74c3c" : "grey" },
                  ]}
                  onPress={() => removeCheckedItem(itemId)}
                >
                  <MaterialIcons name={count > 1 ? "remove" : "close"} size={24} color="white" />
                </TouchableOpacity>
                {count > 1 && (
                <TouchableOpacity
                  style={[styles.checklistItemButton, {backgroundColor: checkedItemsCount[itemId] < count ? "#3498db" : "grey"}]}
                  onPress={() => addCheckedItem(itemId)}
                >
                  <MaterialIcons name="add" size={24} color="white" />
                </TouchableOpacity>
                )}
                  <TouchableOpacity
                  style={[styles.checklistItemButton, {backgroundColor: checkedItemsCount[itemId] < count ? "#2ecc71" : "grey"}]}
                  onPress={() => addCheckedItem(itemId, true)}
                >
                  <MaterialIcons name="check" size={24} color="white" />
                </TouchableOpacity>
              </View>
            </View>
          ))}
          {Object.keys(adminConfig.birthdays).includes(order.user.username) &&
            adminConfig.birthdays[order.user.username] ==
              formatDateEnglish(order.data.datetime) && (
              <View style={styles.checklistItem}>
                <Text style={styles.checklistItemText}>
                  {checkedItemsCount["birthday"]}/1x Spéc. anniv.
                </Text>
                <View style={styles.horizontalContainer}>
                  <TouchableOpacity
                    style={[
                      styles.checklistItemButton,
                      { backgroundColor: checkedItemsCount["birthday"] > 0 ? "#e74c3c" : "grey" },
                    ]}
                    onPress={() => removeCheckedItem("birthday")}
                  >
                    <MaterialIcons name="close" size={24} color="white" />
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[styles.checklistItemButton, {backgroundColor: checkedItemsCount["birthday"] < 1 ? "#2ecc71" : "grey"}]}
                    onPress={() => addCheckedItem("birthday")}
                  >
                    <MaterialIcons name="check" size={24} color="white" />
                  </TouchableOpacity>
                </View>
              </View>
            )}

          <TouchableOpacity
            style={[
              styles.button,
              {
                backgroundColor: "#2ecc71",
                flex: 1,
              },
            ]}
            onPress={orderFullyPrepared}
          >
            <MaterialIcons
              name="check"
              size={24}
              color="white"
              style={styles.buttonIcon}
            />
            <Text style={styles.buttonText}>J'ai tout, livraison !</Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#3498db",
    marginTop: 10,
    padding: 12,
    borderRadius: 8,
  },
  buttonIcon: {
    marginRight: 10,
  },
  buttonText: {
    fontSize: 14,
    color: "white",
    textAlign: "center",
  },
  horizontalContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },

  checklistTitle: {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 10,
  },
  checklistItem: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  checklistItemText: {
    fontSize: 16,
  },
  checklistItemButton: {
    backgroundColor: "#9b59b6",
    padding: 3,
    borderRadius: 8,
    margin: 5,
  },
});

import * as React from "react"
import Svg, { SvgProps, G, Path, Circle } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    height={255}
    width={256}
    data-name="Layer 1"
    {...props}
  >
    <G transform="scale(.52693)">
      <Path
        d="M320.459 128.097h-79.742a11.478 11.478 0 0 1-9.065-4.393 11.622 11.622 0 0 1-2.177-9.968 201.63 201.63 0 0 0 0-93.647 11.624 11.624 0 0 1 2.176-9.967 11.478 11.478 0 0 1 9.066-4.394h79.742a11.623 11.623 0 0 1 11.439 9.755 337.961 337.961 0 0 1 0 102.859 11.623 11.623 0 0 1-11.44 9.755z"
        fill="#e6e6e6"
      />
      <Path
        d="M240.717 11.728a5.513 5.513 0 0 0-4.354 2.108 5.66 5.66 0 0 0-1.054 4.854 207.656 207.656 0 0 1 0 96.445 5.656 5.656 0 0 0 1.054 4.853 5.511 5.511 0 0 0 4.354 2.11h79.742a5.582 5.582 0 0 0 5.509-4.668 331.985 331.985 0 0 0 0-101.035 5.582 5.582 0 0 0-5.51-4.667z"
        fill="#fff"
      />
      <Path
        d="M303.057 40.579h-41.845a6.006 6.006 0 0 1-6-6v-2.345a6.006 6.006 0 0 1 6-6h41.845a6.006 6.006 0 0 1 6 6v2.345a6.006 6.006 0 0 1-6 6zM303.057 70.204h-41.845a6.006 6.006 0 0 1-6-6V61.86a6.006 6.006 0 0 1 6-6h41.845a6.006 6.006 0 0 1 6 6v2.345a6.006 6.006 0 0 1-6 6zM303.057 99.83h-41.845a6.006 6.006 0 0 1-6-6v-2.344a6.006 6.006 0 0 1 6-6h41.845a6.006 6.006 0 0 1 6 6v2.345a6.006 6.006 0 0 1-6 6zM470.459 204.097h-79.742a11.478 11.478 0 0 1-9.065-4.393 11.622 11.622 0 0 1-2.177-9.968 201.63 201.63 0 0 0 0-93.647 11.624 11.624 0 0 1 2.176-9.967 11.478 11.478 0 0 1 9.066-4.394h79.742a11.623 11.623 0 0 1 11.439 9.755 337.961 337.961 0 0 1 0 102.859 11.623 11.623 0 0 1-11.44 9.755z"
        fill="#e6e6e6"
      />
      <Path
        d="M390.717 87.728a5.513 5.513 0 0 0-4.354 2.108 5.66 5.66 0 0 0-1.054 4.854 207.656 207.656 0 0 1 0 96.445 5.656 5.656 0 0 0 1.054 4.853 5.511 5.511 0 0 0 4.354 2.11h79.742a5.582 5.582 0 0 0 5.509-4.668 331.985 331.985 0 0 0 0-101.035 5.582 5.582 0 0 0-5.51-4.667z"
        fill="#fff"
      />
      <Path
        d="M311.459 290.597h-79.742a11.975 11.975 0 0 1-9.458-4.584 12.12 12.12 0 0 1-2.27-10.394 201.131 201.131 0 0 0 0-93.413 12.12 12.12 0 0 1 2.27-10.394 11.976 11.976 0 0 1 9.458-4.584h79.742a12.127 12.127 0 0 1 11.933 10.18 338.46 338.46 0 0 1 0 103.01 12.127 12.127 0 0 1-11.933 10.18z"
        fill="#f2f2f2"
      />
      <Path
        d="M453.057 131.579h-41.845a6.006 6.006 0 0 1-6-6v-2.345a6.006 6.006 0 0 1 6-6h41.845a6.006 6.006 0 0 1 6 6v2.345a6.006 6.006 0 0 1-6 6zM453.057 161.204h-41.845a6.006 6.006 0 0 1-6-6v-2.344a6.006 6.006 0 0 1 6-6h41.845a6.006 6.006 0 0 1 6 6v2.345a6.006 6.006 0 0 1-6 6z"
        fill="#e6e6e6"
      />
      <Circle cx={271.811} cy={228.5} r={23} fill="#fff" />
      <Path
        d="M282.811 225.5h-8v-8a3 3 0 0 0-6 0v8h-8a3 3 0 0 0 0 6h8v8a3 3 0 0 0 6 0v-8h8a3 3 0 0 0 0-6z"
        fill="#e6e6e6"
      />
      <Path
        d="M300.811 17h-42a4.505 4.505 0 0 1-4.5-4.5v-8a4.505 4.505 0 0 1 4.5-4.5h42a4.505 4.505 0 0 1 4.5 4.5v8a4.505 4.505 0 0 1-4.5 4.5zM452.811 94h-42a4.505 4.505 0 0 1-4.5-4.5v-8a4.505 4.505 0 0 1 4.5-4.5h42a4.505 4.505 0 0 1 4.5 4.5v8a4.505 4.505 0 0 1-4.5 4.5z"
        fill="#ccc"
      />
      <Path fill="#ffb8b8" d="m88.594 423.773.002 47.288h12.26l3.833-47.288z" />
      <Path
        d="M85.47 467.058H109.613A15.386 15.386 0 0 1 125 482.443v.5H85.47z"
        fill="#2f2e41"
      />
      <Path fill="#ffb8b8" d="m22.594 423.773.002 47.288h12.26l5.833-47.288z" />
      <Path
        d="M19.47 467.058H43.613A15.386 15.386 0 0 1 59 482.443v.5H19.47zM24.771 456.123a4.981 4.981 0 0 1-3.375-1.319 4.961 4.961 0 0 1-1.616-3.537l-4.916-176.213 69.811 17.452 21.54 64.618a70.461 70.461 0 0 1 3.545 25.824l-2.675 62.636a4.996 4.996 0 0 1-4.994 4.76H90.382a5.023 5.023 0 0 1-4.955-4.33l-8.369-69.142a37.823 37.823 0 0 0-5.531-15.164l-16.47-26.076a1 1 0 0 0-1.838.41L40.295 451.13a4.993 4.993 0 0 1-4.687 4.397l-10.552.587c-.095.005-.19.009-.285.009z"
        fill="#2f2e41"
      />
      <Circle cx={73.058} cy={136.406} r={24.561} fill="#ffb8b8" />
      <Path
        d="M84.34 299.672a5.076 5.076 0 0 1-1.252-.159h-.001l-69.264-17.76a4.998 4.998 0 0 1-3.663-5.815l15.907-85.69a31.214 31.214 0 0 1 18.25-22.54 30.113 30.113 0 0 1 28.265 2.075c.97.605 1.947 1.264 2.903 1.96a30.96 30.96 0 0 1 12.579 24.53l1.265 98.329a5.007 5.007 0 0 1-4.988 5.07z"
        fill="#2ecc71"
      />
      <Path
        d="M20.95 300.68a10.056 10.056 0 0 1 4.213-14.832l-3.08-35.602 16.326 8.848.422 32.452a10.11 10.11 0 0 1-17.882 9.134z"
        fill="#ffb8b8"
      />
      <Path
        d="M26.782 281.14a5.532 5.532 0 0 1-1.366-.174 5.496 5.496 0 0 1-3.972-3.986l-8.023-31.884a47.37 47.37 0 0 1 3.761-33.135l16.81-32.881a15.54 15.54 0 0 1 18.807-11.019 15.356 15.356 0 0 1 9.475 7.1 15.567 15.567 0 0 1 1.654 11.914L40.001 240.58l.284 32.035a5.519 5.519 0 0 1-3.585 5.205l-8.007 2.973a5.48 5.48 0 0 1-1.911.345z"
        fill="#2ecc71"
      />
      <Path
        d="M141.318 287.585a10.056 10.056 0 0 1-8.493-12.87l-28.994-20.89 17.357-6.6 24.872 20.848a10.11 10.11 0 0 1-4.742 19.512z"
        fill="#ffb8b8"
      />
      <Path
        d="M126.14 272.33a5.522 5.522 0 0 1-2.463-.581l-29.399-14.717a47.381 47.381 0 0 1-22.66-24.466l-13.958-34.19a15.544 15.544 0 0 1 3.918-21.444 15.352 15.352 0 0 1 11.59-2.544A15.57 15.57 0 0 1 83.25 180.9l24.945 53.04 24.466 20.683a5.518 5.518 0 0 1 1.607 6.111l-2.97 8.009a5.474 5.474 0 0 1-2.007 2.596 5.497 5.497 0 0 1-3.15.992z"
        fill="#2ecc71"
      />
      <Path
        d="M67.9 161.343c1.305.571 3.977-9.827 2.78-11.907-1.78-3.093-1.675-3.07-2.856-5.117-1.182-2.046-1.447-4.847.084-6.648 1.53-1.8 5.072-1.561 5.77.696-.45-4.288 3.792-7.734 7.993-8.703 4.201-.968 8.633-.367 12.857-1.23 4.902-1 10.003-5.109 8.047-10.5a7.593 7.593 0 0 0-1.481-2.434c-2.26-2.54-5.421-3.626-8.512-4.675-6.43-2.182-13.036-4.392-19.822-4.151a28.798 28.798 0 0 0-25.446 18.609 26.156 26.156 0 0 0-1.084 4.025c-2.33 12.525 4.944 24.878 16.756 29.647z"
        fill="#2f2e41"
      />
      <Path opacity={0.2} d="m42.9 241.343-4 32 .557-32.929L56.9 205.343z" />
      <Path
        d="m197.077 355.982-73.638-30.595a11.478 11.478 0 0 1-6.686-7.536 11.622 11.622 0 0 1 1.814-10.04 201.63 201.63 0 0 0 35.93-86.48 11.624 11.624 0 0 1 5.835-8.369 11.478 11.478 0 0 1 10.058-.579l73.638 30.596a11.623 11.623 0 0 1 6.82 13.398 337.961 337.961 0 0 1-39.464 94.986 11.623 11.623 0 0 1-14.307 4.62z"
        fill="#e6e6e6"
      />
      <Path
        d="M168.088 217.924a5.513 5.513 0 0 0-4.83.276 5.66 5.66 0 0 0-2.836 4.078 207.656 207.656 0 0 1-37.004 89.064 5.656 5.656 0 0 0-.89 4.885l.001.001a5.511 5.511 0 0 0 3.212 3.618l73.638 30.596a5.582 5.582 0 0 0 6.878-2.197 331.986 331.986 0 0 0 38.766-93.302 5.582 5.582 0 0 0-3.297-6.423z"
        fill="#fff"
      />
      <Path
        d="m207.297 286.031-38.642-16.055a6.006 6.006 0 0 1-3.239-7.842l.9-2.166a6.006 6.006 0 0 1 7.842-3.238l38.643 16.055a6.006 6.006 0 0 1 3.238 7.843l-.9 2.165a6.006 6.006 0 0 1-7.842 3.238zM195.93 313.39l-38.642-16.055a6.006 6.006 0 0 1-3.239-7.843l.9-2.165a6.006 6.006 0 0 1 7.842-3.239l38.643 16.056a6.006 6.006 0 0 1 3.238 7.842l-.9 2.165a6.006 6.006 0 0 1-7.842 3.239z"
        fill="#2ecc71"
      />
      <Path
        d="M222.781 247.582a4.49 4.49 0 0 1-1.687-.33l-38.938-15.743a4.505 4.505 0 0 1-2.485-5.859l2.998-7.417a4.5 4.5 0 0 1 5.859-2.484l38.938 15.742a4.505 4.505 0 0 1 2.485 5.859l-2.998 7.417a4.51 4.51 0 0 1-4.172 2.815zM141 483.5H1a1 1 0 1 1 0-2h140a1 1 0 0 1 0 2z"
        fill="#ccc"
      />
    </G>
  </Svg>
)

export default SvgComponent

import React, { useEffect } from "react";

import { StyleSheet, ActivityIndicator, Platform } from "react-native";
import * as WebBrowser from "expo-web-browser";

import { Text, View } from "../components/Themed";
import { ApiResponse, RootStackParamList, UserProfile } from "../types";

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { signIn } from "../auth";
import { nativeDialog } from "../utils";

export default function AuthScreen({ navigation, route }: NativeStackScreenProps<
  RootStackParamList,
  "Auth"
>) {
  useEffect(() => {
    if (Platform.OS === "web") {
      if (route.params.ticket && route.params.token) {
        const { ticket, token } = route.params;
        fetch(
          `https://deliveirb.aboin.fr/api/universal-auth?token=${token}&ticket=${ticket}`
        )
          .then((res) => res.json())
          .then((json: ApiResponse<UserProfile & { token: string }>) => {
            if (json.status === "ok") {
              const { token } = json.data;
              signIn(token).then((displayName) => {
                navigation.navigate("Root", { screen: "Account", params: { message: `Bienvenue ${displayName} !` } });
              });
            } else {
              navigation.navigate("Root", { screen: "Account", params: { message: `Erreur d'authentification:\n\n${json.message}` } });
            }
          });
      }
      //WebBrowser.maybeCompleteAuthSession();
    } else {
      navigation.goBack();
    }
  }, []);

  return (
    <View style={styles.loadingIndicator}>
      <ActivityIndicator size="large" color="#2980b9" />
      <Text>Veuillez patienter, nous vous connectons...</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  loadingIndicator: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

import {
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
  ActivityIndicator,
  Platform,
  Pressable,
} from "react-native";

import { MaterialIcons } from "@expo/vector-icons";

import { Text, View } from "../components/Themed";

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList, Address, VerifiedAddress } from "../types";

import React, { useState, useEffect, useContext } from "react";

import * as Location from "expo-location";

import { AuthContext } from "../context/AuthContext";
import WebView from "react-native-webview";
import useColorScheme from "../hooks/useColorScheme";
import Colors from "../constants/Colors";
import { nativeDialog } from "../utils";

export default function LocationSelectorScreen({
  navigation,
  route,
}: NativeStackScreenProps<RootStackParamList, "LocationSelector">) {
  const colorScheme = useColorScheme();
  // State to hold the address
  const [address, setAddress] = useState(route.params.address);
  const [formattedAddress, setFormattedAddress] = useState(
    route.params.address?.formattedAddress || null
  );
  const [addressToVeriy, setAddressToVeriy] = useState<Address | null>(null);
  const [loading, setLoading] = useState(false);

  const { getAddressesFromLocation, constructVerifiedAddress } =
    useContext(AuthContext);

  useEffect(() => {
    if (formattedAddress) {
      setLoading(true);
    }
  }, [formattedAddress]);

  // Function to use current location
  const useCurrentLocation = () => {
    (async () => {
      if (!loading) {
        let { status } = await Location.requestForegroundPermissionsAsync();
        if (status !== "granted") {
          nativeDialog(
            "Erreur",
            "Vous devez autoriser l'accès à votre géolocalisation pour que l'application puisse vous localiser"
          );
          return;
        }
        setLoading(true);
        let location = await Location.getCurrentPositionAsync({});
        getAddressesFromLocation(
          location.coords.latitude,
          location.coords.longitude
        )
          .then((results) => {
            if (results.length > 0) {
              if (results[0].formatted_address != formattedAddress) {
                setFormattedAddress(results[0].formatted_address);
                const addressToVerify = constructVerifiedAddress(
                  {
                    number: "",
                    street: "",
                    complement: "",
                    postal_code: "",
                    city: "",
                    details: "",
                  },
                  results[0]
                );
                setAddress(addressToVerify);
                setAddressToVeriy(addressToVerify.userInput);
              } else {
                setLoading(false);
              }
            }
          })
          .catch((error) => {
            setLoading(false);
            nativeDialog(
              "Erreur",
              `Impossible de récupérer votre position\n\n${error}`
            );
          });
      }
    })();
  };

  useEffect(() => {
    if (!formattedAddress) {
      useCurrentLocation();
    }

    navigation.setOptions({
      headerLeft: () =>
        Platform.OS === "web" ? (
          <Pressable
            onPress={() => {
              navigation.goBack();
            }}
            style={({ pressed }) => ({
              opacity: pressed ? 0.5 : 1,
              width: 48,
              height: 48,
              alignItems: "center",
              justifyContent: "center",
              marginRight: 4,
            })}
          >
            <MaterialIcons
              name="arrow-back"
              size={25}
              color={Colors[colorScheme].text}
              style={{ marginLeft: 15 }}
            />
          </Pressable>
        ) : undefined,
    });
  }, []);

  return (
    <SafeAreaView style={styles.safeAreaView}>
      <View style={styles.container}>
        <View
          style={{
            flex: 1,
          }}
        >
          {loading ? (
            <View
              style={[
                styles.loadingIndicator,
                { backgroundColor: Colors[colorScheme].container },
              ]}
            >
              <ActivityIndicator size="large" color="#2980b9" />
              <Text style={{ textAlign: "center" }}>
                Veuillez patienter, nous déterminons votre position...
              </Text>
            </View>
          ) : (
            <View></View>
          )}
          {formattedAddress ? (
            <View
              style={{
                flex: 1,
              }}
            >
              <Text style={styles.title}>Adresse séléctionnée :</Text>
              <Text style={styles.address}>
                {formattedAddress}
                {address?.userInput.complement
                  ? ` (${address.userInput.complement})`
                  : ""}
              </Text>

              <View
                style={{
                  flex: 1,
                }}
              >
                {Platform.OS === "web" ? (
                  <iframe
                    onLoad={() => setLoading(false)}
                    width="600"
                    height="500"
                    style={{ width: "100%", height: "100%", border: 0 }}
                    src={`https://maps.google.com/maps?q=${encodeURIComponent(
                      formattedAddress
                    )}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
                    scrolling="no"
                  ></iframe>
                ) : (
                  <WebView
                    onLoad={() => setLoading(false)}
                    source={{
                      html: `<meta name="viewport" content="width=device-width, initial-scale=1.0">
            <style>
            body {
              margin: 0;
            }
            iframe {
              width: 100%;
              height: 100%;
            }
            </style>
            <body>
            <iframe width="600" height="500" src="https://maps.google.com/maps?q=${encodeURIComponent(
              formattedAddress
            )}&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no"></iframe>
              </body>`,
                    }}
                    androidHardwareAccelerationDisabled={true}
                  />
                )}
              </View>
            </View>
          ) : (
            <View
              style={{
                flexDirection: "row",
                flex: 1,
              }}
            ></View>
          )}
        </View>
        <View style={styles.buttonsContainer}>
          <TouchableOpacity
            style={styles.button}
            onPress={() => {
              navigation.navigate("AddressChoice", {
                address: addressToVeriy || address?.userInput || null,
              });
            }}
          >
            <MaterialIcons
              name="edit"
              size={24}
              color="#ffffff"
              style={styles.buttonIcon}
            />
            <Text style={styles.buttonText}>Modifier</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.button, { backgroundColor: "#9b59b6" }]}
            onPress={() => {
              useCurrentLocation();
            }}
          >
            <MaterialIcons
              name="location-on"
              size={24}
              color="#ffffff"
              style={styles.buttonIcon}
            />
            <Text style={styles.buttonText}>Ma position</Text>
          </TouchableOpacity>
        </View>
        <TouchableOpacity
          style={[styles.button, { backgroundColor: "#2ecc71", width: "100%" }]}
          onPress={() => {
            if (!loading) {
              if (addressToVeriy) {
                navigation.navigate("AddressChoice", {
                  address: addressToVeriy,
                });
              } else {
                navigation.goBack();
              }
            }
          }}
        >
          <MaterialIcons
            name="check-circle-outline"
            size={24}
            color="#ffffff"
            style={styles.buttonIcon}
          />
          <Text style={styles.buttonText}>Confirmer l'adresse</Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
  },
  container: {
    flex: 1,
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    marginTop: 10,
    textAlign: "center",
  },
  address: {
    fontSize: 16,
    margin: 10,
    textAlign: "center",
  },
  buttonsContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#3498db",
    paddingVertical: 14,
    width: "50%",
  },
  buttonIcon: {
    marginRight: 10,
  },
  buttonText: {
    fontSize: 16,
    color: "white",
  },
  loadingIndicator: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});

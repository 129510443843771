import { auth } from "./firebase";

import {
  ConfirmationResult,
  signInWithCustomToken,
  linkWithPhoneNumber,
  User,
  unlink,
  UserCredential,
  RecaptchaVerifier,
} from "firebase/auth";
import { FirebaseRecaptchaVerifierModal } from "expo-firebase-recaptcha";

export const signIn = async (token: string) => {
  try {
    return await signInWithCustomToken(auth, token).then(
      (result: UserCredential) => {
        return result.user.displayName;
      }
    );
  } catch (error) {
    console.error(error);
  }
};

export const signOut = async () => {
  //await fetch("https://cas.bordeaux-inp.fr/logout");
  return await auth.signOut();
};

// Function to be called when requesting for a verification code
export const sendVerification = async (
  user: User,
  recaptchaVerifier: FirebaseRecaptchaVerifierModal | RecaptchaVerifier | null,
  phoneNumber: string
) => {
  if (recaptchaVerifier) {
    const verificationResult = await linkWithPhoneNumber(
      user,
      phoneNumber,
      recaptchaVerifier
    );
    return verificationResult;
  } else {
    return null;
  }
};

// Function to be called when confirming the verification code that we received from Firebase via SMS
export const confirmCode = async (
  verificationResult: ConfirmationResult,
  code: string
) => {
  const result = await verificationResult.confirm(code);
  return result;
};

// Function to be called when unlink the phone number from the user
export const unlinkPhoneNumber = async (user: User) => {
  return unlink(user, "phone");
};

import { StyleSheet, ScrollView, TouchableOpacity } from "react-native";

import { MaterialIcons } from "@expo/vector-icons";

import { Text, View } from "../components/Themed";

import { RootTabScreenProps, FoodItem, Restaurant } from "../types";
import React, { useContext, useEffect } from "react";

import { AuthContext } from "../context/AuthContext";
import NoOrdersPicture from "../assets/images/no-orders";
import OrderCard from "../components/OrderCard";
import OrderTrackingCard from "../components/OrderTrackingCard";
import { nativeConfirm } from "../utils";
import { HeadquartersContext } from "../context/HeadquartersContext";
import { useToast } from "react-native-toast-notifications";

export default function OrdersScreen({
  navigation,
}: RootTabScreenProps<"Orders">) {
  const orders = useContext(AuthContext).userData?.orders;
  const user = useContext(AuthContext).user;
  const {cancelPendingOrder} = useContext(HeadquartersContext);
  const toast = useToast();

  useEffect(() => {
    if (orders != undefined) {
    }
  }, [orders]);

  const cancelOrder = async (orderId: string) => {
    if (orders && user) {
      nativeConfirm(
        "Annuler la commande",
        "Souhaitez-vous vraiment annuler la commande ?",
        () => {
          cancelPendingOrder(user.uid, orderId)
            .then((message) => {
              toast.show(message, {
                type: "success",
              });
            })
            .catch((error) => {
              toast.show(error, {
                type: "danger",
              });
            });
        }
      );
    } else {
      toast.show("Vous devez être connecté pour annuler une commande", {
        type: "danger",
      });
    }
  };

  return orders ? (
    <View style={styles.container}>
      <ScrollView style={styles.scrollView}>
        <View style={{ padding: 20 }}>
          {Object.entries(orders).find(
            ([, o]) =>
              o.status === "pending" ||
              o.status === "accepted" ||
              o.status === "ongoing"
          ) && (
            <View>
              <Text style={styles.title}>Commandes en cours</Text>
              {Object.entries(orders)
                .filter(
                  ([, order]) =>
                    order.status === "pending" ||
                    order.status === "accepted" ||
                    order.status === "ongoing"
                )
                .reverse()
                .map(([orderId, order], index, orders) => (
                  <View key={orderId}>
                    <OrderCard
                      order={order}
                      orderId={orderId}
                      clickCallback={() => {
                        navigation.navigate("OrderDetails", { id: orderId });
                      }}
                      displaySeparator={index !== 0}
                    />
                    <OrderTrackingCard
                      order={order}
                      showMessagesCallback={() => {
                        navigation.navigate("OrderMessages", { id: orderId });
                      }}
                      cancelOrderCallback={() => {
                        cancelOrder(orderId);
                      }}
                    />
                  </View>
                ))}
            </View>
          )}
          {Object.entries(orders).find(
            ([, o]) =>
              !(
                o.status === "pending" ||
                o.status === "accepted" ||
                o.status === "ongoing"
              )
          ) && (
            <View>
              <Text style={styles.title}>Historique de commandes</Text>
              {Object.entries(orders)
                .filter(
                  ([, order]) =>
                    !(
                      order.status === "pending" ||
                      order.status === "accepted" ||
                      order.status === "ongoing"
                    )
                )
                .reverse()
                .map(([orderId, order], index, orders) => (
                  <OrderCard
                    key={orderId}
                    order={order}
                    orderId={orderId}
                    clickCallback={() => {
                      navigation.navigate("OrderDetails", { id: orderId });
                    }}
                    displaySeparator={index !== 0}
                  />
                ))}
            </View>
          )}
        </View>
      </ScrollView>
    </View>
  ) : (
    <View style={styles.container}>
      <View style={styles.emptyCart}>
        <NoOrdersPicture style={{ marginVertical: 16 }} />
        <Text style={styles.emptyCartText}>Aucune commande</Text>
        <Text style={styles.emptyCartDescription}>
          Ajoutez des articles à votre panier et faites vous livrer chez vous
          par nos meilleurs livreurs.
        </Text>
        <TouchableOpacity
          style={styles.button}
          onPress={() => {
            navigation.navigate("Home");
          }}
        >
          <MaterialIcons
            name="restaurant"
            size={24}
            color="#ffffff"
            style={styles.buttonIcon}
          />
          <Text style={styles.buttonText}>Parcourir le menu</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    flex: 1,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 16,
    textAlign: "center",
  },

  emptyCart: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  emptyCartText: {
    fontSize: 18,
    fontWeight: "bold",
    color: "grey",
    marginBottom: 10,
  },
  emptyCartDescription: {
    fontSize: 16,
    color: "grey",
    marginBottom: 16,
    textAlign: "center",
    paddingHorizontal: 16,
  },

  button: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#23313F",
    marginBottom: 24,
    padding: 12,
    borderRadius: 8,
    width: "50%",
    minWidth: 220,
  },
  buttonIcon: {
    marginRight: 10,
  },
  buttonText: {
    fontSize: 18,
    color: "white",
    textAlign: "center",
  },
});

import {
  Platform,
  StyleSheet,
  ScrollView,
  ImageBackground,
  TouchableOpacity,
  SafeAreaView,
  Pressable,
} from "react-native";

import { MaterialIcons } from "@expo/vector-icons";

import { Text, View } from "../components/Themed";

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList, Order, OrderData } from "../types";

import React, { useContext, useEffect, useState } from "react";
import useColorScheme from "../hooks/useColorScheme";
import Colors from "../constants/Colors";
import { formatDate, nativeConfirm, ORDER_STATES } from "../utils";
import { AuthContext } from "../context/AuthContext";
import { useToast } from "react-native-toast-notifications";
import { OrdersContext } from "../context/OrdersContext";
import WebView from "react-native-webview";
import { HeadquartersContext } from "../context/HeadquartersContext";
import ItemCardList from "../components/ItemCardList";

export default function OrderDetailsScreen({
  navigation,
  route,
}: NativeStackScreenProps<RootStackParamList, "OrderDetails">) {
  const colorScheme = useColorScheme();
  const toast = useToast();
  const { items, headquarters, cancelPendingOrder } =
    useContext(HeadquartersContext);
  const { userData } = useContext(AuthContext);
  const allOrders = userData?.admin
    ? useContext(OrdersContext).orders
    : undefined;

  const [orderData, setOrderData] = useState<OrderData>();
  const [orderId, setOrderId] = useState<string>(route.params.id);

  // State to hold the order
  const [order, setOrder] = useState<Order | undefined>(undefined);

  const [itemCount, setItemCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    if (order?.items) {
      const itemCount = Object.keys(order.items).reduce(
        (acc, itemId) => acc + order.items[itemId],
        0
      );
      setItemCount(itemCount);
      setTotalPrice(
        Object.keys(order.items).reduce(
          (acc, itemId) => acc + items[itemId].price * order.items[itemId],
          0
        )
      );
    }
  }, [items, headquarters, order?.items]);

  // On load
  useEffect(() => {
    // Get the order
    const username = route.params.username;
    const orderData =
      userData?.admin && username
        ? allOrders?.find(
            (o) => o.user.username == username && o.orderId == orderId
          )
        : undefined;
    if (orderData) {
      setOrderData(orderData);
    }
    const order = orderData?.data
      ? orderData.data
      : userData?.orders
      ? userData.orders[orderId]
      : undefined;

    if (order) {
      setOrder(order);

      navigation.setOptions({
        headerLeft: () =>
          Platform.OS === "web" ? (
            <Pressable
              onPress={() => {
                navigation.goBack();
              }}
              style={({ pressed }) => ({
                opacity: pressed ? 0.5 : 1,
                width: 48,
                height: 48,
                alignItems: "center",
                justifyContent: "center",
                marginRight: 4,
              })}
            >
              <MaterialIcons
                name="arrow-back"
                size={25}
                color={Colors[colorScheme].text}
                style={{ marginLeft: 15 }}
              />
            </Pressable>
          ) : undefined,
        headerRight: () => (
          <Pressable
            onPress={() => {
              navigation.navigate("OrderMessages", {
                id: orderId,
                username: username,
              });
            }}
            style={({ pressed }) => ({
              opacity: pressed ? 0.5 : 1,
              width: 48,
              height: 48,
              alignItems: "center",
              justifyContent: "center",
              marginRight: 4,
            })}
          >
            <MaterialIcons
              name={
                Object.entries(order.messages || []).reverse()[0]?.[1]
                  .datetime >=
                (orderData
                  ? order.lastSeenAdmin || ""
                  : order.lastSeenCustomer || "")
                  ? "mark-chat-unread"
                  : "chat-bubble"
              }
              size={25}
              color={Colors[colorScheme].text}
            />
          </Pressable>
        ),
      });
    }
  }, [userData, allOrders]);

  const cancelOrder = async () => {
    if (userData) {
      nativeConfirm(
        "Annuler la commande",
        "Souhaitez-vous vraiment annuler la commande ?",
        () => {
          cancelPendingOrder(userData.username, orderId)
            .then((message) => {
              toast.show(message, {
                type: "success",
              });
            })
            .catch((error) => {
              toast.show(error, {
                type: "danger",
              });
            });
        }
      );
    } else {
      toast.show("Vous devez être connecté pour annuler une commande", {
        type: "danger",
      });
    }
  };

  return (
    <SafeAreaView style={styles.safeAreaView}>
      <View style={styles.container}>
        {order && headquarters[order.headquarterId] && userData && (
          <ImageBackground
            source={{ uri: headquarters[order.headquarterId].image }}
            resizeMode="cover"
            style={styles.background}
          >
            <View
              style={{
                flex: 1,
                width: "100%",
                backgroundColor: "transparent",
              }}
            >
              <ScrollView>
                <View style={styles.menu}>
                  <Text style={styles.title}>
                    Commande chez {headquarters[order.headquarterId].name}
                  </Text>
                  <Text style={styles.description}>
                    le {formatDate(order.datetime).replace(" ", " à ")}
                  </Text>
                  {orderData && (
                    <View>
                      <Text style={styles.subtitle}>Identité du client</Text>
                      <Text style={styles.value}>
                        {orderData.user.displayName} ({orderData.user.username})
                      </Text>
                      <Text style={styles.value}>
                        -{" "}
                        {
                          allOrders?.filter(
                            (o) => o.user.username == orderData.user.username
                          ).length
                        }{" "}
                        commande(s) en tout
                      </Text>
                    </View>
                  )}
                  <Text style={styles.subtitle}>Statut de la commande</Text>
                  <View
                    style={{ flexDirection: "row", alignItems: "flex-end" }}
                  >
                    <MaterialIcons
                      name={
                        (order.status in ORDER_STATES
                          ? ORDER_STATES[order.status].icon
                          : "warning") as any
                      }
                      size={22}
                      color={
                        order.status in ORDER_STATES
                          ? ORDER_STATES[order.status].color
                          : "#ffc107"
                      }
                    />
                    <Text
                      style={[
                        styles.value,
                        { fontStyle: "italic", marginLeft: 2 },
                      ]}
                    >
                      {order.status in ORDER_STATES
                        ? ORDER_STATES[order.status].text
                        : order.status}
                    </Text>
                  </View>
                  <ItemCardList
                    items={Object.fromEntries(
                      Object.entries(items).filter(([itemId, item]) =>
                        Object.keys(order.items).includes(itemId)
                      )
                    )}
                    headquarterId={order.headquarterId}
                    headquarter={headquarters[order.headquarterId]}
                    hideMaxQuantity={true}
                    hideAvailableQuantity={true}
                    title={`Articles commandés (${itemCount.toFixed(2)})`}
                    itemList={order.items}
                    smallVersion={true}
                  />
                  <View
                    style={[
                      styles.itemCard,
                      { backgroundColor: Colors[colorScheme].card },
                    ]}
                  >
                    <View style={styles.itemCardHeader}>
                      <Text style={styles.itemCardText}>Total à payer</Text>
                      <Text style={styles.itemCardValue}>
                        {totalPrice.toFixed(2)}€
                      </Text>
                    </View>
                  </View>
                  {order.comment && (
                    <View>
                      <Text style={styles.subtitle}>
                        Commentaire de la commande
                      </Text>
                      <Text style={styles.value}>{order.comment}</Text>
                    </View>
                  )}
                  <Text style={styles.subtitle}>Adresse de livraison</Text>
                  <Text style={styles.value}>
                    {order.address?.formattedAddress}
                    {order.address?.userInput.complement
                      ? ` (${order.address.userInput.complement})`
                      : ""}
                  </Text>
                  {order.address?.userInput.details ? (
                    <View>
                      <Text style={styles.subtitle}>
                        Détails pour le livreur
                      </Text>
                      <Text style={styles.value}>
                        {order.address.userInput.details}
                      </Text>
                    </View>
                  ) : null}
                  {order.address?.formattedAddress && (
                    <View style={styles.map}>
                      {Platform.OS === "web" ? (
                        <iframe
                          width="600"
                          height="500"
                          style={{ width: "100%", height: "100%", border: 0 }}
                          src={`https://maps.google.com/maps?q=${encodeURIComponent(
                            order.address.formattedAddress
                          )}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
                          scrolling="no"
                        ></iframe>
                      ) : (
                        <WebView
                          source={{
                            html: `<meta name="viewport" content="width=device-width, initial-scale=1.0">
            <style>
            body {
              margin: 0;
            }
            iframe {
              width: 100%;
              height: 100%;
            }
            </style>
            <body>
            <iframe width="600" height="500" src="https://maps.google.com/maps?q=${encodeURIComponent(
              order.address.formattedAddress
            )}&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no"></iframe>
              </body>`,
                          }}
                          androidHardwareAccelerationDisabled={true}
                        />
                      )}
                    </View>
                  )}
                  <View
                    style={{
                      alignItems: "center",
                    }}
                  >
                    {order.status === "pending" && !orderData && (
                      <TouchableOpacity
                        style={styles.button}
                        onPress={() => {
                          cancelOrder();
                        }}
                      >
                        <MaterialIcons
                          name="cancel"
                          size={22}
                          color="white"
                          style={{ marginRight: 5 }}
                        />
                        <Text style={styles.buttonText}>
                          Annuler la commande
                        </Text>
                      </TouchableOpacity>
                    )}
                  </View>
                </View>
              </ScrollView>
            </View>
          </ImageBackground>
        )}
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
  },
  background: {
    flex: 1,
    justifyContent: "center",
    width: "100%",
    height: 200,
  },
  menu: {
    marginTop: 170,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    flex: 1,
    padding: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
  },
  description: {
    fontSize: 18,
    marginTop: 2,
    color: "grey",
  },

  itemCard: {
    borderRadius: 12,
    paddingVertical: 10,
    paddingHorizontal: 16,
    marginBottom: 10,
  },
  itemCardHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent",
  },
  itemCardText: {
    fontSize: 18,
    fontWeight: "bold",
  },
  itemCardValue: {
    fontSize: 18,
    fontWeight: "bold",
    color: "grey",
  },

  subtitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginTop: 10,
  },
  value: {
    fontSize: 16,
    marginTop: 4,
  },

  map: {
    marginTop: 20,
    height: 300,
    width: "100%",
  },

  button: {
    marginTop: 16,
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: "#c0392b",
    borderRadius: 6,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonText: {
    fontSize: 16,
    color: "#fff",
    textAlign: "center",
  },
});

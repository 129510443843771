import {
  Platform,
  StyleSheet,
  ScrollView,
  ImageBackground,
  Pressable,
  Linking,
  TouchableOpacity,
  SafeAreaView,
} from "react-native";

import { MaterialIcons } from "@expo/vector-icons";

import { Text, View } from "../components/Themed";

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList, Headquarter, ItemList } from "../types";

import React, { useContext, useEffect, useState } from "react";
import useColorScheme from "../hooks/useColorScheme";
import Colors from "../constants/Colors";
import { formatTime, nativeConfirm } from "../utils";
import { AuthContext } from "../context/AuthContext";
import { HeadquartersContext } from "../context/HeadquartersContext";
import ItemCardList from "../components/ItemCardList";

export default function HeadquarterScreen({
  navigation,
  route,
}: NativeStackScreenProps<RootStackParamList, "Headquarter">) {
  const colorScheme = useColorScheme();
  const { userData } = useContext(AuthContext);
  const { headquarters, items, shoppingCart, itemCount, totalPrice } =
    useContext(HeadquartersContext);

  const headquarterId = route.params.id;
  const [headquarter, setHeadquarter] = useState<Headquarter>();

  const [canOrder, setCanOrder] = useState(false);
  const [isWeekend, setIsWeekend] = useState(false);

  useEffect(() => {
    const date = new Date();
    const day = date.getDay();
    setIsWeekend(day === 0 || day === 6);
  }, []);

  useEffect(() => {
    if (
      userData?.address &&
      userData?.travelDetails &&
      userData.travelDetails[headquarterId].covered
    ) {
      setCanOrder(true);
    } else {
      setCanOrder(false);
    }
  }, [userData, headquarterId, headquarter]);

  const [itemsCategory, setItemsCategory] = useState<{
    [key: string]: ItemList;
  }>({
    available: {},
    onlyWeekend: {},
    outOfStock: {},
    unavailable: {},
  });

  useEffect(() => {
    const itemsCategory = {
      available: Object.fromEntries(
        Object.entries(items).filter(
          ([itemId, item]) =>
            item.availability[headquarterId] &&
            item.quantity[headquarterId] > 0 &&
            (!item.weekendOnly || isWeekend)
        )
      ),
      onlyWeekend: isWeekend
        ? {}
        : Object.fromEntries(
            Object.entries(items).filter(([itemId, item]) => item.weekendOnly)
          ),
      outOfStock: Object.fromEntries(
        Object.entries(items).filter(
          ([itemId, item]) =>
            item.availability[headquarterId] &&
            item.quantity[headquarterId] <= 0
        )
      ),
      unavailable: Object.fromEntries(
        Object.entries(items).filter(
          ([itemId, item]) => !item.availability[headquarterId]
        )
      ),
    };
    setItemsCategory(itemsCategory);
  }, [items]);

  useEffect(() => {
    const headquarter = Object.entries(headquarters).find(
      (h) => h[0] === headquarterId.toString()
    )?.[1];
    navigation.setOptions({ headerTitle: headquarter ? headquarter.name : "" });
    if (headquarter) {
      setHeadquarter(headquarter);
      navigation.setOptions({
        title: headquarter.name,
        headerLeft: () =>
          Platform.OS === "web" ? (
            <Pressable
              onPress={() => {
                navigation.goBack();
              }}
              style={({ pressed }) => ({
                opacity: pressed ? 0.5 : 1,
                width: 48,
                height: 48,
                alignItems: "center",
                justifyContent: "center",
                marginRight: 4,
              })}
            >
              <MaterialIcons
                name="arrow-back"
                size={25}
                color={Colors[colorScheme].text}
                style={{ marginLeft: 15 }}
              />
            </Pressable>
          ) : undefined,
        headerRight: () => (
          <Pressable
            onPress={() => {
              nativeConfirm(
                `Appeler le ${headquarter.name} ?`,
                `Si vous avez une demande spéciale et que vous êtes bien couvert par la zone de livraison de ce QG, vous pouvez également passer un appel.\n\nCliquez sur OK pour passer un appel. Une fois sur le standard, vous serez amené à saisir le numéro du QG souhaité. Il s'agit du numéro "${headquarter.phoneNumberCode}".`,
                () => {
                  if (Platform.OS === "web") {
                    window.open(`tel:${headquarter.phoneNumber}`, "_system");
                  } else {
                    Linking.openURL(`tel:${headquarter.phoneNumber}`);
                  }
                },
                "OK"
              );
            }}
            style={({ pressed }) => ({
              opacity: pressed ? 0.5 : 1,
              width: 48,
              height: 48,
              alignItems: "center",
              justifyContent: "center",
              marginRight: 4,
            })}
          >
            <MaterialIcons
              name="phone"
              size={25}
              color={Colors[colorScheme].text}
            />
          </Pressable>
        ),
      });
    }
  }, [headquarters, headquarterId]);

  return (
    <SafeAreaView style={styles.safeAreaView}>
      <View style={styles.container}>
        {headquarter && (
          <ImageBackground
            source={{ uri: headquarter.image + "?1" }}
            resizeMode="cover"
            style={styles.background}
          >
            <View
              style={{
                flex: 1,
                width: "100%",
                backgroundColor: "transparent",
              }}
            >
              <ScrollView>
                <View style={styles.menu}>
                  <Text style={styles.title}>{headquarter.name}</Text>
                  <Text style={styles.position}>{headquarter.position}</Text>
                  <View style={styles.details}>
                    {userData && userData.travelDetails && (
                      <View
                        style={[
                          styles.detail,
                          { backgroundColor: Colors[colorScheme].card },
                        ]}
                      >
                        <MaterialIcons
                          size={16}
                          style={{ paddingTop: 2 }}
                          name="location-pin"
                          color={headquarter.color}
                        />
                        <Text style={styles.detailText}>
                          {(
                            userData.travelDetails[headquarterId].distance /
                            1000
                          ).toFixed(1)}{" "}
                          km
                        </Text>
                      </View>
                    )}
                    {userData && userData.travelDetails && (
                      <View
                        style={[
                          styles.detail,
                          { backgroundColor: Colors[colorScheme].card },
                        ]}
                      >
                        <MaterialIcons
                          size={16}
                          style={{ paddingTop: 2 }}
                          name="schedule"
                          color="#2ecc71"
                        />
                        <Text style={styles.detailText}>
                          {formatTime(
                            userData.travelDetails[headquarterId].duration
                          )}
                          -
                          {formatTime(
                            userData.travelDetails[headquarterId].duration * 1.5
                          )}
                        </Text>
                      </View>
                    )}
                    <View
                      style={[
                        styles.detail,
                        { backgroundColor: Colors[colorScheme].card },
                      ]}
                    >
                      <MaterialIcons
                        size={16}
                        style={{ paddingTop: 2 }}
                        name={headquarter.transport as any}
                        color="#888"
                      />
                      <Text style={styles.detailText}>
                        {headquarter.transportDescription}
                      </Text>
                    </View>
                  </View>
                  {!headquarter.opened && (
                    <View
                      style={[
                        styles.coverageInfo,
                        { borderColor: headquarter.color },
                      ]}
                    >
                      <MaterialIcons
                        size={64}
                        style={{ paddingHorizontal: 4 }}
                        name="timer-off"
                        color={headquarter.color}
                      />
                      <View style={{ flex: 1 }}>
                        <Text
                          style={[
                            styles.coverageInfoTitle,
                            { color: Colors[colorScheme].text },
                          ]}
                        >
                          QG Fermé
                        </Text>
                        <Text style={styles.coverageInfoText}>
                          Impossible de passer commande dans ce QG pour le
                          moment, veuillez revenir aux horaires d'ouverture.
                        </Text>
                      </View>
                    </View>
                  )}
                  {!canOrder && (
                    <View
                      style={[
                        styles.coverageInfo,
                        { borderColor: headquarter.color },
                      ]}
                    >
                      <MaterialIcons
                        size={64}
                        style={{ paddingHorizontal: 4 }}
                        name="location-off"
                        color={headquarter.color}
                      />
                      <View style={{ flex: 1 }}>
                        <Text
                          style={[
                            styles.coverageInfoTitle,
                            { color: Colors[colorScheme].text },
                          ]}
                        >
                          Adresse non{" "}
                          {userData?.address ? "couverte" : "définie"}
                        </Text>
                        <Text style={styles.coverageInfoText}>
                          {userData?.address
                            ? "Vous ne pouvez pas commander dans ce QG car celui-ci se trouve à une trop grande distance de votre lieu de livraison."
                            : "Vous ne pouvez pas commander dans ce QG sans avoir préalablement défini votre adresse"}
                        </Text>
                        {!userData?.address && (
                          <TouchableOpacity
                            onPress={() => navigation.navigate("Account")}
                            style={styles.link}
                          >
                            <Text style={styles.linkText}>
                              Renseigner mon adresse
                            </Text>
                          </TouchableOpacity>
                        )}
                      </View>
                    </View>
                  )}
                  {Object.keys(itemsCategory.available).length > 0 && (
                    <ItemCardList
                      items={itemsCategory.available}
                      itemList={
                        shoppingCart &&
                        shoppingCart.headquarterId === headquarterId
                          ? shoppingCart.items
                          : {}
                      }
                      headquarterId={headquarterId}
                      headquarter={headquarter}
                      showAddButton={canOrder && headquarter.opened}
                      showRemoveButton={canOrder && headquarter.opened}
                      hideAvailableQuantity={true}
                      title="Menu"
                    />
                  )}
                  {Object.keys(itemsCategory.onlyWeekend).length > 0 && (
                    <ItemCardList
                      items={itemsCategory.onlyWeekend}
                      itemList={{}}
                      headquarterId={headquarterId}
                      headquarter={headquarter}
                      hideAvailableQuantity={true}
                      title="Seulement le week-end"
                    />
                  )}
                  {Object.keys(itemsCategory.outOfStock).length > 0 && (
                    <ItemCardList
                      items={itemsCategory.outOfStock}
                      itemList={{}}
                      headquarterId={headquarterId}
                      headquarter={headquarter}
                      hideAvailableQuantity={true}
                      title="À cours de stock"
                    />
                  )}
                  {Object.keys(itemsCategory.unavailable).length > 0 && (
                    <ItemCardList
                      items={itemsCategory.unavailable}
                      itemList={{}}
                      headquarterId={headquarterId}
                      headquarter={headquarter}
                      hideAvailableQuantity={true}
                      title="Indisponible dans ce QG"
                    />
                  )}
                </View>
              </ScrollView>
            </View>
            {userData &&
              shoppingCart &&
              shoppingCart.items &&
              Object.keys(shoppingCart.items).length > 0 && (
                <TouchableOpacity
                  style={[
                    styles.checkoutButton,
                    {
                      backgroundColor:
                        shoppingCart.headquarterId === headquarterId
                          ? "#2ecc71"
                          : headquarter.color,
                    },
                  ]}
                  onPress={() => {
                    navigation.navigate("ShoppingCart");
                  }}
                >
                  <MaterialIcons
                    name="shopping-cart"
                    size={24}
                    color="#ffffff"
                    style={styles.checkoutButtonIcon}
                  />
                  {shoppingCart.headquarterId === headquarterId ? (
                    <Text style={styles.checkoutButtonText}>
                      Voir le panier ({itemCount} article
                      {itemCount > 1 ? "s" : ""} / {totalPrice.toFixed(2)} €)
                    </Text>
                  ) : (
                    <Text style={styles.checkoutButtonText}>
                      Panier en cours dans un autre QG
                    </Text>
                  )}
                </TouchableOpacity>
              )}
          </ImageBackground>
        )}
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
  },
  background: {
    flex: 1,
    justifyContent: "center",
    width: "100%",
    height: 260,
    marginTop: -60,
  },
  menu: {
    marginTop: 200,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    flex: 1,
    padding: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
  },
  position: {
    fontSize: 18,
    marginTop: 2,
    color: "grey",
  },
  details: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: 8,
    backgroundColor: "transparent",
  },
  detail: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 8,
    paddingVertical: 2,
    borderRadius: 6,
    marginRight: 10,
  },
  detailText: {
    fontSize: 12,
    marginLeft: 6,
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },

  checkoutButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    paddingVertical: 14,
  },
  checkoutButtonIcon: {
    marginRight: 10,
  },
  checkoutButtonText: {
    fontSize: 16,
    color: "white",
  },

  coverageInfo: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    paddingVertical: 10,
    paddingHorizontal: 4,
    borderRadius: 16,
    borderWidth: 3,
    marginTop: 20,
  },
  coverageInfoTitle: {
    fontSize: 16,
    fontWeight: "bold",
  },
  coverageInfoText: {
    fontSize: 14,
    color: "grey",
  },

  link: {
    marginTop: 4,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
    textAlign: "center",
  },
});

import { initializeApp } from "firebase/app";
import { initializeAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';

import AsyncStorage from '@react-native-async-storage/async-storage';
import { getReactNativePersistence } from 'firebase/auth/react-native'

// Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyA5qMTKuaCRZgOrIDhnfsaCJBh7I4fsawM",
  authDomain: "deliveirb.aboin.fr",
  databaseURL:
    "https://univeirb-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "univeirb",
  storageBucket: "univeirb.appspot.com",
  messagingSenderId: "596312633504",
  appId: "1:596312633504:web:aab67394eab08b046e59c5",
  measurementId: "G-7GCG59MW0J",
};

// Firebase project initialization
const app = initializeApp(firebaseConfig, "[DEFAULT]");

// Access and export Firebase services
const auth = initializeAuth(app, {
    persistence: getReactNativePersistence(AsyncStorage),
});
const db = getDatabase(app);

export { firebaseConfig, auth, db };


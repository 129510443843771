import React, { useContext, useEffect, useState } from "react";

import {
  StyleSheet,
  Image,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Platform,
} from "react-native";
import * as WebBrowser from "expo-web-browser";
import * as AuthSession from "expo-auth-session";
import base64 from "react-native-base64";
import url from "url";
import { useToast } from "react-native-toast-notifications";

import { Text, View } from "../components/Themed";
import { ApiResponse, RootTabScreenProps, UserProfile } from "../types";

import Logo from "../assets/images/logo";
import { signIn } from "../auth";
import { nativeDialog } from "../utils";
import { MaterialIcons } from "@expo/vector-icons";

export default function LoginScreen({
  navigation,
}: RootTabScreenProps<"Login">) {
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = (alternativeMode = false) => {
    const domain = alternativeMode ? "u-bordeaux.fr" : "bordeaux-inp.fr";
    const redirectUrl = AuthSession.makeRedirectUri({ path: "auth" });
    const serviceUrl = `https://aboin.vvv.enseirb-matmeca.fr/casAuth/?token=${base64.encode(
      redirectUrl
    )}@${domain}`;
    setIsLoading(true);

    if (Platform.OS === "web") {
      window.open(
        `https://cas.${domain}/login?service=${serviceUrl}`,
        "_self",
        "location=no"
      );
    } else {
      WebBrowser.openAuthSessionAsync(
        `https://cas.${domain}/login?service=${serviceUrl}`,
        redirectUrl
      )
        .then((result) => {
          if (result.type === "success") {
            const urlObject = url.parse(result.url, true);
            const token = urlObject.query.token;
            const ticket = urlObject.query.ticket;
            fetch(
              `https://deliveirb.aboin.fr/api/universal-auth?token=${token}&ticket=${ticket}`
            )
              .then((res) => res.json())
              .then((json: ApiResponse<UserProfile & { token: string }>) => {
                if (json.status === "ok") {
                  const { token } = json.data;
                  signIn(token).then((displayName) => {
                    toast.show(`Bienvenue ${displayName} !`);
                  });
                } else {
                  nativeDialog("Erreur de connexion", json.message);
                  setIsLoading(false);
                }
              });
          } else {
            setIsLoading(false);
            toast.show("La connexion a été annulée", {
              type: "danger",
            });
          }
        })
        .catch((error) => {
          nativeDialog("Erreur de connexion", error.message);
          setIsLoading(false);
        });
    }
  };

  return (
    <View style={styles.mainContainer}>
      <ScrollView
        contentContainerStyle={{ flexGrow: 1, justifyContent: "center" }}
      >
        <View style={styles.container}>
          <Logo style={styles.headerLogo} />
          <Text style={styles.subtitle}>
            De nombreux produits et des services, livrés chez vous à la vitesse
            de la lumi'eirb !
          </Text>
          <Image
            style={styles.image}
            source={require("../assets/images/delivery_bee.png")}
          />
          {isLoading ? (
            <TouchableOpacity style={styles.button}>
              <ActivityIndicator
                size="small"
                color="#2980b9"
                style={{ marginRight: 10 }}
              />
              <Text style={styles.buttonText}>Connexion...</Text>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              style={styles.button}
              onPress={() => {
                handleLogin();
              }}
              onLongPress={() => {
                handleLogin(true);
              }}
            >
              <Text style={styles.buttonText}>Connexion CAS</Text>
            </TouchableOpacity>
          )}
        </View>
        <Text style={styles.copyright}>
          © 2022 UNIV'EIRB. Tous droits réservés.{"\n"}Application développée
          par Alexandre Boin
        </Text>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  headerLogo: {
    width: 216,
    height: 36,
    alignSelf: "center",
    margin: 6,
  },
  deliveryLogo: {
    width: 256,
    height: 112,
    marginVertical: 40,
  },
  button: {
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: "#23313F",
    marginBottom: 24,
    padding: 12,
    borderRadius: 8,
    width: "50%",
  },
  buttonText: {
    fontSize: 18,
    color: "white",
    alignSelf: "center",
  },
  title: {
    fontSize: 30,
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: 16,
    textAlign: "center",
    width: 310,
  },
  copyright: {
    fontSize: 12,
    textAlign: "center",
    marginVertical: 6,
  },
  userContainer: {
    marginTop: 24,
  },
  userText: {
    fontSize: 18,
  },
  image: {
    width: 256,
    height: 256,
    marginVertical: 30,
  },
});

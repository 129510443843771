import { createContext, Dispatch, SetStateAction } from "react";
import { useEffect, useState } from "react";
import { User } from "firebase/auth";
import { Address, ApiResponse, UserProfile, VerifiedAddress } from "../types";
import { auth, db } from "../firebase";
import { ref, onValue, set, off, get, push } from "firebase/database";
import * as SplashScreen from "expo-splash-screen";
import * as location from "expo-location";
import { Platform } from "react-native";
import { nativeConfirm } from "../utils";

export interface AuthData {
  authInitialized: boolean;
  user: User | null;
  userData: UserProfile | null;
}

export interface AuthContextProps extends AuthData {
  setAuthInitialized: Dispatch<SetStateAction<boolean>>;
  setUser: Dispatch<SetStateAction<User | null>>;
  setUserData: Dispatch<SetStateAction<UserProfile | null>>;
  postMessageOnPendingOrder: (
    userId: string,
    orderId: string,
    message: string,
    asAdmin: boolean
  ) => Promise<void>;
  setLastSeenMessage: (
    userId: string,
    orderId: string,
    asAdmin: boolean
  ) => Promise<void>;
  getAddressesFromLocation: (lat: number, lng: number) => Promise<any>;
  getAddressesSuggestions: (query: string) => Promise<any>;
  setUserAddress: (address: VerifiedAddress | null) => Promise<any>;
  constructVerifiedAddress: (address: Address, result: any) => VerifiedAddress;
  setUserTravelDetails: (
    address: string,
    lat: number,
    lng: number
  ) => Promise<any>;
  setUserPhoneNumber: (phoneNumber: string | null) => Promise<any>;
  deleteUserAccount: () => Promise<string>;
  setUserGPSLocation: () => Promise<any>;
}

export const AuthContext = createContext<AuthContextProps>(
  {} as AuthContextProps
);

export const AuthProvider: React.FC = ({ children }) => {
  const [authInitialized, setAuthInitialized] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [userData, setUserData] = useState<UserProfile | null>(null);

  useEffect(() => {
    let interval: NodeJS.Timer | null = null;
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      setUser(firebaseUser);
      // Bind realtime database ref of the user to the context
      if (firebaseUser) {
        const userRef = ref(db, `users/${firebaseUser.uid}`);
        onValue(userRef, (snapshot) => {
          if (!authInitialized) {
            setAuthInitialized(true);
            SplashScreen.hideAsync();
          }
          const userData: UserProfile = snapshot.val();
          if (userData) {
            setUserData(userData);
          }
        });
      } else {
        setUserData(null);
        if (!authInitialized) {
          setAuthInitialized(true);
          SplashScreen.hideAsync();
        }
      }
    });

    return unsubscribe;
  }, []);

  /* Function to post a message on an order */
  const postMessageOnPendingOrder = async (
    userId: string,
    orderId: string,
    message: string,
    asAdmin = false
  ): Promise<void> => {
    if (user && userData) {
      const orderMessagesRef = ref(
        db,
        `/users/${userId}/orders/${orderId}/messages`
      );
      // Remove excessing spaces and new lines
      const cleanMessage = message.replace(/\s+/g, " ").trim();
      if (cleanMessage.length > 280) {
        return Promise.reject("Le message est trop long !");
      } else {
        const messageObject = {
          from: asAdmin ? "admin" : "customer",
          message,
          datetime: new Date().toISOString(),
        };
        push(orderMessagesRef, messageObject).then((messageId) => {
          return Promise.resolve("Votre message a été envoyé !");
        });
      }
    } else {
      return Promise.reject(
        "Vous devez être connecté pour poster un message !"
      );
    }
  };

  /* Function to set time of the last seen message */
  const setLastSeenMessage = async (
    userId: string,
    orderId: string,
    asAdmin = false
  ) => {
    if (user && userData) {
      const orderMessagesSeenRef = ref(
        db,
        `/users/${userId}/orders/${orderId}/lastSeen${
          asAdmin ? "Admin" : "Customer"
        }`
      );
      return set(orderMessagesSeenRef, new Date().toISOString());
    } else {
      return Promise.reject(
        "Vous devez être connecté pour définir le dernier message lu !"
      );
    }
  };

  /* Function to get addresses from a location */
  const getAddressesFromLocation = async (
    lat: number,
    lng: number
  ): Promise<any> => {
    const idToken = user ? await user.getIdToken() : null;
    if (idToken) {
      return fetch(
        `https://deliveirb.aboin.fr/api/location?action=addressFromLocation&lat=${lat}&lng=${lng}&idToken=${idToken}`
      )
        .then((res) => res.json())
        .then((json: ApiResponse<any>) => {
          if (json.status === "ok") {
            return Promise.resolve(json.data);
          } else {
            return Promise.reject(json.message);
          }
        });
    } else {
      return Promise.reject(
        "Vous devez être connecté pour récupérer les adresses !"
      );
    }
  };

  /* Function to get addresses suggestions based on query */
  const getAddressesSuggestions = async (query: string): Promise<any> => {
    const idToken = user ? await user.getIdToken() : null;
    if (idToken) {
      return fetch(
        `https://deliveirb.aboin.fr/api/location?action=addressesSuggestions&query=${query}&idToken=${idToken}`
      )
        .then((res) => res.json())
        .then((json: ApiResponse<any>) => {
          if (json.status === "ok") {
            return Promise.resolve(json.data);
          } else {
            return Promise.reject(json.message);
          }
        });
    } else {
      return Promise.reject(
        "Vous devez être connecté pour obtenir des suggestions d'adresses !"
      );
    }
  };

  /* Function to set the address of the user */
  const setUserAddress = (address: VerifiedAddress | null): Promise<any> => {
    const uid = user ? user.uid : null;
    if (uid) {
      const userAddressRef = ref(db, `users/${uid}/address`);
      return set(userAddressRef, address).then(() => {
        if (address == null) {
          const userTravelDetailsRef = ref(db, `users/${uid}/travelDetails`);
          return set(userTravelDetailsRef, null);
        }
      });
    } else {
      return Promise.reject(
        "Vous devez être connecté pour définir votre adresse !"
      );
    }
  };

  /* Function to construction a verified address object */
  const constructVerifiedAddress = (
    address: Address,
    result: any
  ): VerifiedAddress => {
    return {
      userInput: {
        ...address,
        number:
          result.address_components.find((c: any) => {
            return c.types.includes("street_number");
          })?.long_name || address.number,
        street:
          result.address_components.find((c: any) => {
            return c.types.includes("route");
          })?.long_name || address.street,
        city:
          result.address_components.find((c: any) => {
            return c.types.includes("locality");
          })?.long_name || address.city,
        postal_code:
          result.address_components.find((c: any) => {
            return c.types.includes("postal_code");
          })?.long_name || address.postal_code,
      },
      formattedAddress: result.formatted_address,
      location: result.geometry.location,
    };
  };

  /* Function to set travel details of the user */
  const setUserTravelDetails = async (
    address: string,
    lat: number,
    lng: number
  ): Promise<any> => {
    const idToken = user ? await user.getIdToken() : null;
    if (idToken) {
      return fetch(
        `https://deliveirb.aboin.fr/api/location?action=setTravelDetails&address=${address}&lat=${lat}&lng=${lng}&idToken=${idToken}`
      )
        .then((res) => res.json())
        .then((json: ApiResponse<any>) => {
          if (json.status === "ok") {
            return Promise.resolve(json.data);
          } else {
            return Promise.reject(json.message);
          }
        });
    } else {
      return Promise.reject(
        "Vous devez être connecté pour définir vos détails de trajet !"
      );
    }
  };

  /* Function to edit the phone number of the user */
  const setUserPhoneNumber = async (
    phoneNumber: string | null
  ): Promise<any> => {
    const uid = user ? user.uid : null;
    if (uid) {
      const userPhoneNumberRef = ref(db, `users/${uid}/phoneNumber`);
      return set(userPhoneNumberRef, phoneNumber).then(() => {
        off(userPhoneNumberRef);
      });
    } else {
      return Promise.reject(
        "Vous devez être connecté pour définir votre numéro de téléphone !"
      );
    }
  };

  /* Function to delete user account */
  const deleteUserAccount = async (): Promise<string> => {
    const idToken = user ? await user.getIdToken() : null;
    if (idToken) {
      return fetch(
        `https://deliveirb.aboin.fr/api/location?action=deleteAccount&idToken=${idToken}`
      )
        .then((res) => res.json())
        .then((json: ApiResponse<any>) => {
          if (json.status === "ok") {
            return Promise.resolve(json.message);
          } else {
            return Promise.reject(json.message);
          }
        });
    } else {
      return Promise.reject(
        "Vous devez être connecté pour supprimer votre compte !"
      );
    }
  };

  /* Fun to set the user GPS location */
  const setUserGPSLocation = async (): Promise<string> => {
    if (user && userData?.admin) {
      return location
        .requestForegroundPermissionsAsync()
        .then(({ status }) => {
          if (status !== "granted") {
            return Promise.reject(
              "Vous devez autoriser l'accès à votre position"
            );
          }
          return location.getCurrentPositionAsync({}).then(({ coords }) => {
            const userGPSLocationRef = ref(db, `config/listeux/${user.uid}/location`);
            return set(userGPSLocationRef, {
              latitude: coords.latitude,
              longitude: coords.longitude,
              lastUpdate: new Date().toISOString(),
            })
              .then(() => {
                off(userGPSLocationRef);
                return Promise.resolve("Votre position a été mise à jour !");
              })
              .catch(() => {
                return Promise.reject(
                  "Impossible de mettre à jour votre position !"
                );
              });
          });
        })
        .catch(() => {
          return Promise.reject(
            "Vous devez autoriser l'accès à votre position"
          );
        });
    } else {
      return Promise.reject(
        "Vous devez être connecté pour définir votre position GPS !"
      );
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        userData,
        setUserData,
        authInitialized,
        setAuthInitialized,
        postMessageOnPendingOrder,
        setLastSeenMessage,
        getAddressesFromLocation,
        getAddressesSuggestions,
        setUserAddress,
        constructVerifiedAddress,
        setUserTravelDetails,
        setUserPhoneNumber,
        deleteUserAccount,
        setUserGPSLocation,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

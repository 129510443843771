import {
  Platform,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  SafeAreaView,
  Pressable,
  Linking,
  TextInput,
  KeyboardAvoidingView,
} from "react-native";

import { MaterialIcons } from "@expo/vector-icons";

import { Text, View } from "../components/Themed";

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList, Order, OrderData } from "../types";

import React, { useContext, useEffect, useRef, useState } from "react";
import useColorScheme from "../hooks/useColorScheme";
import Colors from "../constants/Colors";
import { formatDate, nativeConfirm } from "../utils";
import { AuthContext } from "../context/AuthContext";
import { useToast } from "react-native-toast-notifications";
import { OrdersContext } from "../context/OrdersContext";
import { HeadquartersContext } from "../context/HeadquartersContext";

export default function OrderMessagesScreen({
  navigation,
  route,
}: NativeStackScreenProps<RootStackParamList, "OrderMessages">) {
  const colorScheme = useColorScheme();
  const toast = useToast();
  const { headquarters } = useContext(HeadquartersContext);
  const { userData, postMessageOnPendingOrder, setLastSeenMessage } =
    useContext(AuthContext);
  const allOrders = userData?.admin
    ? useContext(OrdersContext).orders
    : undefined;

  const messageScrollView = useRef<ScrollView>(null);
  const inputRef = useRef<TextInput>(null);

  const [currentMessage, setCurrentMessage] = useState<string>("");

  const [orderData, setOrderData] = useState<OrderData>();

  const [lastSeenUpdatedOnQuit, setLastSeenUpdatedOnQuit] =
    useState<boolean>(false);
  const [lastSeenUpdatedOnEnter, setLastSeenUpdatedOnEnter] =
    useState<boolean>(false);
  const [lastSeenUpdatedOnNewMessage, setLastSeenUpdatedOnNewMessage] =
    useState<boolean>(false);

  const [orderId, setOrderId] = useState<string>(route.params.id);

  // State to hold the order
  const [order, setOrder] = useState<Order | undefined>(undefined);

  const sendMessage = () => {
    if (currentMessage && userData) {
      postMessageOnPendingOrder(
        orderData ? orderData.user.username : userData.username,
        orderId,
        currentMessage,
        orderData !== undefined
      )
        .then((message) => {
          toast.show("Message envoyé", {
            type: "success",
          });
          setCurrentMessage("");
        })
        .catch((error) => {
          toast.show("Erreur lors de l'envoi du message", {
            type: "danger",
          });
        });
    }
  };

  // On load
  useEffect(() => {
    // Get the order
    const username = route.params.username;
    const orderData =
      userData?.admin && username
        ? allOrders?.find(
            (o) => o.user.username == username && o.orderId == orderId
          )
        : undefined;
    if (orderData) {
      setOrderData(orderData);
    }
    const order = orderData?.data
      ? orderData.data
      : userData?.orders
      ? userData.orders[orderId]
      : undefined;

    if (order) {
      setOrder(order);

      navigation.setOptions({
        headerTitle: route.params.username ? orderData?.user.displayName : "Restaurateur (" + headquarters[order.headquarterId].name + ")",
        headerLeft: () =>
          Platform.OS === "web" ? (
            <Pressable
              onPress={() => {
                navigation.goBack();
              }}
              style={({ pressed }) => ({
                opacity: pressed ? 0.5 : 1,
                width: 48,
                height: 48,
                alignItems: "center",
                justifyContent: "center",
                marginRight: 4,
              })}
            >
              <MaterialIcons
                name="arrow-back"
                size={25}
                color={Colors[colorScheme].text}
                style={{ marginLeft: 15 }}
              />
            </Pressable>
          ) : undefined,
        headerRight: () =>
          orderData ? (
            <Pressable
              onPress={() => {
                nativeConfirm(
                  "Appeler le client",
                  "Voulez-vous appeler le client en numéro masqué ?",
                  () => {
                    if (Platform.OS === "web") {
                      window.open(
                        `tel:%2331%23${orderData.user.phoneNumber}`,
                        "_system"
                      );
                    } else {
                      Linking.openURL(
                        `tel:%2331%23${orderData.user.phoneNumber}`
                      );
                    }
                  },
                  "Oui"
                );
              }}
              style={({ pressed }) => ({
                opacity: pressed ? 0.5 : 1,
                width: 48,
                height: 48,
                alignItems: "center",
                justifyContent: "center",
                marginRight: 4,
              })}
            >
              <MaterialIcons
                name="phone"
                size={25}
                color={Colors[colorScheme].text}
              />
            </Pressable>
          ) : undefined,
      });
    }
  }, [userData, allOrders]);

  useEffect(() => {
    const username = route.params.username;
    if (userData && !lastSeenUpdatedOnEnter) {
      setLastSeenUpdatedOnEnter(true);
      if (username) {
        setLastSeenMessage(
          username ? username : userData.username,
          orderId,
          username !== undefined
        );
      }
    }

    return () => {
      if (userData && !lastSeenUpdatedOnQuit) {
        setLastSeenUpdatedOnQuit(true);
        setLastSeenMessage(
          username ? username : userData.username,
          orderId,
          username !== undefined
        );
      }
    };
  }, []);

  return (
    <SafeAreaView style={styles.safeAreaView}>
      <View style={styles.container}>
        {order && headquarters[order.headquarterId] && userData && (
          <KeyboardAvoidingView
            style={{
              flex: 1,
            }}
            behavior={Platform.OS === "ios" ? "padding" : undefined}
            keyboardVerticalOffset={Platform.OS === "ios" ? 60 : undefined}
          >
            <ScrollView
              style={{
                flex: 1,
              }}
              contentContainerStyle={{
                flexGrow: 1,
                paddingBottom: 10,
              }}
              ref={messageScrollView}
              onContentSizeChange={() => {
                setTimeout(() => {
                  messageScrollView.current?.scrollToEnd({ animated: true });
                }, 300);
              }}
            >
              {Object.entries(order?.messages || {}).map(
                ([messageId, message]) => {
                  const isUserMessage =
                    (message.from == "admin" && route.params.username) ||
                    (message.from == "customer" && !route.params.username);

                  return (
                    <View
                      key={messageId}
                      style={[
                        styles.message,
                        {
                          borderBottomEndRadius: isUserMessage ? 0 : 24,
                          borderBottomStartRadius: isUserMessage ? 24 : 0,
                          borderBottomColor: Colors[colorScheme].card,
                          marginLeft: isUserMessage ? 60 : 20,
                          marginRight: isUserMessage ? 20 : 60,
                          backgroundColor: isUserMessage
                            ? Colors[colorScheme].bubble
                            : Colors[colorScheme].bubbleAlt,
                        },
                      ]}
                    >
                      <Text style={{ fontSize: 16 }}>{message.message}</Text>
                      <Text
                        style={{
                          fontSize: 12,
                          color: Colors[colorScheme].textLight,
                          marginTop: 4,
                        }}
                      >
                        {formatDate(message.datetime)}
                        {isUserMessage &&
                        ((!route.params.username &&
                          message.datetime <
                            (order.lastSeenAdmin || "1970-01-01")) ||
                          (route.params.username &&
                            message.datetime <
                              (order.lastSeenCustomer || "1970-01-01")))
                          ? " (vu)"
                          : ""}
                      </Text>
                    </View>
                  );
                }
              )}
              {order.messages === undefined && (
                <View
                  style={{
                    padding: 10,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 16,
                      textAlign: "center",
                      fontStyle: "italic",
                      color: "grey",
                    }}
                  >
                    Aucun message pour le moment
                  </Text>
                </View>
              )}
            </ScrollView>
            <View
              style={[
                styles.messageField,
                { backgroundColor: Colors[colorScheme].card },
              ]}
            >
              <TextInput
                style={[
                  styles.messageInput,
                  { color: Colors[colorScheme].text },
                ]}
                onChangeText={(text) => {
                  setCurrentMessage(text);
                }}
                value={currentMessage}
                placeholder="Votre message"
                placeholderTextColor={"grey"}
                autoFocus={Platform.OS !== "ios"}
                maxLength={280}
                ref={inputRef}
                onPressIn={() => {
                  messageScrollView.current?.scrollToEnd({ animated: true });
                }}
                onSubmitEditing={() => {
                  sendMessage();
                  setTimeout(() => {
                    inputRef.current?.focus();
                  }, 100);
                }}
                blurOnSubmit={false}
              />
              <TouchableOpacity
                style={styles.messageSendButton}
                onPress={() => {
                  sendMessage();
                }}
              >
                <MaterialIcons
                  name="send"
                  size={25}
                  color={Colors[colorScheme].text}
                />
              </TouchableOpacity>
            </View>
          </KeyboardAvoidingView>
        )}
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
  },
  container: {
    flex: 1,
  },
  contentContainer: {},
  messageField: {
    marginBottom: 10,
    marginHorizontal: 10,
    borderRadius: 12,
    backgroundColor: "#f5f5f5",
    flexDirection: "row",
    borderWidth: 2,
    borderColor: "#e5e5e5",
  },
  messageInput: {
    padding: 10,
    marginLeft: 10,
    fontSize: 18,
    flex: 1,
  },
  messageSendButton: {
    padding: 10,
  },

  message: {
    marginTop: 10,
    marginHorizontal: 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderBottomWidth: 1,
    borderRadius: 24,
    backgroundColor: "#b8eaff",
  },
});

import { StyleSheet, ScrollView, TouchableOpacity, Pressable, Platform } from "react-native";

import { Text, View } from "../components/Themed";

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList, Address } from "../types";

import React, { useState, useContext, useEffect } from "react";

import { AuthContext } from "../context/AuthContext";
import { useToast } from "react-native-toast-notifications";
import useColorScheme from "../hooks/useColorScheme";
import Colors from "../constants/Colors";
import { nativeDialog } from "../utils";
import { MaterialIcons } from "@expo/vector-icons";

export default function AddressSearchScreen({
  navigation,
  route,
}: NativeStackScreenProps<RootStackParamList, "AddressSearch">) {
  const colorScheme = useColorScheme();
  const toast = useToast();

  // State to hold user-typed address
  const [address] = useState<Address>(route.params.address);

  // State to hold search results
  const [results] = useState<any[]>(route.params.results);

  const {
    user,
    constructVerifiedAddress,
    setUserAddress,
    setUserTravelDetails,
  } = useContext(AuthContext);

  // On load
  useEffect(() => {
    navigation.setOptions({
      headerLeft: () =>
        Platform.OS === "web" ? (
          <Pressable
            onPress={() => {
              navigation.goBack();
            }}
            style={({ pressed }) => ({
              opacity: pressed ? 0.5 : 1,
              width: 48,
              height: 48,
              alignItems: "center",
              justifyContent: "center",
              marginRight: 4,
            })}
          >
            <MaterialIcons
              name="arrow-back"
              size={25}
              color={Colors[colorScheme].text}
              style={{ marginLeft: 15 }}
            />
          </Pressable>
        ) : undefined,
    });
  }, []);

  return (
    <View style={styles.container}>
      {/* List search results */}
      <ScrollView
        style={styles.searchResults}
        keyboardShouldPersistTaps="always"
      >
        {results.map((result, index) => (
          <TouchableOpacity
            key={index}
            style={[
              styles.searchResult,
              { borderBottomColor: Colors[colorScheme].card },
            ]}
            onPress={() => {
              if (user) {
                // Construct the verified address object
                const verifiedAddress = constructVerifiedAddress(
                  address,
                  result
                );

                // Save the address
                setUserAddress(verifiedAddress).then(() => {
                  toast.show("Adresse enregistrée avec succès !", {
                    type: "success",
                  });
                  navigation.goBack();
                  navigation.goBack();
                  if (route.params.comingFromLocationSelector) {
                    navigation.goBack();
                  }
                });
                setUserTravelDetails(verifiedAddress.formattedAddress, verifiedAddress.location.lat, verifiedAddress.location.lng);
              }
            }}
          >
            <Text style={styles.searchResultText}>
              {result.formatted_address}
            </Text>
          </TouchableOpacity>
        ))}
        <TouchableOpacity
          style={[styles.searchResult, { borderBottomWidth: 0 }]}
          onPress={() => {
            nativeDialog(
              "Conseils de recherche",
              "Si aucune des adresses proposées ne correspond, veuillez vérifier que le numéro, le nom de la voie, le code postal et la ville ont bien étés saisis correctement.",
              () => {
                navigation.goBack();
              },
              "OK, je vais essayer à nouveau"
            );
          }}
        >
          <Text style={[styles.searchResultText, { color: "#e74c3c" }]}>
            Aucun de ces résultats ne correspond
          </Text>
        </TouchableOpacity>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
    padding: 10,
  },
  searchResults: {
    width: "100%",
    height: "50%",
    paddingTop: 10,
  },
  searchResult: {
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 1,
    borderRadius: 10,
    padding: 10,
  },
  searchResultText: {
    fontSize: 14,
    margin: 10,
  },
});

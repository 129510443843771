import {
  Platform,
  StyleSheet,
  SafeAreaView,
  Pressable,
  Image,
} from "react-native";

import { MaterialIcons } from "@expo/vector-icons";

import { Text, View } from "../components/Themed";

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList, Order, OrderData } from "../types";

import React, { useContext, useEffect, useState } from "react";
import useColorScheme from "../hooks/useColorScheme";
import Colors from "../constants/Colors";
import { formatDate, ORDER_STATES } from "../utils";
import { AuthContext } from "../context/AuthContext";
import { OrdersContext } from "../context/OrdersContext";
import { HeadquartersContext } from "../context/HeadquartersContext";
import { getDirections } from "../locationUtils";
import MapView, { Marker, Polyline } from "react-native-maps";

export default function OrderTrackingScreen({
  navigation,
  route,
}: NativeStackScreenProps<RootStackParamList, "OrderTracking">) {
  const colorScheme = useColorScheme();
  const { items } = useContext(HeadquartersContext);
  const { userData } = useContext(AuthContext);
  const allOrders = userData?.admin
    ? useContext(OrdersContext).orders
    : undefined;

  const orderId = route.params.id;

  // State to hold the order
  const [order, setOrder] = useState<Order | undefined>(undefined);

  const [locations, setLocations] = useState<{ lat: number; lng: number }[]>(
    []
  );

  const [timeMin, setTimeMin] = useState("--:--");
  const [timeMax, setTimeMax] = useState("--:--");

  // On load
  useEffect(() => {
    if (order) {
      getDirections(
        "1 Avenue du Dr Albert Schweitzer, 33400, Talence",
        order.address?.formattedAddress || ""
      ).then((data) => {
        setLocations(data.points);
        let min = data.time / 60;
        let max = (data.time / 60) * 1.5;
        if (order.status === "pending") {
          min += 3;
          max += 4;
        }
        if (order.status === "pending" || order.status === "accepted") {
          const preparationTime = Object.entries(order?.items || {})
            .sort((a, b) => {
              return items[a[0]] && items[b[0]]
                ? items[a[0]].preparationTime - items[b[0]].preparationTime
                : 0;
            })
            .map(([itemId, quantity]) => {
              return items[itemId]
                ? quantity * items[itemId].preparationTime
                : 0;
            })
            .reduce((a, b) => {
              return a + b;
            }, 0);
          min += preparationTime;
          max += preparationTime;
        }
        const date = new Date();
        const timeMin = new Date(date);
        timeMin.setMinutes(date.getMinutes() + min);
        setTimeMin(formatDate(timeMin).split(" ")[1]);
        const timeMax = new Date(date);
        timeMax.setMinutes(date.getMinutes() + max);
        setTimeMax(formatDate(timeMax).split(" ")[1]);
      });
    }
  }, [order]);

  // On load
  useEffect(() => {
    // Get the order
    const username = route.params.username;
    const orderData =
      userData?.admin && username
        ? allOrders?.find(
            (o) => o.user.username == username && o.orderId == orderId
          )
        : undefined;

    const order = orderData?.data
      ? orderData.data
      : userData?.orders
      ? userData.orders[orderId]
      : undefined;

    if (order) {
      setOrder(order);

      navigation.setOptions({
        headerLeft: () =>
          Platform.OS === "web" ? (
            <Pressable
              onPress={() => {
                navigation.goBack();
              }}
              style={({ pressed }) => ({
                opacity: pressed ? 0.5 : 1,
                width: 48,
                height: 48,
                alignItems: "center",
                justifyContent: "center",
                marginRight: 4,
              })}
            >
              <MaterialIcons
                name="arrow-back"
                size={25}
                color={Colors[colorScheme].text}
                style={{ marginLeft: 15 }}
              />
            </Pressable>
          ) : undefined,
        headerRight: () => (
          <Pressable
            onPress={() => {
              navigation.navigate("OrderMessages", {
                id: orderId,
                username: username,
              });
            }}
            style={({ pressed }) => ({
              opacity: pressed ? 0.5 : 1,
              width: 48,
              height: 48,
              alignItems: "center",
              justifyContent: "center",
              marginRight: 4,
            })}
          >
            <MaterialIcons
              name={
                Object.entries(order.messages || []).reverse()[0]?.[1]
                  .datetime >=
                (orderData
                  ? order.lastSeenAdmin || ""
                  : order.lastSeenCustomer || "")
                  ? "mark-chat-unread"
                  : "chat-bubble"
              }
              size={25}
              color={Colors[colorScheme].text}
            />
          </Pressable>
        ),
      });
    }
  }, [userData, allOrders]);

  return (
    <SafeAreaView style={styles.safeAreaView}>
      <View style={styles.container}>
        {/* Absolute header */}
        <View style={styles.header}>
          {/* Estimated arrival time */}
          <View style={styles.estimatedArrivalTime}>
            <View style={styles.estimatedArrivalTimeLine}>
              <Text style={styles.estimatedArrivalTimeTitle}>
                Arrivée estimée
              </Text>
              <Text style={styles.estimatedArrivalTimeText}>
                {timeMin} - {timeMax}
              </Text>
            </View>
            <Image
              style={styles.estimatedArrivalTimeIcon}
              source={
                ORDER_STATES[
                  order && order.status in ORDER_STATES
                    ? order.status
                    : "unknown"
                ].image
              }
            />
          </View>
          <View style={styles.progressBarBackground}>
            <View
              style={[
                styles.progressBar,
                {
                  backgroundColor:
                    ORDER_STATES[
                      order && order.status in ORDER_STATES
                        ? order.status
                        : "unknown"
                    ].imageColor,
                  width:
                    ORDER_STATES[
                      order && order.status in ORDER_STATES
                        ? order.status
                        : "unknown"
                    ].progress + "%",
                },
              ]}
            />
          </View>
          <Text style={styles.description}>
            {
              ORDER_STATES[
                order && order.status in ORDER_STATES ? order.status : "unknown"
              ].description
            }
          </Text>
        </View>

        {(Platform.OS === "android" || Platform.OS === "ios") &&
          locations.length > 1 && (
            <MapView
              style={styles.map}
              region={{
                latitude:
                  (locations[0].lat +
                    locations[locations.length - 1].lat +
                    0.015) /
                  2,
                longitude:
                  (locations[0].lng + locations[locations.length - 1].lng) / 2,
                latitudeDelta:
                  Math.abs(
                    locations[0].lat -
                      locations[locations.length - 1].lat -
                      0.015
                  ) * 1.5,
                longitudeDelta:
                  Math.abs(
                    locations[0].lng - locations[locations.length - 1].lng
                  ) * 1.5,
              }}
            >
              <Marker
                coordinate={{
                  latitude: locations[0].lat,
                  longitude: locations[0].lng,
                }}
                image={require("../assets/images/icons/delivery.png")}
              />
              <Marker
                coordinate={{
                  latitude: locations[locations.length - 1].lat,
                  longitude: locations[locations.length - 1].lng,
                }}
                image={require("../assets/images/icons/home.png")}
              />
              <Polyline
                coordinates={locations.map((l) => ({
                  latitude: l.lat,
                  longitude: l.lng,
                }))}
                strokeWidth={5}
                strokeColor="#8e44ad"
              />
            </MapView>
          )}
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
  },
  header: {
    position: "absolute",
    top: 16,
    left: 16,
    right: 16,
    zIndex: 1,
    padding: 15,
    backgroundColor: "white",
    borderBottomColor: "#e6e6e6",
    borderBottomWidth: 1,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  estimatedArrivalTime: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "transparent",
  },
  estimatedArrivalTimeLine: {
    backgroundColor: "transparent",
  },
  estimatedArrivalTimeIcon: {
    width: 64,
    height: 64,
    marginLeft: 10,
  },
  estimatedArrivalTimeTitle: {
    fontSize: 16,
    color: "black",
  },
  estimatedArrivalTimeText: {
    fontWeight: "bold",
    fontSize: 24,
    color: "black",
  },

  map: {
    width: "100%",
    height: "100%",
  },

  progressBarBackground: {
    marginVertical: 6,
    backgroundColor: "#ecf0f1",
    width: "100%",
    height: 6,
  },
  progressBar: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    width: "75%",
    backgroundColor: "#f39c12",
  },

  description: {
    fontSize: 14,
    color: "gray",
  },
});

import React, { useContext, useEffect, useState } from "react";

import {
  StyleSheet,
  TextInput,
  TouchableOpacity,
  ScrollView,
  SafeAreaView,
} from "react-native";

import Logo from "../assets/images/logo";

import { MaterialIcons } from "@expo/vector-icons";

import { View } from "../components/Themed";
import { RootTabScreenProps, Category, Restaurant } from "../types";
import useColorScheme from "../hooks/useColorScheme";
import Colors from "../constants/Colors";
import CategoryCard from "../components/CategoryCard";
import { formatTime, nativeDialog } from "../utils";
import { HeadquartersContext } from "../context/HeadquartersContext";
import HeadquarterCard from "../components/HeadquarterCard";
import { AuthContext } from "../context/AuthContext";

export default function HomeScreen({ navigation }: RootTabScreenProps<"Home">) {
  const colorScheme = useColorScheme();
  const [search, setSearch] = useState("");
  const { headquarters, items } = useContext(HeadquartersContext);
  const travelDetails = useContext(AuthContext).userData?.travelDetails;

  const [categories, setCategories] = useState<{
    [key: string]: {
      name: string;
      shortname?: string;
      description: string;
      image: string;
    };
  }>({});

  useEffect(() => {
    if (Object.keys(items).length > 0) {
      const categories = {
        ...items,
        oscar: {
          name: "Oscar",
          description: `[BRASSE] [OSCAR]

Un soir 🌛, alors que nous revenions d'une promenade en forêt 🌳🌲, nous avons vu des lumières très particulières qui se déplaçaient dans le ciel 🌌
Nous avons d'abord pensé à des aliens 👽 mais une de ces lumières revint vers nous, c'était une cigogne 🦢 nous apportant un cadeau inattendu 🎁 : OSCAR 👶
Son sourire 😁 (et surtout son boule 🙃) nous a tous conquis, nous lui avons fait goûter nos cookies 🍪, danser notre chorée 💃🏾🕺, utiliser nos magnifiques éco-cup 🥤, et malgré une marche qui fût bien plus lente 🐌, nous sommes rentrés dans notre vaisseau 🚀 tout sourire avec notre nouveau camarade 👶
Puis... plus rien. J'ai encore Tout Oublié le Matin mais Oscar était toujours parmi nous et son aisance nous permettait de comprendre qu'il avait bien cohésioné avec nous 🥳
  
Com du pôle tom
  
PS : Oscar à jamais dans notre cœur ❤️
PPS : Si vous aviez vu son Chagas 😍
PPPS : au fait, pourquoi Oscar ? 🤫`,
          image: "https://deliveirb.aboin.fr/assets/images/items/oscar.png",
        },
      };

      setCategories(categories);
    }
  }, [items]);

  return (
    <SafeAreaView
      style={[
        styles.container,
        { backgroundColor: Colors[colorScheme].container },
      ]}
    >
      <View style={styles.header}>
        <Logo style={styles.headerLogo} />
        <View
          style={[
            styles.searchInput,
            { backgroundColor: Colors[colorScheme].input },
          ]}
        >
          <MaterialIcons
            size={20}
            style={{ padding: 6 }}
            name="search"
            color="#888"
          />
          <TextInput
            style={[
              styles.searchInputText,
              { color: Colors[colorScheme].text },
            ]}
            placeholder="Rechercher un restaurant"
            placeholderTextColor="#666"
            onChangeText={(text) => {
              setSearch(text);
            }}
            value={search}
          />
          {search.length > 0 && (
            <TouchableOpacity
              onPress={() => {
                setSearch("");
              }}
            >
              <MaterialIcons
                size={20}
                style={{ padding: 6 }}
                name="close"
                color="#888"
              />
            </TouchableOpacity>
          )}
        </View>
      </View>
      <ScrollView
        style={[
          styles.scrollView,
          { backgroundColor: Colors[colorScheme].background },
        ]}
      >
        <View
          style={[
            styles.separator,
            { backgroundColor: Colors[colorScheme].separator },
          ]}
        />
        <ScrollView horizontal showsHorizontalScrollIndicator={false}>
          {Object.entries(categories).map(([categoryId, category]) => (
            <CategoryCard
              key={categoryId}
              name={category.shortname ? category.shortname : category.name}
              image={category.image}
              clickCallback={() => {
                nativeDialog(category.name, category.description || "");
              }}
            />
          ))}
        </ScrollView>
        <View
          style={[
            styles.separator,
            { backgroundColor: Colors[colorScheme].separator },
          ]}
        />
        {Object.entries(headquarters)
          .filter(
            ([headquarterId, headquarter]) =>
              headquarter.name.toLowerCase().includes(search.toLowerCase()) ||
              headquarter.position.toLowerCase().includes(search.toLowerCase())
          )
          .sort(
            (
              [headquarterIdA, headquarterA],
              [headquarterIdB, headquarterB]
            ) => {
              if (travelDetails) {
                if (
                  travelDetails[headquarterIdA].covered &&
                  !travelDetails[headquarterIdB].covered
                ) {
                  return -1;
                }
                if (
                  !travelDetails[headquarterIdA].covered &&
                  travelDetails[headquarterIdB].covered
                ) {
                  return 1;
                }
                if (
                  travelDetails[headquarterIdA].distance <
                  travelDetails[headquarterIdB].distance
                ) {
                  return -1;
                }
                if (
                  travelDetails[headquarterIdA].distance >
                  travelDetails[headquarterIdB].distance
                )
                  return 1;
                return 0;
              } else {
                if (headquarterIdA < headquarterIdB) return -1;
                if (headquarterIdA > headquarterIdB) return 1;
                return 0;
              }
            }
          )
          .map(([headquarterId, headquarter]) => (
            <HeadquarterCard
              key={headquarterId}
              headquarter={headquarter}
              distance={
                travelDetails
                  ? (travelDetails[headquarterId].distance / 1000).toFixed(1)
                  : undefined
              }
              minDeliveryTime={
                travelDetails
                  ? formatTime(travelDetails[headquarterId].duration)
                  : undefined
              }
              maxDeliveryTime={
                travelDetails
                  ? formatTime(travelDetails[headquarterId].duration * 1.5)
                  : undefined
              }
              covered={
                travelDetails ? travelDetails[headquarterId].covered : true
              }
              clickCallback={() => {
                navigation.navigate("Headquarter", {
                  id: parseInt(headquarterId),
                });
              }}
            />
          ))}
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    paddingHorizontal: 16,
    paddingTop: 10,
  },
  headerLogo: {
    width: 216,
    height: 36,
    alignSelf: "center",
    margin: 6,
  },
  searchInput: {
    height: 50,
    borderRadius: 25,
    marginVertical: 6,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 16,
  },
  searchInputText: {
    flex: 1,
    fontSize: 16,
    paddingVertical: 8,
    paddingLeft: 8,
  },
  scrollView: {
    paddingHorizontal: 16,
    flex: 1,
  },
  separator: {
    marginVertical: 10,
    height: 1,
    width: "80%",
    alignSelf: "center",
  },
});

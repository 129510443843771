import { createContext, useContext, useEffect, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  ref,
  onValue,
  off,
  DatabaseReference,
  push,
  get,
  set,
} from "firebase/database";

import { db } from "../firebase";
import {
  HeadquarterList,
  ItemList,
  Cart,
  Order,
  ApiResponse,
  AdminConfig,
} from "../types";
import { AuthContext } from "./AuthContext";

export interface HeadquartersContextProps {
  headquarters: HeadquarterList;
  items: ItemList;
  adminConfig: AdminConfig;
  shoppingCart: Cart | null;
  shoppingCartLoaded: boolean;
  itemCount: number;
  totalPrice: number;
  preparationTime: number;
  addItemToShoppingCart: (
    headquarterId: number,
    itemId: string,
    quantity?: number
  ) => Promise<string>;
  removeItemFromShoppingCart: (
    itemId: string,
    quantity?: number
  ) => Promise<string>;
  setShoppingCartComment: (comment: string) => Promise<void>;
  convertShoppingCartToOrder: (partialShoppingCart: any) => Promise<string>;
  deleteShoppingCart: () => Promise<string>;
  cancelPendingOrder: (userId: string, orderId: string) => Promise<string>;
  assignListeux: (
    userId: string,
    orderId: string,
    listeuxIds: string[],
    estimatedPreparationTime: number
  ) => Promise<string>;
  updateDeliveryStatus: (
    userId: string,
    orderId: string,
    location: {
      latitude: number;
      longitude: number;
    }
  ) => Promise<string>;
  markOrderAsDelivered: (userId: string, orderId: string) => Promise<string>;
  setSpecialRequestPrice: (
    userId: string,
    orderId: string,
    price: number
  ) => Promise<string>;
  setHeadquarterOpeningStatus: (
    headquarterId: string,
    status: boolean
  ) => Promise<string>;
  setItemQuantity: (
    headquarterId: string,
    itemId: string,
    quantity: number
  ) => Promise<string>;
  setItemAvailability: (
    headquarterId: string,
    itemId: string,
    availability: boolean
  ) => Promise<string>;
  createOrderByPhone: (
    order: Order,
  ) => Promise<string>;
}

export const HeadquartersContext = createContext<HeadquartersContextProps>(
  {} as HeadquartersContextProps
);

export const HeadquartersProvider: React.FC = ({ children }) => {
  const [headquartersLoaded, setHeadquartersLoaded] = useState(false);
  const [headquarters, setHeadquarters] = useState<HeadquarterList>({});
  const [itemsLoaded, setItemsLoaded] = useState(false);
  const [items, setItems] = useState<ItemList>({});
  const [adminConfigLoaded, setAdminConfigLoaded] = useState(false);
  const [adminConfig, setAdmingConfig] = useState<AdminConfig>({
    birthdays: {},
    listeux: {},
    specialDetails: "",
  });

  const [shoppingCartLoaded, setShoppingCartLoaded] = useState(false);
  const [shoppingCart, setShoppingCart] = useState<Cart | null>(null);
  const [itemCount, setItemCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [preparationTime, setPreparationTime] = useState(0);

  const { user, userData } = useContext(AuthContext);

  // Function to add an item to the shopping cart and create it if it doesn't exist
  const addItemToShoppingCart = (
    headquarterId: number,
    itemId: string,
    quantity = 1
  ): Promise<string> => {
    // Check if the headquarter exists
    if (!headquarters[headquarterId]) {
      return Promise.reject("QG non reconnu");
    }

    // Check if the item exists
    if (!items[itemId]) {
      return Promise.reject("Produit non reconnu");
    }

    // Check if the item is available in the headquarter
    if (items[itemId] && !items[itemId].availability[headquarterId]) {
      return Promise.reject(
        "Ce produit n'est pas disponible à la vente dans ce QG !"
      );
    }

    // Check if the item is available in enough quantity in the headquarter
    if (items[itemId] && items[itemId].quantity[headquarterId] < quantity) {
      return Promise.reject(
        "Ce QG n'a pas assez de ce produit ! Veuillez en commander moins ou réessayer plus tard."
      );
    }

    // Add the item to the shopping cart
    if (!shoppingCart || shoppingCart.headquarterId !== headquarterId) {
      const items = { [itemId]: quantity };
      setShoppingCart({
        headquarterId,
        items,
      });
    } else {
      const items = { ...shoppingCart.items };
      if (items[itemId]) {
        items[itemId] += quantity;
      } else {
        items[itemId] = quantity;
      }
      setShoppingCart({
        headquarterId,
        items,
      });
    }

    if (quantity == 1) {
      return Promise.resolve(
        `"${items[itemId].name}" a bien été ajouté au panier !`
      );
    } else {
      return Promise.resolve(
        `"${items[itemId].name}" a bien été ajouté ${quantity} fois au panier !`
      );
    }
  };

  // Function to remove an item from the shopping cart
  const removeItemFromShoppingCart = (
    itemId: string,
    quantity = 1
  ): Promise<string> => {
    // Check if the item exists in the shopping cart
    if (!shoppingCart || !shoppingCart.items[itemId]) {
      return Promise.reject("Ce produit n'est pas dans le panier !");
    }

    // Remove the item from the shopping cart
    const cartItems = { ...shoppingCart.items };
    if (cartItems[itemId] > quantity) {
      cartItems[itemId] -= quantity;
    } else {
      delete cartItems[itemId];
    }
    setShoppingCart({
      headquarterId: shoppingCart.headquarterId,
      items: cartItems,
    });

    if (quantity == 1) {
      return Promise.resolve(
        `"${items[itemId].name}" a bien été retiré du panier !`
      );
    } else {
      return Promise.resolve(
        `"${items[itemId].name}" a bien été retiré ${quantity} fois du panier !`
      );
    }
  };

  // Function to delete the shopping cart
  const deleteShoppingCart = async (): Promise<string> => {
    setShoppingCart(null);
    return Promise.resolve("Le panier a bien été vidé !");
  };

  // Function to set the comment of the shopping cart
  const setShoppingCartComment = (comment: string): Promise<void> => {
    if (!shoppingCart) {
      return Promise.reject("Le panier est vide !");
    }
    setShoppingCart({
      ...shoppingCart,
      comment,
    });
    return Promise.resolve();
  };

  // Function to convert the shopping cart to an order
  const convertShoppingCartToOrder = (
    partialShoppingCart: any
  ): Promise<string> => {
    if (!shoppingCart) {
      return Promise.reject("Le panier est vide !");
    }

    const cart: Cart = {
      ...shoppingCart,
      ...partialShoppingCart,
    };

    // Check if the user is logged in
    if (!user || !userData) {
      return Promise.reject(
        "Vous devez être connecté pour passer une commande !"
      );
    }

    // Check if the user has no pending order
    if (
      Object.entries(userData.orders || {}).find(
        (o) =>
          o[1].status === "pending" ||
          o[1].status === "accepted" ||
          o[1].status === "ongoing"
      )
    ) {
      return Promise.reject(
        "Vous avez déjà une commande en cours, veuillez attendre que celle-ci soit livrée avant de passer une nouvelle commande !"
      );
    }

    // Check if the headquarter is open
    if (!headquarters[cart.headquarterId].opened) {
      return Promise.reject(
        "Ce QG est fermé, impossible de valider la commande ! Veuillez revenir au horaires d'ouverture."
      );
    }

    // Check if the user has an address set if the order is to be delivered
    if (
      Object.entries(cart.items).some((item) => items[item[0]].needDelivery)
    ) {
      // Check if the address is covered by the headquarter
      if (!userData.address) {
        return Promise.reject(
          "Vous devez avoir défini une adresse pour passer une commande nécessitant une livraison !"
        );
      } else if (!userData.travelDetails) {
        return Promise.reject(
          "Impossible de vérifier si votre adresse est couverte par le QG ! Veuillez redéfinir votre adresse !"
        );
      } else if (!userData.travelDetails[cart.headquarterId].covered) {
        return Promise.reject(
          "Votre adresse n'est pas couverte par le QG ! Veuillez faire votre panier dans un autre QG !"
        );
      }
    }

    // Check if the user has a phone number set if the order contains a call service
    if (
      Object.entries(cart.items).some(
        (item) => items[item[0]].needPhoneNumber
      ) &&
      !userData.phoneNumber
    ) {
      return Promise.reject(
        "Vous devez avoir renseigné votre numéro de téléphone pour passer une commande !"
      );
    }

    // Check if the quantity of the items in the shopping cart is coverable by the available quantity in the headquarter
    const missingItem = Object.entries(cart.items).find(
      (item) => items[item[0]].quantity[cart.headquarterId] < item[1]
    );
    if (missingItem) {
      return Promise.reject(
        `Le produit "${
          items[missingItem[0]].name
        }" n'est pas disponible en assez grande quantité à la vente dans ce QG, veuillez mettre à jour les votre panier !`
      );
    }

    // Check if all items can be ordered this day
    const date = new Date();
    const day = date.getDay();
    const isWeekend = day === 6 || day === 0;
    const weekendItem = Object.entries(cart.items).find(
      (item) => items[item[0]].weekendOnly && !isWeekend
    );
    if (weekendItem) {
      return Promise.reject(
        `Le produit "${
          items[weekendItem[0]].name
        }" n'est disponible que le week-end, veuillez le supprimer de votre panier !`
      );
    }

    // Create the order
    const order: Order = {
      headquarterId: cart.headquarterId,
      items: cart.items,
      comment: cart.comment || null,
      address: userData.address,
      phoneNumber: userData.phoneNumber || null,
      datetime: new Date().toISOString(),
      status: "pending",
    };

    // Create the order in the database
    const orderRef = ref(db, `/users/${user.uid}/orders`);
    return push(orderRef, order)
      .then(() => {
        // Delete the shopping cart
        deleteShoppingCart();

        return Promise.resolve(
          "Commande enregistrée ! Vous pouvez désormais suivre son avancement dans cet onglet."
        );
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(
          "Une erreur est survenue lors de la création de la commande !"
        );
      });
  };

  // Function to cancel a pending order
  const cancelPendingOrder = (
    userId: string,
    orderId: string
  ): Promise<string> => {
    // Check if the user is logged in
    if (!user || !userData) {
      return Promise.reject(
        "Vous devez être connecté pour annuler une commande !"
      );
    }

    // Check if the user is the owner of the order
    if (!userData.admin && userData.uid !== userId) {
      return Promise.reject(
        "Vous n'êtes pas le propriétaire de cette commande !"
      );
    }

    // Get the order status once from the database
    const orderStatusRef = ref(db, `/users/${userId}/orders/${orderId}/status`);
    return get(orderStatusRef).then((snapshot) => {
      // Check if the order is pending
      const orderStatusValue = snapshot.val();
      if (orderStatusValue !== "pending") {
        return Promise.reject(
          "Impossible d'annuler cette commande car elle n'est plus en attente de validation !"
        );
      } else {
        // Cancel the order
        return set(orderStatusRef, "cancelled")
          .then(() => {
            return Promise.resolve("Commande annulée avec succès !");
          })
          .catch((error) => {
            console.error(error);
            return Promise.reject(
              "Une erreur est survenue lors de l'annulation de la commande !"
            );
          });
      }
    });
  };

  // Function to load headquarters from realtime database (bind listener)
  const loadHeadquarters = (): DatabaseReference => {
    const headquartersRef = ref(db, "headquarters");
    onValue(headquartersRef, (snapshot) => {
      setHeadquartersLoaded(true);
      const headquartersData = snapshot.val() as HeadquarterList;
      setHeadquarters(headquartersData);
    });
    return headquartersRef;
  };

  // Function to load items from realtime database (bind listener)
  const loadItems = (): DatabaseReference => {
    const itemsRef = ref(db, "items");
    onValue(itemsRef, (snapshot) => {
      setItemsLoaded(true);
      const itemsData = snapshot.val() as ItemList;
      setItems(itemsData);
    });
    return itemsRef;
  };

  // Function to load listeux from realtime database (bind listener)
  const loadAdminConfig = (): DatabaseReference => {
    const adminConfigRef = ref(db, "config");
    onValue(adminConfigRef, (snapshot) => {
      setAdminConfigLoaded(true);
      const adminConfigData = snapshot.val() as AdminConfig;
      setAdmingConfig(adminConfigData);
    });
    return adminConfigRef;
  };

  // Function to assign listeux to an order
  const assignListeux = (
    userId: string,
    orderId: string,
    listeuxIds: string[],
    estimatedPreparationTime: number
  ): Promise<string> => {
    // Check if the user is logged in
    if (!user || !userData) {
      return Promise.reject(
        "Vous devez être connecté pour annuler une commande !"
      );
    }

    const date = new Date();
    const preparedDate = new Date(date);
    preparedDate.setMinutes(date.getMinutes() + estimatedPreparationTime);

    // Get the order once from the database
    const orderRef = ref(db, `/users/${userId}/orders/${orderId}`);
    return get(orderRef)
      .then((snapshot) => {
        const orderValue = snapshot.val();

        // Check if the order is pending
        if (orderValue.status !== "pending") {
          return Promise.reject(
            "Impossible d'assigner cette commande car elle n'est plus en attente de validation !"
          );
        }

        const updatedOrder: Order = {
          ...orderValue,
          deliveryBy: listeuxIds,
          acceptedTime: date.toISOString(),
          nextStepExpectedTime: preparedDate.toISOString(),
          status: "accepted",
        };

        // Update the order in the database
        return set(orderRef, updatedOrder)
          .then(() => {
            return Promise.resolve("Listeux assignés avec succès !");
          })
          .catch((error) => {
            console.error(error);
            return Promise.reject(
              "Une erreur est survenue lors de l'assignation des listeux !"
            );
          });
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(
          "Une erreur est survenue lors de l'assignation des listeux !"
        );
      });
  };

  // Function to set quantity of an item in a headquarter
  const setItemQuantity = (
    headquarterId: string,
    itemId: string,
    quantity: number
  ): Promise<string> => {
    // Check if the user is logged in
    if (!user || !userData || !userData.admin) {
      return Promise.reject(
        "Vous devez être connecté et administrateur pour modifier la quantité d'un item !"
      );
    }

    // Create a reference to the item in the database
    const itemRef = ref(db, `/items/${itemId}/quantity/${headquarterId}`);

    // Update the item quantity in the database
    return set(itemRef, quantity)
      .then(() => {
        return Promise.resolve("Quantité modifiée avec succès !");
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(
          "Une erreur est survenue lors de la modification de la quantité !"
        );
      });
  };

  // Function to set quantity of an item in a headquarter
  const setItemAvailability = (
    headquarterId: string,
    itemId: string,
    availability: boolean
  ): Promise<string> => {
    // Check if the user is logged in
    if (!user || !userData || !userData.admin) {
      return Promise.reject(
        "Vous devez être connecté et administrateur pour modifier la disponibilité d'un item !"
      );
    }

    // Create a reference to the item in the database
    const itemRef = ref(db, `/items/${itemId}/availability/${headquarterId}`);

    // Update the item quantity in the database
    return set(itemRef, availability)
      .then(() => {
        return Promise.resolve("Disponibilité modifiée avec succès !");
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(
          "Une erreur est survenue lors de la modification de la disponibilité !"
        );
      });
  };

  // Function to open/close a headquarter
  const setHeadquarterOpeningStatus = (
    headquarterId: string,
    status: boolean
  ): Promise<string> => {
    // Check if the user is logged in
    if (!user || !userData || !userData.admin) {
      return Promise.reject(
        "Vous devez être connecté et administrateur pour modifier le statut d'un QG !"
      );
    }

    // Create a reference to the headquarter status
    const headquarterRef = ref(db, `/headquarters/${headquarterId}/opened`);
    return set(headquarterRef, status)
      .then(() => {
        return Promise.resolve("Statut du QG modifié avec succès !");
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(
          "Une erreur est survenue lors de la modification du statut du QG !"
        );
      });
  };

  // Function to set the price of a special request
  const setSpecialRequestPrice = (
    userId: string,
    orderId: string,
    price: number
  ): Promise<string> => {
    // Check if the user is logged in
    if (!user || !userData) {
      return Promise.reject(
        "Vous devez être connecté pour annuler une commande !"
      );
    }

    // Get the order once from the database
    const orderRef = ref(db, `/users/${userId}/orders/${orderId}`);
    return get(orderRef)
      .then((snapshot) => {
        const orderValue = snapshot.val();

        // Check if the order is pending
        if (orderValue.status !== "pending") {
          return Promise.reject(
            "Impossible de modifier le prix de cette commande car elle n'est plus en attente de validation !"
          );
        }

        const updatedOrder: Order = {
          ...orderValue,
          items: {
            ...orderValue.items,
            zspecial: price,
          },
        };

        // Update the order in the database
        return set(orderRef, updatedOrder)
          .then(() => {
            return Promise.resolve("Prix de la commande modifié avec succès !");
          })
          .catch((error) => {
            console.error(error);
            return Promise.reject(
              "Une erreur est survenue lors de la modification du prix de la commande !"
            );
          });
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(
          "Une erreur est survenue lors de la modification du prix de la commande !"
        );
      });
  };

  // Function to mark an order as delivered
  const markOrderAsDelivered = (
    userId: string,
    orderId: string
  ): Promise<string> => {
    // Check if the user is logged in
    if (!user || !userData) {
      return Promise.reject(
        "Vous devez être connecté pour terminer une commande !"
      );
    }

    // Get the order once from the database
    const orderRef = ref(db, `/users/${userId}/orders/${orderId}`);
    return get(orderRef)
      .then((snapshot) => {
        const orderValue = snapshot.val();

        // Check if the order is pending
        if (orderValue.status !== "ongoing") {
          return Promise.reject(
            "Impossible de terminer cette commande car elle n'est pas en cours de livraison !"
          );
        }

        const updatedOrder: Order = {
          ...orderValue,
          status: "delivered",
          deliveredTime: new Date().toISOString(),
        };

        // Update the order in the database
        return set(orderRef, updatedOrder)
          .then(() => {
            return Promise.resolve("Commande terminée avec succès !");
          })
          .catch((error) => {
            console.error(error);
            return Promise.reject(
              "Une erreur est survenue lors de la terminaison de la commande !"
            );
          });
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(
          "Une erreur est survenue lors de la terminaison de la commande !"
        );
      });
  };

  // Function to create an order by phone
  const createOrderByPhone = (order: Order): Promise<string> => {
    // Check if the user is logged in
    if (!user || !userData || !userData.admin) {
      return Promise.reject(
        "Vous devez être connecté et administrateur pour créer une commande par téléphone !"
      );
    }

    // Create a reference to the order
    const orderRef = ref(db, `/users/standard/orders`);

    // Create a new order in the database
    return push(orderRef, order)
      .then((snapshot) => {
        return Promise.resolve(`Commande créée avec succès !`);
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(
          "Une erreur est survenue lors de la création de la commande !"
        );
      });
  };

  // Function to start an order delivery
  const updateDeliveryStatus = async (
    userId: string,
    orderId: string,
    location: {
      latitude: number;
      longitude: number;
    }
  ): Promise<string> => {
    // Check if the user is logged in
    if (!user || !userData) {
      return Promise.reject(
        "Vous devez être connecté pour mettre à jour le statut de la livraison !"
      );
    }

    // Get the order once from the database
    const orderRef = ref(db, `/users/${userId}/orders/${orderId}`);
    return get(orderRef)
      .then(async (snapshot) => {
        const orderValue: Order = snapshot.val();

        const idToken = user ? await user.getIdToken() : null;
        if (idToken) {
          return fetch(
            `https://deliveirb.aboin.fr/api/location?action=getTravelDetailsFromCoordinates&lat1=${location.latitude}&lng1=${location.longitude}&lat2=${orderValue.address?.location.lat}&lng2=${orderValue.address?.location.lng}&idToken=${idToken}`
          )
            .then((res) => res.json())
            .then((json: ApiResponse<any>) => {
              if (json.status === "ok") {
                const travelDetails = json.data;

                const date = new Date();
                const nextStepExpectedTime = new Date(date);
                nextStepExpectedTime.setMinutes(
                  date.getMinutes() + (travelDetails.duration.value / 60) * 1.2
                );

                // Update the order in the database
                const updatedOrder: Order = {
                  ...orderValue,
                  ongoingTime: date.toISOString(),
                  status: "ongoing",
                  nextStepExpectedTime: nextStepExpectedTime.toISOString(),
                };

                return set(orderRef, updatedOrder)
                  .then(() => {
                    return Promise.resolve(
                      "Statut de la livraison mis à jour avec succès !"
                    );
                  })
                  .catch((error) => {
                    console.error(error);
                    return Promise.reject(
                      "Une erreur est survenue lors de la mise à jour du statut de la livraison !"
                    );
                  });
              } else {
                return Promise.reject(json.message);
              }
            });
        } else {
          return Promise.reject(
            "Vous devez être connecté pour mettre à jour le statut de la livraison !"
          );
        }
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(
          "Une erreur est survenue lors de la mise à jour du statut de la livraison !"
        );
      });
  };

  useEffect(() => {
    const refs: DatabaseReference[] = [];
    if (!headquartersLoaded) {
      const headquartersRef = loadHeadquarters();
      refs.push(headquartersRef);
    }

    if (!itemsLoaded) {
      const itemsRef = loadItems();
      refs.push(itemsRef);
    }

    if (user && !adminConfigLoaded) {
      const adminConfigRef = loadAdminConfig();
      refs.push(adminConfigRef);
    }

    if (!user) {
      setAdmingConfig({
        birthdays: {},
        listeux: {},
        specialDetails: "",
      });
      setAdminConfigLoaded(false);
    }

    return () => {
      refs.forEach((ref) => off(ref));
    };
  }, [user]);

  /**
   * On any shopping cart state change :
   * - Set the new shopping cart in the async storage
   */
  useEffect(() => {
    if (!shoppingCartLoaded)
      // Prevent the update of the async storage with not defined shopping cart state
      return;

    const cart = shoppingCart || null;

    const count = Object.entries(cart?.items || {}).reduce((a, b) => {
      return a + b[1];
    }, 0);
    setItemCount(count);
    const totalPrice = Object.entries(cart?.items || {}).reduce((a, b) => {
      return a + b[1] * items[b[0]].price;
    }, 0);
    setTotalPrice(totalPrice);
    const preparationTime =
      Object.entries(cart?.items || {})
        .sort((a, b) => {
          return items[a[0]] && items[b[0]]
            ? items[a[0]].preparationTime - items[b[0]].preparationTime
            : 0;
        })
        .map(([itemId, quantity]) => {
          return items[itemId] ? quantity * items[itemId].preparationTime : 0;
        })
        .reduce((a, b) => {
          return a + b;
        }, 0) * 60;
    setPreparationTime(preparationTime);

    AsyncStorage.setItem("shoppingCart", JSON.stringify(cart));
  }, [shoppingCart]);

  /**
   * On any item state change :
   * - Update price and quantity of the item in the shopping cart
   */
  useEffect(() => {
    const count = Object.entries(shoppingCart?.items || {}).reduce((a, b) => {
      return a + b[1];
    }, 0);
    setItemCount(count);
    const totalPrice = Object.entries(shoppingCart?.items || {}).reduce(
      (a, b) => {
        return a + b[1] * (items[b[0]] ? items[b[0]].price : 0);
      },
      0
    );
    setTotalPrice(totalPrice);
  }, [items]);

  /**
   * Load shopping cart from async storage
   */
  const loadShoppingCart = async (): Promise<void> => {
    const value = await AsyncStorage.getItem("shoppingCart").catch((e) =>
      console.error("Error while retrieving shoppingCart", e)
    );

    const cart: Cart = value ? JSON.parse(value) : null;
    setShoppingCart(cart);
    setShoppingCartLoaded(true);
  };

  useEffect(() => {
    loadShoppingCart();
  }, []);

  return (
    <HeadquartersContext.Provider
      value={{
        headquarters,
        items,
        adminConfig,
        shoppingCart,
        shoppingCartLoaded,
        itemCount,
        totalPrice,
        preparationTime,
        addItemToShoppingCart,
        removeItemFromShoppingCart,
        setShoppingCartComment,
        convertShoppingCartToOrder,
        deleteShoppingCart,
        cancelPendingOrder,
        assignListeux,
        updateDeliveryStatus,
        markOrderAsDelivered,
        setSpecialRequestPrice,
        setHeadquarterOpeningStatus,
        setItemQuantity,
        setItemAvailability,
        createOrderByPhone,
      }}
    >
      {children}
    </HeadquartersContext.Provider>
  );
};

import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { Settings } from "../types";
import { ColorSchemeName } from "react-native";

export interface SettingsContextProps extends Settings {
  setAdminMode: Dispatch<SetStateAction<boolean>>;
  setHideMyOrdersScreen: Dispatch<SetStateAction<boolean>>;
  setHideShoppingCartScreen: Dispatch<SetStateAction<boolean>>;
  setUseSystemColorScheme: Dispatch<SetStateAction<boolean>>;
  setDarkMode: Dispatch<SetStateAction<boolean>>;
  setSelectedHeadquarter: Dispatch<SetStateAction<number>>;
  getColorScheme: () => ColorSchemeName;
  loadSettings: () => Promise<void>;
}

export const SettingsContext = createContext<SettingsContextProps>(
  {} as SettingsContextProps
);

export const SettingsProvider = ({
  children,
  systemColorScheme,
}: {
  children: JSX.Element;
  systemColorScheme: ColorSchemeName;
}): JSX.Element => {
  const [settingsLoaded, setSettingsLoaded] = useState(false);

  const [adminMode, setAdminMode] = useState<boolean>(false);
  const [hideMyOrdersScreen, setHideMyOrdersScreen] =
    useState<boolean>(false);
  const [hideShoppingCartScreen, setHideShoppingCartScreen] = useState<boolean>(false);
  const [useSystemColorScheme, setUseSystemColorScheme] = useState<boolean>(true);
  const [darkMode, setDarkMode] = useState<boolean>(false);
  const [selectedHeadquarter, setSelectedHeadquarter] = useState<number>(-1);

  const getColorScheme = (): ColorSchemeName => {
    return useSystemColorScheme ? systemColorScheme : darkMode ? "dark" : "light";
  };

  /**
   * On any settings state change :
   * - Set the new settings values in the async storage
   */
  useEffect(() => {
    if (!settingsLoaded)
      // Prevent the update of the async storage with not defined settings states
      return;

    const states: Settings = {
      adminMode,
      hideMyOrdersScreen,
      hideShoppingCartScreen,
      useSystemColorScheme,
      darkMode,
      selectedHeadquarter,
    };

    AsyncStorage.setItem("settings", JSON.stringify(states));
  }, [adminMode, hideMyOrdersScreen, hideShoppingCartScreen, useSystemColorScheme, darkMode, selectedHeadquarter]);

  /**
   * Load settings and set all states values from the retreived item.
   * If the async storage is null, settings are using default state.
   */
  const loadSettings = async (): Promise<void> => {
    const value = await AsyncStorage.getItem("settings").catch((e) =>
      console.error("Error while retrieving settings", e)
    );

    if (value != null) {
      const settingsObject: Settings = JSON.parse(value);

      setAdminMode(settingsObject.adminMode || adminMode);
      setHideMyOrdersScreen(settingsObject.hideMyOrdersScreen || hideMyOrdersScreen);
      setHideShoppingCartScreen(settingsObject.hideShoppingCartScreen || hideShoppingCartScreen);
      setUseSystemColorScheme(settingsObject.useSystemColorScheme || useSystemColorScheme);
      setDarkMode(settingsObject.darkMode || darkMode);
      setSelectedHeadquarter(settingsObject.selectedHeadquarter || selectedHeadquarter);
    }
    setSettingsLoaded(true);
  };

  useEffect(() => {
    loadSettings();
  }, []);

  return (
    <SettingsContext.Provider
      value={{
        adminMode,
        setAdminMode,
        hideMyOrdersScreen,
        setHideMyOrdersScreen,
        hideShoppingCartScreen,
        setHideShoppingCartScreen,
        useSystemColorScheme,
        setUseSystemColorScheme,
        darkMode,
        setDarkMode,
        selectedHeadquarter,
        setSelectedHeadquarter,
        getColorScheme,
        loadSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

import {
  StyleSheet,
  ScrollView,
  SafeAreaView,
  Image,
  Switch,
  Pressable,
  TouchableOpacity,
  Platform,
  TextInput,
} from "react-native";

import { MaterialIcons } from "@expo/vector-icons";

import { Text, View } from "../components/Themed";

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Order, RootStackParamList, VerifiedAddress } from "../types";

import React, { useContext, useEffect, useState } from "react";
import useColorScheme from "../hooks/useColorScheme";
import Colors from "../constants/Colors";
import { LISTEUX_STATES, nativeConfirm } from "../utils";
import { AuthContext } from "../context/AuthContext";
import { useToast } from "react-native-toast-notifications";
import { HeadquartersContext } from "../context/HeadquartersContext";
import { Picker } from "@react-native-picker/picker";
import { useDebounce } from "../hooks/useDebounce";

export default function NewOrderScreen({
  navigation,
  route,
}: NativeStackScreenProps<RootStackParamList, "NewOrder">) {
  const colorScheme = useColorScheme();
  const toast = useToast();
  const { userData, getAddressesSuggestions } = useContext(AuthContext);

  const { headquarters, items, createOrderByPhone } =
    useContext(HeadquartersContext);
  const [selectedHeadquarter, setSelectedHeadquarter] = useState<number>(-1);

  const [clientName, setClientName] = useState("");
  const [clientPhone, setClientPhone] = useState("");

  const [address, setAddress] = useState("");
  const debouncedAddress = useDebounce(address, 1000);

  const [orderedItems, setOrderedItems] = useState<{ [key: string]: number }>(
    Object.fromEntries(Object.entries(items).map(([key, value]) => [key, 0]))
  );

  const addQuantity = (itemId: string, quantity: number) => {
    const newQuantity = (orderedItems[itemId] || 0) + quantity;
    if (newQuantity > 0) {
      setOrderedItems((prev) => ({ ...prev, [itemId]: newQuantity }));
    } else {
      setOrderedItems((prev) => ({ ...prev, [itemId]: 0 }));
    }
  };

  const constructVerifiedAddress = (result: any): VerifiedAddress => {
    return {
      userInput: {
        number:
          result.address_components.find((c: any) => {
            return c.types.includes("street_number");
          })?.long_name || "",
        street:
          result.address_components.find((c: any) => {
            return c.types.includes("route");
          })?.long_name || "",
        city:
          result.address_components.find((c: any) => {
            return c.types.includes("locality");
          })?.long_name || "",
        postal_code:
          result.address_components.find((c: any) => {
            return c.types.includes("postal_code");
          })?.long_name || "",
        complement: "",
        details: "",
      },
      formattedAddress: result.formatted_address,
      location: result.geometry.location,
    };
  };

  // State to hold search results
  const [searchResults, setSearchResults] = useState<VerifiedAddress[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<VerifiedAddress>();

  useEffect(
    () => {
      if (debouncedAddress) {
        // Get results corresponding to the address
        getAddressesSuggestions(debouncedAddress).then((results) => {
          setSearchResults(
            results.map((result: any) => constructVerifiedAddress(result))
          );
        });
      } else {
        setSearchResults([]);
      }
    },
    [debouncedAddress] // Only call effect if debounced search term changes
  );

  const validateOrder = () => {
    if (selectedHeadquarter === -1) {
      toast.show("Veuillez sélectionner une QG", {
        type: "warning",
      });
      return;
    }
    if (
      !selectedAddress?.formattedAddress ||
      !selectedAddress?.location ||
      !selectedAddress?.userInput
    ) {
      toast.show("Veuillez sélectionner une adresse en faisant une recherche", {
        type: "warning",
      });
      return;
    }

    const date = new Date();
    date.setMinutes(date.getMinutes() - 2);

    const order: Order = {
      address: selectedAddress,
      items: Object.fromEntries(
        Object.entries(orderedItems).filter(([key, value]) => value > 0)
      ),
      comment: "Commande téléphonique pour " + clientName,
      datetime: date.toISOString(),
      headquarterId: selectedHeadquarter,
      phoneNumber: clientPhone,
      status: "pending",
    };

    createOrderByPhone(order)
      .then((message) => {
        toast.show(message, {
          type: "success",
        });
        navigation.goBack();
        navigation.navigate("PendingOrders");
      })
      .catch((error) => {
        toast.show(error, {
          type: "danger",
        });
      });
  };

  return (
    <SafeAreaView style={styles.safeAreaView}>
      <View style={styles.container}>
        <ScrollView style={{ width: "100%", padding: 20 }}>
          {/* Input to type phone number */}
          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Numéro de téléphone</Text>
            <TextInput
              style={[
                styles.input,
                {
                  color: Colors[colorScheme].text,
                  backgroundColor: Colors[colorScheme].card,
                },
              ]}
              placeholder="Numéro de téléphone"
              placeholderTextColor="grey"
              keyboardType="phone-pad"
              autoCapitalize="none"
              autoCorrect={false}
              returnKeyType="next"
              onChangeText={(text) => setClientPhone(text)}
              value={clientPhone}
            />
          </View>
          {/* Input to type the name of the client */}
          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Nom du client</Text>
            <TextInput
              style={[
                styles.input,
                {
                  color: Colors[colorScheme].text,
                  backgroundColor: Colors[colorScheme].card,
                },
              ]}
              placeholder="Nom du client"
              placeholderTextColor="grey"
              keyboardType="default"
              autoCapitalize="words"
              autoCorrect={false}
              returnKeyType="next"
              onChangeText={(text) => setClientName(text)}
            />
          </View>
          <View style={styles.searchInput}>
            <MaterialIcons
              size={20}
              style={{ padding: 6, marginRight: 8 }}
              name="search"
              color="#aaa"
            />
            <TextInput
              style={styles.searchInputText}
              placeholder="Tapez l'adresse à rechercher"
              placeholderTextColor="#888"
              onChangeText={(text) => setAddress(text)}
              value={address}
            />
            {address ? (
              <MaterialIcons
                size={20}
                style={{ padding: 6 }}
                name="close"
                color="#aaa"
                onPress={() => setAddress("")}
              />
            ) : null}
          </View>
          {/* List search results */}
          <View style={styles.searchResults}>
            {searchResults.map((result) => (
              <TouchableOpacity
                key={result.formattedAddress}
                style={styles.searchResult}
                onPress={() => {
                  setSelectedAddress(result);
                }}
              >
                <Text style={styles.searchResultText}>
                  {result.formattedAddress}
                </Text>
              </TouchableOpacity>
            ))}
            {searchResults.length === 0 &&
              (address == null || address == "" ? (
                <View style={styles.searchResult}>
                  <Text style={[styles.searchResultText, { color: "#3498db" }]}>
                    Commencez à taper votre adresse pour obtenir des suggestions
                    ici
                  </Text>
                </View>
              ) : (
                <View style={styles.searchResult}>
                  <Text style={[styles.searchResultText, { color: "#e74c3c" }]}>
                    Aucun résultat correspondant
                  </Text>
                </View>
              ))}
          </View>
          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Choix du QG</Text>
            <Picker
              style={[
                styles.headquarterPicker,
                {
                  color: Colors[colorScheme].text,
                  backgroundColor: Colors[colorScheme].card,
                },
              ]}
              selectedValue={selectedHeadquarter.toString()}
              onValueChange={(itemValue: string, itemIndex: number) =>
                setSelectedHeadquarter(parseInt(itemValue))
              }
              mode="dropdown"
              dropdownIconColor={Colors[colorScheme].text}
              itemStyle={{
                color: "gray",
                fontSize: 16,
              }}
            >
              <Picker.Item label="Choisir un QG" value="-1" />
              {Object.entries(headquarters).map(([id, hq]) => (
                <Picker.Item
                  label={`${hq.name} (${hq.position})`}
                  value={id}
                  key={id}
                />
              ))}
            </Picker>
          </View>
          {/* Input to type house number */}
          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Numéro de maison</Text>
            <TextInput
              style={[
                styles.input,
                {
                  color: Colors[colorScheme].text,
                  backgroundColor: Colors[colorScheme].card,
                },
              ]}
              placeholder="Numéro de maison"
              placeholderTextColor="grey"
              keyboardType="number-pad"
              autoCapitalize="none"
              autoCorrect={false}
              returnKeyType="next"
              onChangeText={(text) => {
                const newAddress = {
                  ...selectedAddress,
                };
                if (newAddress.userInput) newAddress.userInput.number = text;
                setSelectedAddress(newAddress as VerifiedAddress);
              }}
              value={selectedAddress?.userInput?.number}
            />
          </View>
          {/* Input to type the address */}
          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Adresse</Text>
            <TextInput
              style={[
                styles.input,
                {
                  color: Colors[colorScheme].text,
                  backgroundColor: Colors[colorScheme].card,
                },
              ]}
              placeholder="Adresse"
              placeholderTextColor="grey"
              keyboardType="default"
              autoCapitalize="words"
              autoCorrect={false}
              returnKeyType="next"
              onChangeText={(text) => {
                const newAddress = {
                  ...selectedAddress,
                };
                if (newAddress.userInput) newAddress.userInput.street = text;
                setSelectedAddress(newAddress as VerifiedAddress);
              }}
              value={selectedAddress?.userInput?.street}
            />
          </View>
          {/* Input to type the postal code */}
          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Code postal</Text>
            <TextInput
              style={[
                styles.input,
                {
                  color: Colors[colorScheme].text,
                  backgroundColor: Colors[colorScheme].card,
                },
              ]}
              placeholder="Code postal"
              placeholderTextColor="grey"
              keyboardType="number-pad"
              autoCapitalize="none"
              autoCorrect={false}
              returnKeyType="next"
              onChangeText={(text) => {
                const newAddress = {
                  ...selectedAddress,
                };
                if (newAddress.userInput)
                  newAddress.userInput.postal_code = text;
                setSelectedAddress(newAddress as VerifiedAddress);
              }}
              value={selectedAddress?.userInput?.postal_code}
            />
          </View>
          {/* Input to type the city */}
          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Ville</Text>
            <TextInput
              style={[
                styles.input,
                {
                  color: Colors[colorScheme].text,
                  backgroundColor: Colors[colorScheme].card,
                },
              ]}
              placeholder="Ville"
              placeholderTextColor="grey"
              keyboardType="default"
              autoCapitalize="words"
              autoCorrect={false}
              returnKeyType="next"
              onChangeText={(text) => {
                const newAddress = {
                  ...selectedAddress,
                };
                if (newAddress.userInput) newAddress.userInput.city = text;
                setSelectedAddress(newAddress as VerifiedAddress);
              }}
              value={selectedAddress?.userInput?.city}
            />
          </View>
          {/* Input to type address complement */}
          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Complément d'adresse</Text>
            <TextInput
              style={[
                styles.input,
                {
                  color: Colors[colorScheme].text,
                  backgroundColor: Colors[colorScheme].card,
                },
              ]}
              placeholder="Complément d'adresse"
              placeholderTextColor="grey"
              keyboardType="default"
              autoCapitalize="words"
              autoCorrect={false}
              returnKeyType="next"
              onChangeText={(text) => {
                const newAddress = {
                  ...selectedAddress,
                };
                if (newAddress.userInput)
                  newAddress.userInput.complement = text;
                setSelectedAddress(newAddress as VerifiedAddress);
              }}
              value={selectedAddress?.userInput?.complement || ""}
            />
          </View>
          {/* Input to type the details */}
          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Détails</Text>
            <TextInput
              style={[
                styles.input,
                {
                  color: Colors[colorScheme].text,
                  backgroundColor: Colors[colorScheme].card,
                },
              ]}
              placeholder="Détails"
              placeholderTextColor="grey"
              keyboardType="default"
              autoCapitalize="words"
              autoCorrect={false}
              returnKeyType="next"
              onChangeText={(text) => {
                const newAddress = {
                  ...selectedAddress,
                };
                if (newAddress.userInput) newAddress.userInput.details = text;
                setSelectedAddress(newAddress as VerifiedAddress);
              }}
              value={selectedAddress?.userInput?.details || ""}
            />
          </View>
          <Text style={styles.checklistTitle}>Articles commandés :</Text>
          {Object.entries(items).map(([itemId, count]) => (
            <View key={itemId} style={styles.checklistItem}>
              <View style={styles.horizontalContainer}>
                <Text style={styles.checklistItemText}>
                  {orderedItems[itemId]} x{" "}
                  {items[itemId].shortname
                    ? items[itemId].shortname
                    : items[itemId].name}
                </Text>
              </View>
              <View style={styles.horizontalContainer}>
                <TouchableOpacity
                  style={[
                    styles.checklistItemButton,
                    {
                      backgroundColor:
                        orderedItems[itemId] > 0 ? "#2ecc71" : "grey",
                    },
                  ]}
                  onPress={() => addQuantity(itemId, -10)}
                >
                  <Text>-10</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={[
                    styles.checklistItemButton,
                    {
                      backgroundColor:
                        orderedItems[itemId] > 0 ? "#e74c3c" : "grey",
                    },
                  ]}
                  onPress={() => addQuantity(itemId, -1)}
                >
                  <MaterialIcons name="remove" size={24} color="white" />
                </TouchableOpacity>
                <TouchableOpacity
                  style={[
                    styles.checklistItemButton,
                    {
                      backgroundColor: "#3498db",
                    },
                  ]}
                  onPress={() => addQuantity(itemId, 1)}
                >
                  <MaterialIcons name="add" size={24} color="white" />
                </TouchableOpacity>
                <TouchableOpacity
                  style={[
                    styles.checklistItemButton,
                    {
                      backgroundColor: "#2ecc71",
                    },
                  ]}
                  onPress={() => addQuantity(itemId, 10)}
                >
                  <Text>+10</Text>
                </TouchableOpacity>
              </View>
            </View>
          ))}
          {/* Button to confirm the order */}
          <TouchableOpacity
            style={styles.button}
            onPress={() => {
              validateOrder();
            }}
          >
            <MaterialIcons
              name="check"
              size={24}
              color={Colors[colorScheme].text}
            />
            <Text style={styles.buttonText}>Valider la commande</Text>
          </TouchableOpacity>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
  },
  listeuxList: {
    padding: 16,
  },

  itemCard: {
    borderRadius: 12,
    paddingVertical: 10,
    paddingHorizontal: 16,
    marginBottom: 10,
    flex: 1,
  },
  itemCardHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent",
  },
  itemCardText: {
    fontSize: 14,
    fontWeight: "bold",
  },
  itemCardValue: {
    fontSize: 14,
    fontWeight: "bold",
    color: "grey",
  },

  status: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "transparent",
  },
  switch: {
    marginRight: 8,
  },

  estimatedPreparationTimeTitle: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 8,
    textAlign: "center",
  },
  estimatedPreparationTimeText: {
    fontSize: 16,
    marginHorizontal: 8,
  },
  estimatedPreparationTimeButtons: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
  },
  estimatedPreparationTimeButton: {
    width: 48,
    height: 48,
    borderRadius: 24,
    backgroundColor: "grey",
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: 4,
  },

  headquarterPicker: {
    marginBottom: 10,
    height: 60,
    top: Platform.OS === "ios" ? -65 : 0,
  },

  inputContainer: {
    marginBottom: 10,
  },

  inputLabel: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 8,
  },

  input: {
    height: 40,
    borderColor: "gray",
    borderWidth: 1,
    borderRadius: 6,
    paddingHorizontal: 8,
  },

  searchInput: {
    height: 50,
    borderRadius: 25,
    marginVertical: 6,
    backgroundColor: "#eee",
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 16,
  },
  searchInputText: {
    flex: 1,
    fontSize: 16,
    color: "#444",
  },
  searchResults: {
    paddingTop: 10,
  },
  searchResult: {
    flexDirection: "row",
    alignItems: "center",
    borderTopColor: "#eee",
    borderTopWidth: 1,
    borderRadius: 10,
    padding: 10,
  },
  searchResultText: {
    fontSize: 14,
    margin: 10,
  },

  horizontalContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },

  checklistTitle: {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 10,
  },
  checklistItem: {
    flexDirection: "column",
    borderColor: "grey",
    borderWidth: 3,
    borderRadius: 10,
    marginVertical: 6,
  },
  checklistItemText: {
    fontSize: 16,
    marginLeft: 10,
    textAlign: "left",
  },
  checklistItemButton: {
    backgroundColor: "#9b59b6",
    padding: 3,
    borderRadius: 8,
    margin: 5,
  },

  button: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    borderRadius: 10,
    marginBottom: 10,
    backgroundColor: "#2ecc71",
  },

  buttonText: {
    fontSize: 16,
    fontWeight: "bold",
    color: "white",
    marginLeft: 10,
  },
});

import React, { useContext, useState, useRef, useEffect } from "react";
import {
  FirebaseRecaptchaBanner,
  FirebaseRecaptchaVerifierModal,
} from "expo-firebase-recaptcha";

import {
  signOut,
  unlinkPhoneNumber,
} from "../auth";
import { AuthContext } from "../context/AuthContext";

import {
  StyleSheet,
  Image,
  TouchableOpacity,
  ScrollView,
  TextInput,
  Platform,
} from "react-native";

import { MaterialIcons } from "@expo/vector-icons";

import { Text, View } from "../components/Themed";
import { RootTabScreenProps } from "../types";

import { ConfirmationResult } from "firebase/auth";
import { nativeConfirm, nativeDialog } from "../utils";
import Colors from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme";
import { useToast } from "react-native-toast-notifications";

export default function AccountScreen({
  navigation,
  route,
}: RootTabScreenProps<"Account">) {
  const toast = useToast();
  const colorScheme = useColorScheme();
  const { user, userData, setUserAddress, setUserPhoneNumber } =
    useContext(AuthContext);

  const [phoneInputFocused, setPhoneInputFocused] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
    useState<ConfirmationResult | null>(null);
  const recaptchaVerifier = useRef<FirebaseRecaptchaVerifierModal | null>(null);

  const codeInputRef = useRef<TextInput>(null);

  const verifyPhoneNumber = async () => {
    setPhoneInputFocused(false);
    if (
      phoneNumber.length === 10 &&
      phoneNumber.match(/^[0-9]+$/) &&
      (phoneNumber.startsWith("06") || phoneNumber.startsWith("07"))
    ) {
      if (user) {
        setUserPhoneNumber(phoneNumber).then(() => {
          toast.show(
            "Votre numéro de téléphone a été enregistré sur votre compte avec succès !",
            {
              type: "success",
            }
          );
          setPhoneNumber("");
        });
      }
    } else {
      nativeDialog(
        "Erreur",
        "Numéro de téléphone invalide\n\nVeuillez renseigner votre numéro sans espaces ni tirets, au format 06XXXXXXXX ou 07XXXXXXXX."
      );
    }
  };

  // On load
  useEffect(() => {
    if (route.params?.message) {
      toast.show(route.params.message);
    }
  }, []);

  return (
    <View style={styles.mainContainer}>
      <ScrollView
        contentContainerStyle={{ flexGrow: 1, justifyContent: "center" }}
        keyboardShouldPersistTaps="handled"
      >
        <View style={styles.container}>
          <Image
            source={{
              uri: user?.photoURL
                ? user.photoURL
                : "https://pbs.twimg.com/profile_images/3309741408/eff94615a3653c01a9d5a178ced7fbb5_400x400.jpeg",
            }}
            style={styles.profileImage}
          />
          <Text style={styles.name}>
            {user ? user.displayName : "Chargement..."}
          </Text>
          <Text style={styles.email}>
            {user ? user.email : "Chargement..."}
          </Text>
          <View
            style={[
              styles.separator,
              { backgroundColor: Colors[colorScheme].separator },
            ]}
          />
          <View style={styles.parameters}>
            <Text style={styles.parametersTitle}>Numéro de téléphone</Text>
            <View
              style={[
                styles.parameterGroup,
                { backgroundColor: Colors[colorScheme].card },
              ]}
            >
              <MaterialIcons
                name="phone"
                size={24}
                color={Colors[colorScheme].text}
                style={styles.parameterIcon}
              />
              {userData?.phoneNumber ? (
                <TouchableOpacity
                  style={styles.parameter}
                  onPress={() => {
                    if (user) {
                      nativeConfirm(
                        "Suppression du numéro de téléphone",
                        "Voulez-vous supprimer ce numéro de téléphone de votre compte ?",
                        () => {
                          unlinkPhoneNumber(user).then(() => {
                            setUserPhoneNumber(null);
                            toast.show(
                              "Votre numéro de téléphone a été supprimé de votre compte avec succès !",
                              {
                                type: "success",
                              }
                            );
                          });
                        },
                        "Oui, supprimer"
                      );
                    }
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      backgroundColor: "transparent",
                    }}
                  >
                    <Text style={[styles.parameterValue, { lineHeight: 36 }]}>
                      {userData.phoneNumber}
                    </Text>
                    <Text
                      style={[
                        styles.parameterValue,
                        {
                          color: "#2ecc71",
                          fontStyle: "italic",
                          lineHeight: 36,
                        },
                      ]}
                    >
                      · Enregistré
                    </Text>
                  </View>
                </TouchableOpacity>
              ) : (
                <View
                  style={{
                    flexDirection: "row",
                    backgroundColor: "transparent",
                    flex: 1,
                  }}
                >
                  <TextInput
                    value={phoneNumber}
                    keyboardType="phone-pad"
                    autoCompleteType="tel"
                    placeholder={
                      phoneInputFocused
                        ? "Entrez votre numéro de téléphone"
                        : "Aucun numéro enregistré"
                    }
                    placeholderTextColor="grey"
                    onChangeText={(text) => setPhoneNumber(text)}
                    style={[
                      styles.parameterValue,
                      {
                        flex: 1,
                        color: Colors[colorScheme].text,
                        lineHeight: Platform.OS === "ios" ? 20 : 36,
                      },
                    ]}
                    onSubmitEditing={verifyPhoneNumber}
                    onBlur={() => setPhoneInputFocused(false)}
                    onFocus={() => setPhoneInputFocused(true)}
                  />
                  <Text
                    style={[
                      styles.errorMessage,
                      {
                        display:
                          (phoneNumber.length > 1 &&
                            !(
                              phoneNumber.startsWith("06") ||
                              phoneNumber.startsWith("07")
                            )) ||
                          phoneNumber.length > 10
                            ? "flex"
                            : "none",
                      },
                    ]}
                  >
                    Format invalide
                  </Text>
                  <TouchableOpacity
                    onPress={verifyPhoneNumber}
                    style={{
                      display: phoneNumber.length === 10 ? "flex" : "none",
                      width: 36,
                      height: 36,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <MaterialIcons
                      name="arrow-forward"
                      size={24}
                      color={Colors[colorScheme].text}
                      style={styles.parameterIcon}
                    />
                  </TouchableOpacity>
                </View>
              )}
            </View>
            <Text style={styles.parametersTitle}>Adresses enregistrées</Text>
            <View
              style={[
                styles.parameterGroup,
                { backgroundColor: Colors[colorScheme].card },
              ]}
            >
              <MaterialIcons
                name="home"
                size={24}
                color={Colors[colorScheme].text}
                style={styles.parameterIcon}
              />
              <TouchableOpacity
                style={styles.parameter}
                onLongPress={() => {
                  if (user && userData?.address) {
                    nativeConfirm(
                      "Modifier l'adresse",
                      "Voulez-vous vraiment modifier l'adresse de livraison ?",
                      () => {
                        if (userData?.address) {
                          navigation.navigate("LocationSelector", {
                            address: userData.address,
                          });
                        }
                      },
                      "Oui, modifier"
                    );
                  }
                }}
                onPress={() => {
                  if (user && userData?.address) {
                    nativeConfirm(
                      "Suppression de l'adresse",
                      "Voulez-vous vraiment supprimer cette adresse de votre compte ?",
                      () => {
                        setUserAddress(null).then(() => {
                          toast.show(
                            "Votre adresse de domicile a été supprimée avec succès !",
                            {
                              type: "success",
                            }
                          );
                        });
                      },
                      "Oui, supprimer",
                      undefined,
                      "Non"
                    );
                  } else {
                    if (!userData?.address) {
                      nativeConfirm(
                        "Aucune adresse encore définie",
                        "Voulez-vous que l'application tente de déterminer automatiquement votre adresse à l'aide de votre géolocalisation ?",
                        () => {
                          navigation.navigate("LocationSelector", {
                            address: null,
                          });
                        },
                        "Oui",
                        () => {
                          navigation.navigate("AddressChoice", {
                            address: null,
                          });
                        },
                        "Non"
                      );
                    }
                  }
                }}
              >
                <Text style={styles.parameterTitle}>Domicile</Text>
                {userData?.address ? (
                  <Text style={styles.parameterValue}>
                    {userData.address.formattedAddress +
                      (userData.address.userInput.complement &&
                      userData.address.userInput.complement != ""
                        ? ` (${userData.address.userInput.complement})`
                        : "")}
                  </Text>
                ) : (
                  <Text style={[styles.parameterValue, { color: "#bbbbbb" }]}>
                    Aucune adresse enregistrée
                  </Text>
                )}
              </TouchableOpacity>
            </View>
            <View
              style={[
                styles.parameterGroup,
                { backgroundColor: Colors[colorScheme].card },
              ]}
            >
              <MaterialIcons
                name="work"
                size={24}
                color={Colors[colorScheme].text}
                style={styles.parameterIcon}
              />
              <TouchableOpacity
                style={styles.parameter}
                onPress={() =>
                  nativeDialog(
                    "Impossible de modifier l'adresse",
                    "Hey, tu ne peux pas modifier l'adresse du travail, car tu n'es pas censé te faire livrer alors que tu es en cours !\n\nPS : Par contre, tu as parfaitement le droit de passer à nos stands le midi, nous t'accueillerons avec plaisir !",
                    undefined,
                    "Dommage..."
                  )
                }
              >
                <Text style={styles.parameterTitle}>Travail</Text>
                <Text style={styles.parameterValue}>
                  Enseirb-Matmeca, 1 Avenue du Dr Albert Schweitzer, 33400
                  Talence, France
                </Text>
              </TouchableOpacity>
            </View>
          </View>
          <View style={styles.buttonsContainer}>
            <TouchableOpacity
              style={styles.logoutButton}
              onPress={() => {
                nativeConfirm(
                  "Déconnexion",
                  "Souhaitez-vous vraiment vous déconnecter ?\n\nSi vous avez une commande en cours, il ne vous sera plus possible suivre sa progression.",
                  () => {
                    signOut().then(() => {
                      toast.show("Vous avez bien été déconnecté !", {
                        type: "success",
                      });
                    });
                  }
                );
              }}
            >
              <Text style={styles.logoutButtonText}>Déconnexion</Text>
            </TouchableOpacity>
          </View>
          <FirebaseRecaptchaBanner
            textStyle={{ color: "grey", textAlign: "center" }}
            linkStyle={{ fontWeight: "bold", color: "#3498db" }}
          />
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  container: {
    flex: 1,
    padding: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  profileImage: {
    width: 92,
    height: 92,
    borderRadius: 50,
    marginBottom: 10,
  },
  name: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 10,
  },
  email: {
    fontSize: 18,
    color: "grey",
  },
  separator: {
    marginTop: 15,
    marginBottom: 5,
    height: 1,
    width: "100%",
  },
  parameters: {},
  parametersTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginVertical: 6,
  },
  parameterGroup: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 6,
    paddingVertical: 6,
    marginVertical: 4,
    borderRadius: 6,
    marginRight: 10,
    minHeight: 48,
  },
  parameterIcon: {
    marginHorizontal: 6,
  },
  parameter: {
    flex: 1,
  },
  parameterTitle: {
    fontSize: 12,
    marginLeft: 6,
  },
  parameterValue: {
    fontSize: 16,
    marginLeft: 6,
    minHeight: 24,
  },
  dialogInput: {
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 6,
    paddingVertical: 6,
    paddingHorizontal: 12,
    marginHorizontal: 8,
    marginBottom: 10,
    fontSize: 18,
  },
  buttonsContainer: {
    justifyContent: "center",
    marginBottom: 20,
  },
  logoutButton: {
    marginTop: 25,
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: "#f5f5f5",
    borderRadius: 6,
  },
  logoutButtonText: {
    fontSize: 16,
    color: "#000",
  },
  errorMessage: {
    color: "#e74c3c",
    fontSize: 13,
    padding: 4,
  },
});

/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../types';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Root: {
        screens: {
          Home: {
            path: '',
          },
          ShoppingCart: {
            path: 'shopping-cart',
          },
          Orders: {
            path: 'orders',
          },
          Account: {
            path: 'account',
          },
          HeadquarterManagement: {
            path: 'headquarter-management',
          },
          PendingOrders: {
            path: 'pending-orders',
          },
          AcceptedOrders: {
            path: 'accepted-orders',
          },
          OngoingOrders: {
            path: 'ongoing-orders',
          },
        },
      },
      Modal: 'modal',
      Headquarter: {
        path: 'headquarter/:id',
      },
      OrderDetails: {
        path: 'order/:id',
      },
      OrderTracking: {
        path: 'order/:id/tracking',
      },
      OrderMessages: {
        path: 'order/:id/messages',
      },
      Auth: {
        path: 'auth',
      },
      Settings: {
        path: 'settings',
        screens: {
          SettingsScreen: 'settings',
        },
      },
      LocationSelector: {
        path: 'location-selector',
      },
      AddressChoice: {
        path: 'address-choice',
        parse: {
          address: (address) => JSON.parse(address),
        },
        stringify: {
          address: (address) => JSON.stringify(address),
        }
      },
      AddressSearch: {
        path: 'address-search',
        parse: {
          address: (address) => JSON.parse(address),
          results: (results) => [],
        },
        stringify: {
          address: (address) => JSON.stringify(address),
          results: (results) => "list",
        }
      },
      ListeuxChoice: {
        path: 'listeux-choice',
      },
      SpecialPriceChoice: {
        path: 'special-price-choice',
      },
      NewOrder: {
        path: 'new-order',
      },
      NotFound: '*',
    },
  },
};

export default linking;

const tintColorLight = "#2f95dc";
const tintColorDark = "#fff";

export default {
  light: {
    text: "#000",
    background: "#fff",
    tint: tintColorLight,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorLight,
    logo: "#000",
    container: "#fff",
    input: "#eee",
    separator: "#eee",
    card: "#f5f5f5",
    bubble: "#E8F0FD",
    bubbleAlt: "#F2F2F2",
    textLight: "#888888",
  },
  dark: {
    text: "#eee",
    background: "#000",
    tint: tintColorDark,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorDark,
    logo: "#ccc",
    container: "#151515",
    input: "#1e1e1e",
    separator: "#333",
    card: "#222",
    bubble: "#7A9EDB",
    bubbleAlt: "#333333",
    textLight: "#bbbbbb",
  },
};

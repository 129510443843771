import {
  StyleSheet,
  TouchableOpacity,
  Image,
} from "react-native";

import { Text, View } from "./Themed";

import { Order } from "../types";
import React, { useContext, useEffect, useState } from "react";
import { formatDate, ORDER_STATES } from "../utils";
import { MaterialIcons } from "@expo/vector-icons";
import useColorScheme from "../hooks/useColorScheme";
import Colors from "../constants/Colors";
import { HeadquartersContext } from "../context/HeadquartersContext";

export default function OrderCard({
  order,
  orderId,
  clickCallback,
  displaySeparator = false,
}: {
  order: Order;
  orderId: string;
  clickCallback: () => void;
  displaySeparator?: boolean;
}) {
  const colorScheme = useColorScheme();
  const { items, headquarters } = useContext(HeadquartersContext);

  const [itemCount, setItemCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    if (order.items) {
      const itemCount = Object.keys(order.items).reduce(
        (acc, itemId) => acc + order.items[itemId],
        0
      );
      setItemCount(itemCount);
      setTotalPrice(
        Object.keys(order.items).reduce(
          (acc, itemId) => acc + (items[itemId] ? items[itemId].price : 0) * order.items[itemId],
          0
        )
      );
    }
  }, [items, headquarters, order.items]);

  return (
    headquarters[order.headquarterId] ? (
      <TouchableOpacity
        key={orderId}
        style={[
          styles.card,
          {
            borderTopColor: displaySeparator
              ? Colors[colorScheme].card
              : "transparent",
            borderTopWidth: displaySeparator ? 2 : 0,
            paddingTop: displaySeparator ? 10 : 0,
          },
        ]}
        onPress={clickCallback}
      >
        <Image
          style={styles.cardImage}
          source={{
            uri: headquarters[order.headquarterId].image,
          }}
        />
        <View style={styles.verticalContainer}>
          <Text style={styles.cardTitle}>
            {headquarters[order.headquarterId].name}
          </Text>
          {/* Item count and total price */}
          <Text style={styles.cardDescription}>
            {itemCount.toFixed(2)} article{itemCount > 1 ? "s" : ""} ·{" "}
            {totalPrice.toFixed(2)}€
          </Text>
          <Text style={styles.cardDescription}>
            {formatDate(order.datetime)}
          </Text>
          <View style={styles.horizontalContainer}>
            <MaterialIcons
              name={
                (order.status in ORDER_STATES
                  ? ORDER_STATES[order.status].icon
                  : "warning") as any
              }
              size={22}
              color={
                order.status in ORDER_STATES
                  ? ORDER_STATES[order.status].color
                  : "#ffc107"
              }
            />
            <Text
              style={[
                styles.cardDescription,
                { fontStyle: "italic", marginLeft: 2 },
              ]}
            >
              {order.status in ORDER_STATES
                ? ORDER_STATES[order.status].text
                : order.status}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    ) : null
  );
}

const styles = StyleSheet.create({
  card: {
    flexDirection: "row",
    marginVertical: 5,
    paddingBottom: 5,
  },
  cardImage: {
    width: 100,
    height: 100,
    borderRadius: 10,
  },
  verticalContainer: {
    flex: 1,
    padding: 10,
    justifyContent: "space-between",
  },
  cardTitle: {
    fontSize: 18,
    fontWeight: "bold",
  },
  cardDescription: {
    fontSize: 15,
    color: "grey",
  },
  horizontalContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
});

import { StatusBar } from "expo-status-bar";
import { LogBox } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";

import useCachedResources from "./hooks/useCachedResources";
import useSystemColorScheme from "./hooks/useSystemColorScheme";
import Navigation from "./navigation";

import { SettingsProvider } from "./context/SettingsContext";
import { ToastProvider } from "react-native-toast-notifications";
import { AuthProvider } from "./context/AuthContext";
import { OrdersProvider } from "./context/OrdersContext";

import { HeadquartersProvider } from "./context/HeadquartersContext";

LogBox.ignoreLogs([
  "AsyncStorage has been extracted from react-native core and will be removed in a future release",
  "Setting a timer for a long period of time",
]);

export default function App() {
  const isLoadingComplete = useCachedResources();
  const systemColorScheme = useSystemColorScheme();

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <SafeAreaProvider>
        <SettingsProvider systemColorScheme={systemColorScheme}>
          <ToastProvider offset={36} placement="top" duration={3000}>
            <AuthProvider>
              <HeadquartersProvider>
                <OrdersProvider>
                  <Navigation />
                  <StatusBar />
                </OrdersProvider>
              </HeadquartersProvider>
            </AuthProvider>
          </ToastProvider>
        </SettingsProvider>
      </SafeAreaProvider>
    );
  }
}

import { StyleSheet, TouchableOpacity, Image, Platform } from "react-native";

import { Text, View } from "./Themed";

import { Order } from "../types";
import React, { useContext, useEffect, useState } from "react";
import { formatSeconds, formatTime, ORDER_STATES } from "../utils";
import useColorScheme from "../hooks/useColorScheme";
import Colors from "../constants/Colors";
import { HeadquartersContext } from "../context/HeadquartersContext";
import { MaterialIcons } from "@expo/vector-icons";

import { LISTEUX } from "../utils";

export default function OrderTrackingCard({
  order,
  showMessagesCallback,
  cancelOrderCallback,
}: {
  order: Order;
  showMessagesCallback: () => void;
  cancelOrderCallback: () => void;
}) {
  const colorScheme = useColorScheme();
  const { items } = useContext(HeadquartersContext);

  const [nextStepExpectedTime, setNextStepExpectedTime] = useState(0);

  const [secondsElapsed, setSecondsElapsed] = useState(360);

  const orderDate = new Date(order.datetime);

  // Update the date every 1 second
  useEffect(() => {
    setSecondsElapsed((new Date().getTime() - orderDate.getTime()) / 1000);
    const interval = setInterval(() => {
      setSecondsElapsed((new Date().getTime() - orderDate.getTime()) / 1000);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (order.nextStepExpectedTime) {
      setNextStepExpectedTime(
        (new Date(order.nextStepExpectedTime).getTime() - orderDate.getTime()) /
          1000
      );
    } else {
      setNextStepExpectedTime(360);
    }
  }, [order]);

  return (
    <View>
      {order.messages && (
        <TouchableOpacity
          style={[styles.button, { width: "100%", marginTop: -5 }]}
          onPress={() => {
            showMessagesCallback();
          }}
        >
          <MaterialIcons
            name="message"
            size={24}
            color="#ffffff"
            style={styles.buttonIcon}
          />
          <Text style={styles.buttonText}>
            {Object.entries(order.messages).reverse()[0]?.[1].datetime >=
            (order.lastSeenCustomer || "")
              ? "1 message non lu"
              : "Messagerie de commande"}
          </Text>
        </TouchableOpacity>
      )}
      <View
        style={[styles.header, { backgroundColor: Colors[colorScheme].card }]}
      >
        {/* Estimated arrival time */}
        <View style={styles.estimatedArrivalTime}>
          <View style={styles.estimatedArrivalTimeLine}>
            <Text style={styles.estimatedArrivalTimeTitle}>
              {ORDER_STATES[order.status].timeText}
            </Text>
            <Text style={styles.estimatedArrivalTimeText}>
              {formatTime(nextStepExpectedTime - secondsElapsed)} environ
            </Text>
          </View>
          <View style={styles.estimatedArrivalTimeIconContainer}>
            <Image
              style={styles.estimatedArrivalTimeIcon}
              source={
                ORDER_STATES[
                  order && order.status in ORDER_STATES
                    ? order.status
                    : "unknown"
                ].image
              }
            />
          </View>
        </View>
        <View style={styles.progressBarBackground}>
          <View
            style={[
              styles.progressBar,
              {
                backgroundColor:
                  ORDER_STATES[
                    order && order.status in ORDER_STATES
                      ? order.status
                      : "unknown"
                  ].imageColor,
                width:
                  ORDER_STATES[
                    order && order.status in ORDER_STATES
                      ? order.status
                      : "unknown"
                  ].progress + "%",
              },
            ]}
          />
        </View>
        <Text style={styles.description}>
          {
            ORDER_STATES[
              order && order.status in ORDER_STATES ? order.status : "unknown"
            ].description
          }
        </Text>
        {secondsElapsed <= 120 && order.status == "pending" && (
          <View style={{ backgroundColor: "transparent" }}>
            <Text style={styles.description}>
              Annulation encore possible pendant{" "}
              {formatSeconds(120 - secondsElapsed)}.
            </Text>
            <TouchableOpacity
              style={[
                styles.button,
                {
                  width: "100%",
                  marginTop: 10,
                  marginBottom: -2,
                  backgroundColor: "#c0392b",
                },
              ]}
              onPress={() => {
                cancelOrderCallback();
              }}
            >
              <MaterialIcons
                name="cancel"
                size={24}
                color="#ffffff"
                style={styles.buttonIcon}
              />
              <Text style={styles.buttonText}>Annuler la commande</Text>
            </TouchableOpacity>
          </View>
        )}
        {order.deliveryBy && (
          <View style={{ backgroundColor: "transparent" }}>
            <Text style={styles.deliveryByTitle}>
              {order.deliveryBy.length > 1
                ? "Vos livreurs :"
                : "Votre livreur :"}
            </Text>
            <View style={styles.deliveryByCards}>
              {order.deliveryBy.map((l) => (
                <View
                  style={[
                    styles.deliveryByCard,
                    { backgroundColor: Colors[colorScheme].background },
                  ]}
                  key={l}
                >
                  <Image
                    style={styles.deliveryByCardImage}
                    source={{
                      uri: `https://deliveirb.aboin.fr/assets/images/listeux/${l}.png`,
                    }}
                  />
                  <Text style={styles.deliveryByCardName}>{LISTEUX[l]}</Text>
                </View>
              ))}
            </View>
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  card: {
    flexDirection: "row",
    marginVertical: 5,
    paddingBottom: 5,
  },
  cardImage: {
    width: 100,
    height: 100,
    borderRadius: 10,
  },
  verticalContainer: {
    flex: 1,
    padding: 10,
    justifyContent: "space-between",
  },
  cardTitle: {
    fontSize: 18,
    fontWeight: "bold",
  },
  cardDescription: {
    fontSize: 15,
    color: "grey",
  },
  horizontalContainer: {
    flexDirection: "row",
    alignItems: "center",
  },

  header: {
    padding: 15,
    borderRadius: 20,
    marginBottom: 10,
  },
  estimatedArrivalTime: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "transparent",
  },
  estimatedArrivalTimeLine: {
    backgroundColor: "transparent",
  },
  estimatedArrivalTimeIconContainer: {
    padding: 8,
    backgroundColor: "white",
    borderRadius: 64,
    overflow: "hidden",
    marginLeft: 10,
  },
  estimatedArrivalTimeIcon: {
    width: 48,
    height: 48,
  },
  estimatedArrivalTimeTitle: {
    fontSize: 16,
  },
  estimatedArrivalTimeText: {
    fontWeight: "bold",
    fontSize: 24,
  },
  progressBarBackground: {
    marginVertical: 6,
    backgroundColor: "#ecf0f1",
    width: "100%",
    height: 6,
  },
  progressBar: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    width: "75%",
    backgroundColor: "#f39c12",
  },

  description: {
    fontSize: 14,
    color: "gray",
  },

  deliveryByTitle: {
    fontSize: 16,
    fontWeight: "bold",
    marginTop: 10,
  },
  deliveryByCards: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    backgroundColor: "transparent",
  },
  deliveryByCard: {
    marginHorizontal: 5,
    marginTop: 10,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 8,
    paddingVertical: 5,
    borderRadius: 10,
  },
  deliveryByCardImage: {
    width: 32,
    height: 32,
    marginRight: 6,
  },
  deliveryByCardName: {
    fontSize: 14,
  },

  button: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#23313F",
    marginBottom: 10,
    padding: 12,
    borderRadius: 8,
  },
  buttonIcon: {
    marginRight: 10,
  },
  buttonText: {
    fontSize: 18,
    color: "white",
    textAlign: "center",
  },
});

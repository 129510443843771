/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { MaterialIcons } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as React from "react";
import { Pressable, View } from "react-native";

import Colors from "../constants/Colors";
import ModalScreen from "../screens/ModalScreen";
import NotFoundScreen from "../screens/NotFoundScreen";
import HomeScreen from "../screens/HomeScreen";
import {
  RootStackParamList,
  RootTabParamList,
  RootTabScreenProps,
} from "../types";
import LinkingConfiguration from "./LinkingConfiguration";
import Constants from "expo-constants";
import LoginScreen from "../screens/LoginScreen";
import AuthScreen from "../screens/AuthScreen";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import AccountScreen from "../screens/AccountScreen";
import SettingsScreen from "../screens/SettingsScreen";
import { SettingsContext } from "../context/SettingsContext";
import LocationSelectorScreen from "../screens/LocationSelectorScreen";
import AddressChoiceScreen from "../screens/AddressChoiceScreen";
import AddressSearchScreen from "../screens/AddressSearchScreen";
import useColorScheme from "../hooks/useColorScheme";
import ShoppingCartScreen from "../screens/ShoppingCartScreen";
import OrdersScreen from "../screens/OrdersScreen";
import { OrdersContext } from "../context/OrdersContext";
import OrderDetailsScreen from "../screens/OrderDetailsScreen";
import OrderMessagesScreen from "../screens/OrderMessagesScreen";
import HeadquarterScreen from "../screens/HeadquarterScreen";
import { HeadquartersContext } from "../context/HeadquartersContext";
import OrderTrackingScreen from "../screens/OrderTrackingScreen";
import ListeuxChoiceScreen from "../screens/ListeuxChoiceScreen";
import PendingOrdersScreen from "../screens/PendingOrdersScreen";
import AcceptedOrdersScreen from "../screens/AcceptedOrdersScreen";
import OngoingOrdersScreen from "../screens/OngoingOrdersScreen";
import SpecialPriceChoiceScreen from "../screens/SpecialPriceChoiceScreen";
import HeadquarterManagementScreen from "../screens/HeadquarterManagementScreen";
import NewOrderScreen from "../screens/NewOrderScreen";

export default function Navigation() {
  const colorScheme = useColorScheme();

  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Root"
        component={BottomTabNavigator}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="NotFound"
        component={NotFoundScreen}
        options={{ title: "Oupsi !" }}
      />
      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen name="Modal" component={ModalScreen} />
      </Stack.Group>
      <Stack.Group screenOptions={{ presentation: "card" }}>
        <Stack.Screen
          name="Headquarter"
          component={HeadquarterScreen}
          options={() => ({ headerTitle: "", title: "" })}
        />
        <Stack.Screen
          name="OrderDetails"
          component={OrderDetailsScreen}
          options={{ title: "Détails de la commande" }}
        />
        <Stack.Screen
          name="ListeuxChoice"
          component={ListeuxChoiceScreen}
          options={{ title: "Choix des livreurs" }}
        />
        <Stack.Screen
          name="NewOrder"
          component={NewOrderScreen}
          options={{ title: "Nouvelle commande au téléphone" }}
        />
        <Stack.Screen
          name="SpecialPriceChoice"
          component={SpecialPriceChoiceScreen}
          options={{ title: "Choix du prix" }}
        />
        <Stack.Screen
          name="OrderTracking"
          component={OrderTrackingScreen}
          options={{ title: "Suivi de la commande" }}
        />
        <Stack.Screen
          name="OrderMessages"
          component={OrderMessagesScreen}
          options={{ title: "Messagerie de commande" }}
        />
        <Stack.Screen
          name="Auth"
          component={AuthScreen}
          options={{ title: "Authentification" }}
        />
        <Stack.Screen
          name="Settings"
          component={SettingsScreen}
          options={{ title: "Paramètres" }}
        />
        <Stack.Screen
          name="LocationSelector"
          component={LocationSelectorScreen}
          options={{ title: "Sélectionner un emplacement" }}
        />
        <Stack.Screen
          name="AddressChoice"
          component={AddressChoiceScreen}
          options={({ route }) => ({
            title:
              route.params.address == null
                ? "Renseigner une adresse"
                : "Confirmer l'adresse",
          })}
        />
        <Stack.Screen
          name="AddressSearch"
          component={AddressSearchScreen}
          options={{ title: "Résultats de recherche" }}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>();

function BottomTabNavigator() {
  const colorScheme = useColorScheme();
  const userData = useContext(AuthContext).userData;
  const itemCount = useContext(HeadquartersContext).itemCount;
  const settings = useContext(SettingsContext);
  const orders = useContext(OrdersContext);

  return (
    <BottomTab.Navigator
      initialRouteName="Home"
      screenOptions={{
        tabBarActiveTintColor: Colors[colorScheme].tint,
      }}
    >
      <BottomTab.Screen
        name="Home"
        component={HomeScreen}
        options={{
          title: "Accueil",
          headerTitle: "",
          tabBarIcon: ({ color }) => <TabBarIcon name="home" color={color} />,
          headerStyle: {
            height: Constants.statusBarHeight,
          },
        }}
      />
      {!(userData?.admin && settings.hideShoppingCartScreen) && (
      <BottomTab.Screen
        name="ShoppingCart"
        component={ShoppingCartScreen}
        options={{
          title: "Panier",
          tabBarBadge: userData && itemCount > 0 ? itemCount : undefined,
          tabBarBadgeStyle: {
            backgroundColor: "#f39c12",
            color: "#fff",
          },
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="shopping-cart" color={color} />
          ),
        }}
      />
      )}
      {!(userData?.admin && settings.hideMyOrdersScreen) && (
      <BottomTab.Screen
        name="Orders"
        component={OrdersScreen}
        options={{
          title: "Commandes",
          tabBarBadge: userData?.orders
            ? Object.entries(userData.orders).filter(
                (o) =>
                  o[1].status === "pending" ||
                  o[1].status === "accepted" ||
                  o[1].status === "ongoing"
              ).length > 0 ? Object.entries(userData.orders).filter(
                (o) =>
                  o[1].status === "pending" ||
                  o[1].status === "accepted" ||
                  o[1].status === "ongoing"
              ).length : undefined
            : undefined,
          tabBarBadgeStyle: {
            backgroundColor: "#f39c12",
            color: "#fff",
          },
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="receipt-long" color={color} />
          ),
        }}
      />
      )}
      {userData?.admin && settings.adminMode && (
        <BottomTab.Screen
          name="HeadquarterManagement"
          component={HeadquarterManagementScreen}
          options={{
            title: "Gestion QGs",
            headerTitle: "Gestion des QGs",
            tabBarBadge: 4,
            tabBarBadgeStyle: {
              backgroundColor: "#8e44ad",
              color: "#fff",
            },
            tabBarIcon: ({ color }) => (
              <TabBarIcon name="apartment" color={color} />
            ),
          }}
        />
      )}
      {userData?.admin && settings.adminMode && (
        <BottomTab.Screen
          name="OngoingOrders"
          component={OngoingOrdersScreen}
          options={{
            title: "À livrer",
            headerTitle: "Commandes à livrer",
            tabBarBadge: orders.orders.filter(
              (o) =>
                o.data.status === "ongoing" && o.data.deliveryBy?.includes(userData.username)
            ).length,
            tabBarBadgeStyle: {
              backgroundColor: "#e67e22",
              color: "#fff",
            },
            tabBarIcon: ({ color }) => (
              <TabBarIcon name="local-shipping" color={color} />
            ),
          }}
        />
      )}
      {userData?.admin && settings.adminMode && (
        <BottomTab.Screen
          name="AcceptedOrders"
          component={AcceptedOrdersScreen}
          options={{
            title: "À préparer",
            headerTitle: "Commandes à préparer",
            tabBarBadge: orders.orders.filter(
              (o) =>
              o.data.status === "accepted" && o.data.deliveryBy?.includes(userData.username)
            ).length,
            tabBarBadgeStyle: {
              backgroundColor: "#2ecc71",
              color: "#fff",
            },
            tabBarIcon: ({ color }) => (
              <TabBarIcon name="fastfood" color={color} />
            ),
          }}
        />
      )}
      {userData?.admin && settings.adminMode && (
        <BottomTab.Screen
          name="PendingOrders"
          component={PendingOrdersScreen}
          options={{
            title: "À valider",
            headerTitle: "Commandes à valider",
            tabBarBadge: orders.orders.filter(
              (o) =>
                o.data.status === "pending" &&
                (settings.selectedHeadquarter === -1 ||
                  o.data.headquarterId === settings.selectedHeadquarter)
            ).length,
            tabBarBadgeStyle: {
              backgroundColor: "#f1c40f",
              color: "#fff",
            },
            tabBarIcon: ({ color }) => (
              <TabBarIcon name="list-alt" color={color} />
            ),
          }}
        />
      )}
      <BottomTab.Screen
        name="Account"
        component={userData ? AccountScreen : LoginScreen}
        options={({ navigation }: RootTabScreenProps<"Account">) => ({
          title: userData ? "Compte" : "Connexion",
          tabBarIcon: ({ color }) => <TabBarIcon name="person" color={color} />,
          headerRight: () => (
            <Pressable
              onPress={() => navigation.navigate("Settings")}
              style={({ pressed }) => ({
                opacity: pressed ? 0.5 : 1,
                width: 48,
                height: 48,
                alignItems: "center",
                justifyContent: "center",
                marginRight: 4,
              })}
            >
              <MaterialIcons
                name="settings"
                size={25}
                color={Colors[colorScheme].text}
              />
            </Pressable>
          ),
        })}
      />
    </BottomTab.Navigator>
  );
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: {
  name: React.ComponentProps<typeof MaterialIcons>["name"];
  color: string;
}) {
  return <MaterialIcons size={30} style={{ marginBottom: -3 }} {...props} />;
}

import {
  Platform,
  StyleSheet,
  TouchableOpacity,
  Switch,
  ScrollView,
  Pressable,
} from "react-native";

import { Picker } from "@react-native-picker/picker";

import * as WebBrowser from "expo-web-browser";
import { Text, View } from "../components/Themed";
import { FontAwesome, MaterialIcons } from "@expo/vector-icons";
import React, { useContext, useEffect, useState } from "react";

import { AuthContext } from "../context/AuthContext";

import { SettingsContext } from "../context/SettingsContext";
import Colors from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme";
import Constants from "expo-constants";
import { nativeConfirm, nativeDialog } from "../utils";
import { RootStackParamList } from "../types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { signOut } from "../auth";

const SWITCH_COLORS = {
  true: {
    tackColor: "#c0e5c8",
    thumbColor: "#78e08f",
  },
  false: {
    tackColor: "lightgrey",
    thumbColor: "grey",
  },
};

const GROUPS: { [key: string]: { name: string; icon: string } } = {
  info: {
    name: "Informatique",
    icon: "laptop",
  },
  elec: {
    name: "Électronique",
    icon: "bolt",
  },
  matmeca: {
    name: "Matméca",
    icon: "cogs",
  },
  telecom: {
    name: "Télécomunication",
    icon: "phone",
  },
  rsi: {
    name: "Réseau et Systèmes d'Information",
    icon: "wifi",
  },
  see: {
    name: "Systèmes Électroniques Embarqués",
    icon: "microchip",
  },
  beta: {
    name: "Beta Testeur",
    icon: "bug",
  },
};

export default function SettingsScreen({
  navigation,
  route,
}: NativeStackScreenProps<RootStackParamList, "Settings">) {
  const colorScheme = useColorScheme();
  const { user, userData, deleteUserAccount } = useContext(AuthContext);
  const settings = useContext(SettingsContext);

  const [selectedTheme, setSelectedTheme] = useState<string>(
    settings.useSystemColorScheme
      ? "auto"
      : settings.darkMode
      ? "dark"
      : "light"
  );

  useEffect(() => {
    if (selectedTheme === "auto") {
      settings.setUseSystemColorScheme(true);
    } else {
      settings.setUseSystemColorScheme(false);
      settings.setDarkMode(selectedTheme === "dark");
    }
  }, [selectedTheme]);

  const [versionClickCount, setVersionClickCount] = useState(0);

  const incrementVerrsionClickCount = () => {
    setVersionClickCount(versionClickCount + 1);
  };

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () =>
        Platform.OS === "web" ? (
          <Pressable
            onPress={() => {
              navigation.goBack();
            }}
            style={({ pressed }) => ({
              opacity: pressed ? 0.5 : 1,
              width: 48,
              height: 48,
              alignItems: "center",
              justifyContent: "center",
              marginRight: 4,
            })}
          >
            <MaterialIcons
              name="arrow-back"
              size={25}
              color={Colors[colorScheme].text}
              style={{ marginLeft: 15 }}
            />
          </Pressable>
        ) : undefined,
    });
  }, [colorScheme]);

  return (
    <View style={styles.mainContainer}>
      <ScrollView
        contentContainerStyle={{ flexGrow: 1, justifyContent: "center" }}
      >
        <View
          style={[
            styles.container,
            { backgroundColor: Colors[colorScheme].card },
          ]}
        >
          {user && userData && (
            <View style={styles.settingsCategory}>
              <Text style={styles.settingsCategoryTitle}>
                Détails personnels
              </Text>
              <View
                style={[
                  styles.settingsCategoryContent,
                  { backgroundColor: Colors[colorScheme].container },
                ]}
              >
                <TouchableOpacity
                  style={[
                    styles.settingsCategoryContentItem,
                    { borderBottomColor: Colors[colorScheme].card },
                  ]}
                >
                  <FontAwesome
                    name="user"
                    size={24}
                    color={Colors[colorScheme].text}
                    style={styles.settingsIcon}
                  />
                  <Text style={styles.settingsCategoryContentItemText}>
                    {user.displayName}
                  </Text>
                </TouchableOpacity>
                {userData.group != "" && (
                  <TouchableOpacity
                    style={[
                      styles.settingsCategoryContentItem,
                      { borderBottomColor: Colors[colorScheme].card },
                    ]}
                    onPress={() => {}}
                  >
                    <FontAwesome
                      name={GROUPS[userData.group].icon as any}
                      size={24}
                      color={Colors[colorScheme].text}
                      style={styles.settingsIcon}
                    />
                    <Text style={styles.settingsCategoryContentItemText}>
                      {GROUPS[userData.group].name}
                    </Text>
                  </TouchableOpacity>
                )}
                {userData.title && (
                  <TouchableOpacity
                    style={[
                      styles.settingsCategoryContentItem,
                      { borderBottomColor: Colors[colorScheme].card },
                    ]}
                    onPress={() => {}}
                  >
                    <FontAwesome
                      name="moon-o"
                      size={24}
                      color={Colors[colorScheme].text}
                      style={styles.settingsIcon}
                    />
                    <Text style={styles.settingsCategoryContentItemText}>
                      UNIV'EIRB • {userData.title}
                    </Text>
                  </TouchableOpacity>
                )}
                {userData.admin && (
                  <View
                    style={[
                      styles.settingsCategoryContentItem,
                      {
                        backgroundColor: Colors[colorScheme].container,
                        borderBottomColor: Colors[colorScheme].card,
                      },
                    ]}
                  >
                    <FontAwesome
                      name="shopping-basket"
                      size={24}
                      color={Colors[colorScheme].text}
                      style={styles.settingsIcon}
                    />
                    <Text style={styles.settingsCategoryContentItemText}>
                      Gestion des commandes
                    </Text>
                    <Switch
                      trackColor={{
                        true: SWITCH_COLORS.true.tackColor,
                        false: SWITCH_COLORS.false.tackColor,
                      }}
                      thumbColor={
                        settings.adminMode
                          ? SWITCH_COLORS.true.thumbColor
                          : SWITCH_COLORS.false.thumbColor
                      }
                      onValueChange={(value) => {
                        settings.setAdminMode(value);
                      }}
                      value={settings.adminMode}
                      style={styles.settingsSwitch}
                    />
                  </View>
                )}
                {userData.admin && settings.adminMode && (
                  <View
                    style={[
                      styles.settingsCategoryContentItem,
                      {
                        backgroundColor: Colors[colorScheme].container,
                        borderBottomColor: Colors[colorScheme].card,
                      },
                    ]}
                  >
                    <FontAwesome
                      name={
                        settings.hideShoppingCartScreen ? "eye-slash" : "eye"
                      }
                      size={24}
                      color={Colors[colorScheme].text}
                      style={styles.settingsIcon}
                    />
                    <Text style={styles.settingsCategoryContentItemText}>
                      Masquer l'onglet "Panier"
                    </Text>
                    <Switch
                      trackColor={{
                        true: SWITCH_COLORS.true.tackColor,
                        false: SWITCH_COLORS.false.tackColor,
                      }}
                      thumbColor={
                        settings.hideShoppingCartScreen
                          ? SWITCH_COLORS.true.thumbColor
                          : SWITCH_COLORS.false.thumbColor
                      }
                      onValueChange={(value) => {
                        settings.setHideShoppingCartScreen(value);
                      }}
                      value={settings.hideShoppingCartScreen}
                      style={styles.settingsSwitch}
                    />
                  </View>
                )}
                {userData.admin && settings.adminMode && (
                  <View
                    style={[
                      styles.settingsCategoryContentItem,
                      {
                        backgroundColor: Colors[colorScheme].container,
                        borderBottomColor: Colors[colorScheme].card,
                      },
                    ]}
                  >
                    <FontAwesome
                      name={
                        settings.hideMyOrdersScreen ? "eye-slash" : "eye"
                      }
                      size={24}
                      color={Colors[colorScheme].text}
                      style={styles.settingsIcon}
                    />
                    <Text style={styles.settingsCategoryContentItemText}>
                      Masquer l'onglet "Commandes"
                    </Text>
                    <Switch
                      trackColor={{
                        true: SWITCH_COLORS.true.tackColor,
                        false: SWITCH_COLORS.false.tackColor,
                      }}
                      thumbColor={
                        settings.hideMyOrdersScreen
                          ? SWITCH_COLORS.true.thumbColor
                          : SWITCH_COLORS.false.thumbColor
                      }
                      onValueChange={(value) => {
                        settings.setHideMyOrdersScreen(value);
                      }}
                      value={settings.hideMyOrdersScreen}
                      style={styles.settingsSwitch}
                    />
                  </View>
                )}
                <TouchableOpacity
                  style={[
                    styles.settingsCategoryContentItem,
                    { borderBottomColor: Colors[colorScheme].card },
                  ]}
                  onPress={() => {}}
                >
                  <FontAwesome
                    name="envelope"
                    size={24}
                    color={Colors[colorScheme].text}
                    style={styles.settingsIcon}
                  />
                  <Text style={styles.settingsCategoryContentItemText}>
                    {user.email}
                  </Text>
                </TouchableOpacity>
                {userData.phoneNumber && (
                  <TouchableOpacity
                    style={[
                      styles.settingsCategoryContentItem,
                      { borderBottomColor: Colors[colorScheme].card },
                    ]}
                    onPress={() => {
                      nativeDialog(
                        "Modification du téléphone",
                        "Afin de modifier votre numéro de téléphone, veuillez cliquer sur celui-ci depuis l'écran précédent"
                      );
                    }}
                  >
                    <FontAwesome
                      name="phone"
                      size={24}
                      color={Colors[colorScheme].text}
                      style={styles.settingsIcon}
                    />
                    <Text style={styles.settingsCategoryContentItemText}>
                      {userData.phoneNumber}
                    </Text>
                  </TouchableOpacity>
                )}
                {userData.address && (
                  <TouchableOpacity
                    style={[
                      styles.settingsCategoryContentItem,
                      { borderBottomColor: Colors[colorScheme].card },
                    ]}
                    onPress={() => {
                      nativeDialog(
                        "Modification de l'adresse",
                        "Afin de modifier votre adresse, veuillez cliquer sur celle-ci depuis l'écran précédent"
                      );
                    }}
                  >
                    <FontAwesome
                      name="map-marker"
                      size={24}
                      color={Colors[colorScheme].text}
                      style={styles.settingsIcon}
                    />
                    <Text
                      style={styles.settingsCategoryContentItemText}
                      ellipsizeMode="tail"
                      numberOfLines={1}
                    >
                      {userData.address.formattedAddress}
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
            </View>
          )}
          <View style={styles.settingsCategory}>
            <Text style={styles.settingsCategoryTitle}>Affichage</Text>
            <View
              style={[
                styles.settingsCategoryContent,
                { backgroundColor: Colors[colorScheme].container },
              ]}
            >
              <View
                style={[
                  styles.settingsCategoryContentItem,
                  {
                    backgroundColor: Colors[colorScheme].container,
                    borderBottomColor: Colors[colorScheme].card,
                  },
                ]}
              >
                <MaterialIcons
                  name={
                    settings.useSystemColorScheme
                      ? "settings-brightness"
                      : settings.darkMode
                      ? "nightlight-round"
                      : "wb-sunny"
                  }
                  size={24}
                  color={Colors[colorScheme].text}
                  style={styles.settingsIcon}
                />
                <Text style={styles.settingsCategoryContentItemText}>
                  Thème d'affichage :
                </Text>
                <Picker
                  style={{
                    flex: 1,
                    height:
                      Platform.OS === "ios"
                        ? 80
                        : Platform.OS === "android"
                        ? 0
                        : 40,
                    top: Platform.OS === "ios" ? -65 : 0,
                    marginVertical: -16,
                    color: Colors[colorScheme].text,
                    backgroundColor: Colors[colorScheme].container,
                  }}
                  selectedValue={selectedTheme}
                  onValueChange={(itemValue: string, itemIndex: number) =>
                    setSelectedTheme(itemValue)
                  }
                  mode="dropdown"
                  dropdownIconColor={Colors[colorScheme].text}
                  itemStyle={{
                    color: "gray",
                    fontSize: 16,
                  }}
                >
                  <Picker.Item label="Auto" value="auto" />
                  <Picker.Item label="Clair" value="light" />
                  <Picker.Item label="Sombre" value="dark" />
                </Picker>
              </View>
            </View>
          </View>
          <View style={styles.settingsCategory}>
            <Text style={styles.settingsCategoryTitle}>Aide</Text>
            <View
              style={[
                styles.settingsCategoryContent,
                { backgroundColor: Colors[colorScheme].container },
              ]}
            >
              <TouchableOpacity
                style={[
                  styles.settingsCategoryContentItem,
                  { borderBottomColor: Colors[colorScheme].card },
                ]}
                onPress={() => {
                  nativeDialog(
                    "À propos",
                    "Application développée avec ⌛ et 💙 par Alexandre Boin (aka Mr. Robot)\n\n© 2022 UNIV'EIRB. Tous droits réservés."
                  );
                }}
              >
                <FontAwesome
                  name="info-circle"
                  size={24}
                  color={Colors[colorScheme].text}
                  style={styles.settingsIcon}
                />
                <Text style={styles.settingsCategoryContentItemText}>
                  À propos de Deliv'Eirb
                </Text>
                <FontAwesome
                  name="angle-right"
                  size={24}
                  color={Colors[colorScheme].text}
                  style={styles.settingsIconRight}
                />
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  styles.settingsCategoryContentItem,
                  { borderBottomColor: Colors[colorScheme].card },
                ]}
                onPress={() => {
                  WebBrowser.openBrowserAsync(
                    "https://deliveirb.aboin.fr/assets/contact.html"
                  );
                }}
              >
                <FontAwesome
                  name="question-circle"
                  size={24}
                  color={Colors[colorScheme].text}
                  style={styles.settingsIcon}
                />
                <Text style={styles.settingsCategoryContentItemText}>
                  Support
                </Text>
                <FontAwesome
                  name="angle-right"
                  size={24}
                  color={Colors[colorScheme].text}
                  style={styles.settingsIconRight}
                />
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  styles.settingsCategoryContentItem,
                  { borderBottomColor: Colors[colorScheme].card },
                ]}
                onPress={() => {
                  WebBrowser.openBrowserAsync(
                    "https://deliveirb.aboin.fr/assets/terms.html"
                  );
                }}
              >
                <FontAwesome
                  name="file-text"
                  size={24}
                  color={Colors[colorScheme].text}
                  style={styles.settingsIcon}
                />
                <Text style={styles.settingsCategoryContentItemText}>
                  Termes de service
                </Text>
                <FontAwesome
                  name="angle-right"
                  size={24}
                  color={Colors[colorScheme].text}
                  style={styles.settingsIconRight}
                />
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  styles.settingsCategoryContentItem,
                  { borderBottomColor: Colors[colorScheme].card },
                ]}
                onPress={() => {
                  WebBrowser.openBrowserAsync(
                    "https://deliveirb.aboin.fr/assets/privacy.html"
                  );
                }}
              >
                <FontAwesome
                  name="lock"
                  size={24}
                  color={Colors[colorScheme].text}
                  style={styles.settingsIcon}
                />
                <Text style={styles.settingsCategoryContentItemText}>
                  Politique de confidentialité
                </Text>
                <FontAwesome
                  name="angle-right"
                  size={24}
                  color={Colors[colorScheme].text}
                  style={styles.settingsIconRight}
                />
              </TouchableOpacity>
            </View>
          </View>

          <TouchableOpacity
            style={styles.versionButton}
            onPress={() => {
              incrementVerrsionClickCount();
              if (versionClickCount == 6) {
                setVersionClickCount(0);
                WebBrowser.openBrowserAsync(
                  "https://deliveirb.aboin.fr/assets/developer.jpg"
                );
              }
            }}
          >
            <Text style={styles.versionText}>
              V.{Constants.manifest?.version} {Platform.OS}
            </Text>
          </TouchableOpacity>
          {user && userData && (
            <View style={styles.buttonsContainer}>
              <TouchableOpacity
                style={styles.deleteButton}
                onPress={() => {
                  nativeConfirm(
                    "Supprimer toutes les données",
                    "Voulez-vous supprimer toutes les données de votre compte ?",
                    () => {
                      deleteUserAccount()
                        .then(() => {
                          nativeDialog(
                            "Suppression réussie",
                            "Votre compte a été supprimé. Vous avez été déconnecté."
                          );
                          signOut().then(() => {
                            navigation.goBack();
                          });
                        })
                        .catch((error) => {
                          if (
                            error.code == "ACCOUNT/DELETE-WITH-PENDING-ORDERS"
                          ) {
                            nativeDialog(
                              "Erreur",
                              "Il n'est pas possible de supprimer votre compte lorsque vous avez une commande en cours. Veuillez attendre que celle-ci ait été livrée."
                            );
                          } else {
                            nativeDialog(
                              "Erreur",
                              "Une erreur est survenue. Veuillez réessayer plus tard."
                            );
                          }
                        });
                    },
                    "Supprimer"
                  );
                }}
              >
                <Text style={styles.deleteButtonText}>
                  Supprimer mon compte
                </Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  container: {
    flex: 1,
    padding: 16,
  },
  settingsCategory: {
    marginBottom: 20,
    backgroundColor: "transparent",
  },
  settingsCategoryTitle: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 10,
    textTransform: "uppercase",
  },
  settingsCategoryContent: {
    borderRadius: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.5,
    shadowRadius: 4,
    elevation: 2,
    overflow: "hidden",
  },
  settingsCategoryContentItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 14,
    paddingVertical: 18,
    borderBottomWidth: 1,
  },
  settingsCategoryContentItemText: {
    fontSize: 16,
    marginLeft: 6,
    flex: 1,
    marginRight: 4,
  },
  settingsIcon: {
    width: 28,
    height: 24,
    marginHorizontal: 6,
    textAlign: "center",
  },
  settingsSwitch: {
    height: 24,
  },
  settingsIconRight: {
    width: 24,
    height: 24,
  },
  versionButton: {
    padding: 4,
    paddingBottom: 20,
  },
  versionText: {
    fontSize: 12,
    color: "grey",
    textAlign: "center",
  },
  buttonsContainer: {
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  deleteButton: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: "#c0392b",
    borderRadius: 6,
  },
  deleteButtonText: {
    fontSize: 16,
    color: "#fff",
  },
});

import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { auth, db } from "../firebase";
import {
  ref,
  onValue,
  set,
  off,
  get,
  push,
  DatabaseReference,
} from "firebase/database";

import { Order, OrderData, UserProfile } from "../types";
import { ColorSchemeName } from "react-native";
import { AuthContext } from "./AuthContext";

interface UserList {
  [username: string]: UserProfile;
}

export interface OrdersContextProps {
  rawOrders: UserList;
  orders: OrderData[];
}

export const OrdersContext = createContext<OrdersContextProps>(
  {} as OrdersContextProps
);

export const OrdersProvider: React.FC = ({ children }) => {
  const [ordersRef, setOrdersRef] = useState<DatabaseReference>();
  const [ordersLoaded, setOrdersLoaded] = useState(false);
  const [rawOrders, setRawOrders] = useState<UserList>({});
  const [orders, setOrders] = useState<OrderData[]>([]);

  const { user, userData } = useContext(AuthContext);

  // Function to load orders from realtime database (bind listener)
  const loadOrders = (): DatabaseReference => {
    const ordersRef = ref(db, "users");
    setOrdersRef(ordersRef);
    onValue(ordersRef, (snapshot) => {
      setOrdersLoaded(true);
      const ordersData = snapshot.val();
      setRawOrders(ordersData);
      const orders: OrderData[] = [];
      Object.keys(ordersData).forEach((username) => {
        const userOrders = ordersData[username].orders;
        Object.entries((userOrders as { [key: string]: Order }) || []).forEach(
          ([orderId, order]) => {
            orders.push({
              orderId,
              data: order,
              user: {
                username,
                displayName: ordersData[username].displayName,
                phoneNumber: ordersData[username].phoneNumber,
              },
            });
          }
        );
      });
      setOrders(
        orders.sort((a, b) => b.data.datetime.localeCompare(a.data.datetime))
      );
    });
    return ordersRef;
  };

  useEffect(() => {
    if (user != null && userData != null) {
      const ordersRef = loadOrders();
      return () => {
        off(ordersRef);
      };
    } else {
      setOrdersLoaded(false);
      setOrders([]);
      return;
    }
  }, [user, userData]);

  return (
    <OrdersContext.Provider
      value={{
        rawOrders,
        orders,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};

import { MaterialIcons } from "@expo/vector-icons";
import { StyleSheet, TouchableOpacity, Image } from "react-native";

import { Text, View } from "./Themed";
import { Headquarter } from "../types";

export default function HeadquarterCard({
  headquarter,
  clickCallback,
  minDeliveryTime,
  maxDeliveryTime,
  distance,
  covered,
}: {
  headquarter: Headquarter;
  clickCallback: () => void;
  minDeliveryTime?: string | number;
  maxDeliveryTime?: string | number;
  distance?: string | number;
  covered?: boolean;
}) {
  return (
    <TouchableOpacity
      key={headquarter.id}
      style={styles.card}
      onPress={clickCallback}
    >
      <Image
        style={[styles.cardImage, { opacity: covered ? 1 : 0.6 }]}
        source={{
          uri: headquarter.image + '?1',
        }}
      />
      <View style={[styles.horizontalContainer, {opacity: covered ? 1 : 0.6}]}>
        <Text style={styles.cardTitle}>{headquarter.name}</Text>
        {headquarter.opened ? (
          <View style={styles.cardRight}>
            <Text style={styles.cardOpenHours}>Ouvert</Text>
            <MaterialIcons
              size={16}
              style={{ paddingTop: 2 }}
              name="circle"
              color="#2ecc71"
            />
          </View>
        ) : (
          <View style={styles.cardRight}>
            <Text style={styles.cardOpenHours}>Fermé</Text>
            <MaterialIcons
              size={16}
              style={{ paddingTop: 2 }}
              name="circle"
              color="#e74c3c"
            />
          </View>
        )}
      </View>
      {minDeliveryTime && maxDeliveryTime && distance && (
        <View style={[styles.horizontalContainer, {opacity: covered ? 1 : 0.6}]}>
          <MaterialIcons
            size={16}
            style={{ paddingTop: 2 }}
            name="schedule"
            color="#2ecc71"
          />
          <Text style={styles.cardDeliveryTime}>
            {minDeliveryTime}-{maxDeliveryTime} ·{" "}
          </Text>
          <MaterialIcons
            size={16}
            name={headquarter.transport as any}
            color="#888"
          />
          <Text style={styles.cardDeliveryTime}>{distance} km {covered ? "" : "(Non desservi)"}</Text>
        </View>
      )}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  horizontalContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    backgroundColor: "transparent",
  },
  card: {
    overflow: "hidden",
    width: "100%",
    marginVertical: 6,
  },
  cardTitle: {
    fontSize: 18,
    fontWeight: "bold",
  },
  cardDeliveryTime: {
    fontSize: 14,
    color: "grey",
    paddingLeft: 5,
  },
  cardRight: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    flex: 1,
    backgroundColor: "transparent",
  },
  cardOpenHours: {
    fontSize: 14,
    color: "grey",
    paddingRight: 5,
  },
  cardImage: {
    width: "100%",
    height: 200,
    borderRadius: 12,
    marginBottom: 6,
  },
});

import { StyleSheet, TouchableOpacity, Image, Platform } from "react-native";

import { Text } from "./Themed";

import Colors from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme";

export default function CategoryCard({
  name,
  image,
  clickCallback,
}: {
  name: string;
  image: string;
  clickCallback: () => void;
}) {
  const colorScheme = useColorScheme();

  return (
    <TouchableOpacity
      style={[
        styles.categoryCard,
        { backgroundColor: Colors[colorScheme].card },
      ]}
      onPress={clickCallback}
    >
      <Image style={styles.categoryCardImage} source={{ uri: image }} />
      <Text style={styles.categoryCardTitle}>{name}</Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  categoryCard: {
    overflow: "hidden",
    borderRadius: 4,
    paddingHorizontal: 10,
    paddingVertical: 6,
    marginRight: 6,
  },
  categoryCardImage: {
    width: 48,
    height: 48,
    alignSelf: "center",
    marginBottom: 4,
  },
  categoryCardTitle: {
    fontSize: 12,
    alignSelf: "center",
  },
});

import {
  StyleSheet,
  ScrollView,
  SafeAreaView,
  Image,
  Switch,
  Pressable,
  TouchableOpacity,
} from "react-native";

import { MaterialIcons } from "@expo/vector-icons";

import { Text, View } from "../components/Themed";

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "../types";

import React, { useContext, useEffect, useState } from "react";
import useColorScheme from "../hooks/useColorScheme";
import Colors from "../constants/Colors";
import { LISTEUX_STATES, nativeConfirm } from "../utils";
import { AuthContext } from "../context/AuthContext";
import { useToast } from "react-native-toast-notifications";
import { HeadquartersContext } from "../context/HeadquartersContext";

export default function ListeuxChoiceScreen({
  navigation,
  route,
}: NativeStackScreenProps<RootStackParamList, "ListeuxChoice">) {
  const colorScheme = useColorScheme();
  const toast = useToast();
  const { adminConfig, assignListeux } = useContext(HeadquartersContext);
  const { userData } = useContext(AuthContext);

  const orderId = route.params.id;
  const username = route.params.username;

  const [selectedListeux, setSelectedListeux] = useState<{
    [key: string]: boolean;
  }>({});

  const [estimatedPreparationTime, setEstimatedPreparationTime] = useState(0);

  const confirmSelectedListeux = () => {
    const selectedListeuxIds = Object.entries(selectedListeux)
      .filter(([, value]) => value)
      .map(([key]) => key);
    nativeConfirm(
      "Assigner à des listeux",
      `Êtes-vous sûr(e) de vouloir assigner cette commande à ce(s) listeux ?\n\n${selectedListeuxIds
        .map((l) => adminConfig.listeux[l].displayName)
        .join(" et ")}`,
      () => {
        assignListeux(
          username,
          orderId,
          selectedListeuxIds,
          estimatedPreparationTime
        )
          .then((message) => {
            toast.show(message, {
              type: "success",
            });
            navigation.navigate("AcceptedOrders");
          })
          .catch((message) => {
            toast.show(message, {
              type: "danger",
            });
          });
      }
    );
  };

  useEffect(() => {
    const selectedListeuxIds: { [key: string]: boolean } = {};
    Object.keys(adminConfig.listeux).forEach((key) => {
      selectedListeuxIds[key] = false;
    });
    if (userData) {
      selectedListeuxIds[userData.username] = true;
    }
    setSelectedListeux(selectedListeuxIds);
  }, []);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Pressable
          onPress={() => {
            confirmSelectedListeux();
          }}
          style={({ pressed }) => ({
            opacity: pressed ? 0.5 : 1,
            width: 48,
            height: 48,
            alignItems: "center",
            justifyContent: "center",
            marginRight: 4,
          })}
        >
          <MaterialIcons
            name="check"
            size={25}
            color={Colors[colorScheme].text}
          />
        </Pressable>
      ),
    });
  }, [selectedListeux, estimatedPreparationTime]);

  const toggleListeux = (listeuxId: string) => {
    setSelectedListeux({
      ...selectedListeux,
      [listeuxId]: !selectedListeux[listeuxId],
    });
  };

  return (
    <SafeAreaView style={styles.safeAreaView}>
      <View style={styles.container}>
        {adminConfig.listeux && (
          <ScrollView style={{ width: "100%" }}>
            <View style={styles.listeuxList}>
              {/* Buttons to set estimated preparation time */}
              <Text style={styles.estimatedPreparationTimeTitle}>
                Temps de préparation estimé
              </Text>
              <View style={styles.estimatedPreparationTimeButtons}>
                <TouchableOpacity
                  onPress={() => {
                    if (estimatedPreparationTime > 0) {
                      setEstimatedPreparationTime(estimatedPreparationTime - 1);
                    }
                  }}
                  style={styles.estimatedPreparationTimeButton}
                >
                  <MaterialIcons name="remove" size={25} color="white" />
                </TouchableOpacity>
                <Text style={styles.estimatedPreparationTimeText}>
                  {estimatedPreparationTime} minute
                  {estimatedPreparationTime > 1 && "s"}
                </Text>
                <TouchableOpacity
                  onPress={() => {
                    setEstimatedPreparationTime(estimatedPreparationTime + 1);
                  }}
                  style={styles.estimatedPreparationTimeButton}
                >
                  <MaterialIcons name="add" size={25} color="white" />
                </TouchableOpacity>
              </View>
              {Object.entries(adminConfig.listeux).map(([username, l], i) => (
                <View
                  style={[
                    styles.itemCard,
                    { backgroundColor: Colors[colorScheme].card },
                  ]}
                  key={l.username}
                >
                  <View style={styles.itemCardHeader}>
                    <View style={styles.status}>
                      <Switch
                        value={selectedListeux[username]}
                        onValueChange={() => toggleListeux(username)}
                        style={styles.switch}
                      />
                      <Image
                        source={{
                          uri: `https://deliveirb.aboin.fr/assets/images/listeux/${l.username}.png`,
                        }}
                        style={{ width: 32, height: 32, marginRight: 8 }}
                      />
                      <Text style={styles.itemCardText}>{l.displayName}</Text>
                    </View>
                    <View style={styles.status}>
                      <MaterialIcons
                        name={LISTEUX_STATES[l.status].icon as any}
                        size={25}
                        style={{
                          marginRight: 6,
                        }}
                        color={LISTEUX_STATES[l.status].color}
                      />
                      <Text style={styles.itemCardValue}>
                        {LISTEUX_STATES[l.status].name}
                      </Text>
                    </View>
                  </View>
                </View>
              ))}
            </View>
          </ScrollView>
        )}
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
  },
  listeuxList: {
    padding: 16,
  },

  itemCard: {
    borderRadius: 12,
    paddingVertical: 10,
    paddingHorizontal: 16,
    marginBottom: 10,
    flex: 1,
  },
  itemCardHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent",
  },
  itemCardText: {
    fontSize: 14,
    fontWeight: "bold",
  },
  itemCardValue: {
    fontSize: 14,
    fontWeight: "bold",
    color: "grey",
  },

  status: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "transparent",
  },
  switch: {
    marginRight: 8,
  },

  estimatedPreparationTimeTitle: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 8,
    textAlign: "center",
  },
  estimatedPreparationTimeText: {
    fontSize: 16,
    marginHorizontal: 8,
  },
  estimatedPreparationTimeButtons: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
  },
  estimatedPreparationTimeButton: {
    width: 48,
    height: 48,
    borderRadius: 24,
    backgroundColor: "grey",
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: 4,
  },
});

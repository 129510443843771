import {
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Pressable,
  TextInput,
  KeyboardAvoidingView,
  Platform,
} from "react-native";

import { MaterialIcons } from "@expo/vector-icons";

import { Text, View } from "../components/Themed";

import {
  RootTabScreenProps,
  FoodItem,
  Restaurant,
  Headquarter,
} from "../types";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import useColorScheme from "../hooks/useColorScheme";
import Colors from "../constants/Colors";
import { formatTime, nativeConfirm, nativeDialog } from "../utils";
import { useToast } from "react-native-toast-notifications";

import EmptyCartPicture from "../assets/images/empty-cart";
import { HeadquartersContext } from "../context/HeadquartersContext";
import ItemCardList from "../components/ItemCardList";

export default function ShoppingCartScreen({
  navigation,
}: RootTabScreenProps<"ShoppingCart">) {
  const toast = useToast();
  const colorScheme = useColorScheme();
  const { user, userData } = useContext(AuthContext);

  const {
    headquarters,
    items,
    shoppingCart,
    itemCount,
    totalPrice,
    preparationTime,
    deleteShoppingCart,
    setShoppingCartComment,
    convertShoppingCartToOrder,
  } = useContext(HeadquartersContext);

  const [headquarter, setHeadquarter] = useState<Headquarter>();

  const [comment, setComment] = useState("");

  useEffect(() => {
    if (shoppingCart) {
      const headquarter = Object.entries(headquarters).find(
        (h) => h[0] === shoppingCart.headquarterId.toString()
      )?.[1];
      if (headquarter) {
        setHeadquarter(headquarter);
        setComment(shoppingCart.comment || "");
        if (itemCount > 0) {
          navigation.setOptions({
            headerRight: () => (
              <Pressable
                onPress={() => {
                  nativeConfirm(
                    "Suppression du panier",
                    "Voulez-vous vraiment supprimer tout le contenu de votre panier ?",
                    () => {
                      deleteShoppingCart().then((message) => {
                        toast.show(message, {
                          type: "success",
                        });
                      });
                    },
                    "Oui, supprimer"
                  );
                }}
                style={({ pressed }) => ({
                  opacity: pressed ? 0.5 : 1,
                  width: 48,
                  height: 48,
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: 4,
                })}
              >
                <MaterialIcons
                  name="delete"
                  size={25}
                  color={Colors[colorScheme].text}
                />
              </Pressable>
            ),
          });
        }
      }
    } else {
      navigation.setOptions({
        headerRight: undefined,
      });
      setHeadquarter(undefined);
    }
  }, [headquarters, shoppingCart, itemCount]);

  const validateOrder = () => {
    nativeConfirm(
      "Validation de la commande",
      "Voulez-vous vraiment valider votre commande ?\n\nEn validant votre commande, vous certifiez que l'adresse de livraison renseignée est correcte et que vous procéderez à son paiement si-nécessaire une fois celle-ci livrée.\n\nUne fois votre commande validée, vous bénéficierez d'un délai d'annulation de 2 minutes avant que la commande soit traitée par notre équipe.\n\nTout abus du système de commande et de livraison (commande passée mais non payée, livraison à une fausse adresse...) sera sanctionné par une restriction d'accès à l'application.",
      () => {
        convertShoppingCartToOrder({ comment })
          .then((message) => {
            navigation.navigate("Orders");
            toast.show(message, {
              type: "success",
            });
            deleteShoppingCart();
          })
          .catch((error) => {
            toast.show(error, {
              type: "danger",
            });
          });
      },
      "Oui, valider"
    );
  };

  return user && headquarter && shoppingCart ? (
    <View style={styles.container}>
      <View
        style={{
          flex: 1,
          width: "100%",
        }}
      >
        <View
          style={{
            flex: 1,
          }}
        >
          <KeyboardAvoidingView
            behavior={Platform.OS == "ios" ? "padding" : undefined}
            style={{ flex: 1 }}
          >
            <ScrollView style={styles.scrollView}>
              <View
                style={[
                  styles.background,
                  { backgroundColor: headquarter.color },
                ]}
              >
                <Text style={styles.title}>{headquarter.name}</Text>
              </View>
              <View style={styles.orderSummary}>
                <ItemCardList
                  items={Object.fromEntries(
                    Object.entries(items).filter(([itemId, item]) =>
                      Object.keys(shoppingCart.items).includes(itemId)
                    )
                  )}
                  headquarterId={shoppingCart.headquarterId}
                  headquarter={headquarter}
                  itemList={shoppingCart.items}
                  showAddButton={true}
                  showRemoveButton={true}
                  deleteButton={true}
                  hideAvailableQuantity={true}
                  title="Articles"
                />
                <View
                  style={[
                    styles.itemCard,
                    { backgroundColor: Colors[colorScheme].card },
                  ]}
                >
                  <View style={styles.itemCardHeader}>
                    <Text style={styles.itemCardText}>Total</Text>
                    <Text style={styles.itemCardValue}>
                      {totalPrice.toFixed(2)} €
                    </Text>
                  </View>
                </View>
                <View
                  style={[
                    styles.itemCard,
                    { backgroundColor: Colors[colorScheme].card },
                  ]}
                >
                  <View style={styles.itemCardHeader}>
                    <Text style={styles.itemCardText}>Commentaire</Text>
                    <Text style={styles.itemCardValue}>
                      {comment.length}/280
                    </Text>
                  </View>
                  <TextInput
                    style={[
                      styles.commentInput,
                      {
                        color: Colors[colorScheme].text,
                        textAlignVertical: "top",
                      },
                    ]}
                    placeholder="Instructions pour le livreur, demandes spéciales..."
                    placeholderTextColor="grey"
                    multiline={true}
                    numberOfLines={3}
                    maxLength={280}
                    onChangeText={(text) => {
                      setComment(text);
                    }}
                    value={comment}
                    onBlur={() => {
                      setShoppingCartComment(comment);
                    }}
                    onEndEditing={() => {
                      setShoppingCartComment(comment);
                    }}
                  />
                </View>
                <View style={styles.deliveryDetails}>
                  <Text style={styles.deliveryDetailsText}>
                    Adresse de livraison
                  </Text>
                  <Text style={styles.deliveryDetailsValue}>
                    {userData?.address?.formattedAddress || "Indéterminée"}
                  </Text>
                  {preparationTime > 0 && (
                    <View>
                      <Text style={styles.deliveryDetailsText}>
                        Temps de préparation estimé
                      </Text>
                      <Text style={styles.deliveryDetailsValue}>
                        {formatTime(preparationTime)}
                      </Text>
                    </View>
                  )}
                  {userData?.travelDetails && (
                    <View>
                      <Text style={styles.deliveryDetailsText}>
                        Distance de livraison
                      </Text>
                      <Text style={styles.deliveryDetailsValue}>
                        environ{" "}
                        {(
                          userData.travelDetails[shoppingCart.headquarterId]
                            .distance / 1000
                        ).toFixed(1)}{" "}
                        km
                      </Text>
                      <Text style={styles.deliveryDetailsText}>
                        Temps de livraison
                      </Text>
                      <Text style={styles.deliveryDetailsValue}>
                        entre{" "}
                        {formatTime(
                          userData.travelDetails[shoppingCart.headquarterId]
                            .duration,
                          true
                        )}{" "}
                        et{" "}
                        {formatTime(
                          userData.travelDetails[shoppingCart.headquarterId]
                            .duration * 1.5
                        )}
                      </Text>
                    </View>
                  )}
                </View>
              </View>
            </ScrollView>
          </KeyboardAvoidingView>
        </View>
      </View>
      <TouchableOpacity style={styles.checkoutButton} onPress={validateOrder}>
        <MaterialIcons
          name="check-circle-outline"
          size={24}
          color="#ffffff"
          style={styles.checkoutButtonIcon}
        />
        <Text style={styles.checkoutButtonText}>Valider la commande</Text>
      </TouchableOpacity>
    </View>
  ) : (
    <View style={styles.container}>
      <View style={styles.emptyCart}>
        <EmptyCartPicture style={{ marginVertical: 16 }} />
        <Text style={styles.emptyCartText}>Votre panier est vide</Text>
        <Text style={styles.emptyCartDescription}>
          Ajoutez des articles à votre panier et faites vous livrer chez vous
          par nos meilleurs livreurs.
        </Text>
        <TouchableOpacity
          style={styles.button}
          onPress={() => {
            navigation.navigate("Home");
          }}
        >
          <MaterialIcons
            name="restaurant"
            size={24}
            color="#ffffff"
            style={styles.buttonIcon}
          />
          <Text style={styles.buttonText}>Parcourir le menu</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
  },
  background: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    borderRadius: 20,
    overflow: "hidden",
    height: 70,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    color: "white",
    zIndex: 1,
  },
  scrollView: {
    flex: 1,
    width: "100%",
    paddingHorizontal: 20,
    paddingTop: 16,
  },
  orderSummary: {
    marginBottom: 10,
  },
  itemCard: {
    borderRadius: 12,
    paddingVertical: 10,
    paddingHorizontal: 16,
    marginBottom: 10,
  },
  itemCardHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent",
  },
  itemCardText: {
    fontSize: 18,
    fontWeight: "bold",
  },
  itemCardValue: {
    fontSize: 18,
    fontWeight: "bold",
    color: "grey",
  },
  commentInput: {
    fontSize: 16,
    color: "grey",
    marginBottom: 8,
  },
  deliveryDetails: {
    paddingVertical: 10,
    paddingHorizontal: 16,
  },
  deliveryDetailsText: {
    fontSize: 16,
    fontWeight: "bold",
  },
  deliveryDetailsValue: {
    fontSize: 14,
    color: "grey",
    marginBottom: 8,
  },

  checkoutButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#2ecc71",
    width: "100%",
    paddingVertical: 14,
  },
  checkoutButtonIcon: {
    marginRight: 10,
  },
  checkoutButtonText: {
    fontSize: 16,
    color: "white",
  },

  emptyCart: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  emptyCartText: {
    fontSize: 18,
    fontWeight: "bold",
    color: "grey",
    marginBottom: 10,
  },
  emptyCartDescription: {
    fontSize: 16,
    color: "grey",
    marginBottom: 16,
    textAlign: "center",
    paddingHorizontal: 16,
  },

  button: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#23313F",
    marginBottom: 24,
    padding: 12,
    borderRadius: 8,
    width: "50%",
    minWidth: 220,
  },
  buttonIcon: {
    marginRight: 10,
  },
  buttonText: {
    fontSize: 18,
    color: "white",
    textAlign: "center",
  },
});
